import React from 'react';
import {ErrorReason} from '../../types/Errors';
import {MessageDialog} from './MessageDialog';

type Props = {
  shown: boolean;
  messages?: ErrorReason[];
  onClose: () => void;
};

export function ExportDialog(props: Props): JSX.Element | null {
  return <MessageDialog {...props} />;
}
