import {CSSProperties} from 'react';
import {ResourceDetails} from '../../types/ResourceDetails';

type StyleHolder =
  | ResourceDetails
  | {
      __style__?: string | CSSProperties;
      style?: string | CSSProperties;
    };

export function makeRowStyle(x: StyleHolder): CSSProperties {
  const style = getStyle(x);

  if (!style) {
    return {};
  }

  if (typeof style === 'string') {
    return parse(style);
  }

  return style;
}

function getStyle(x: StyleHolder): string | CSSProperties | undefined {
  return x.__style__ || x.style;
}

function parse(s: string): CSSProperties {
  if (!s) {
    return {};
  }

  try {
    return JSON.parse(s);
  } catch (ignore) {}

  return {};
}
