import React from 'react';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Field} from '../types/Field';
import {selectStringValue} from '../widgets/common';
import {QRCodeWidget} from '../widgets/QRCodeWidget';
import {useOnChange} from './hooks/useOnChange';

type Props = {
  value: any;
  onChange: OnChangeFieldValue;
  field: Field;
  readOnly?: boolean;
};

export function QRCodeField(props: Props): JSX.Element | null {
  const value = selectStringValue(props.value);
  const onChange = useOnChange(props.onChange, props.field.id);
  return <QRCodeWidget {...props} value={value} onChange={onChange} />;
}
