import React from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {Field} from '../../types/Field';
import {Params} from '../../types/Params';
import {ButtonWidget} from '../ButtonWidget';
import {widgetProps} from '../common';
import {ScanData} from './ScanData';
import {ButtonContainer} from './UsersAndThings';

type Props = {
  data: ScanData;
  size: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  actions: Action[];
};

export type Action = {
  label: string;
  execute: () => void;
};

export type ActionDef = {
  label: string;
  value: string;
  additionalList?: AdditionalListParams;
};

type RawActionDef = {
  label: string;
  value: string;
  additional_list?: RawAdditionalListParams;
};

export type AdditionalListParams = {
  schemaId: string;
  fieldId: string;
  header: string;
  nameFieldId: string;
  notRequiredFieldId: string;
  listParams: Params;
  actionLabel: string;
  noItemActionLabel: string;
};

type RawAdditionalListParams = {
  schema_id?: string;
  field_id?: string;
  header?: string;
  name_field_id?: string;
  not_required_field_id?: string;
  list_params?: Params;
  action_label?: string;
  no_item_action_label?: string;
};

export function useActions(field: Field): ActionDef[] {
  const intl = useIntl();

  const rawActions = widgetProps<RawActionDef[]>(
    field,
    'actions',
    useDefaultActions(),
  );

  return rawActions.map((rawAction) => toAction(rawAction, intl));
}

function toAction(rawAction: RawActionDef, intl: IntlShape): ActionDef {
  const action: ActionDef = {
    value: rawAction.value,
    label: rawAction.label,
  };

  if (rawAction.additional_list) {
    action.additionalList = toAdditionalListParams(
      rawAction.additional_list,
      intl,
    );
  }

  return action;
}

function toAdditionalListParams(
  raw: RawAdditionalListParams,
  intl: IntlShape,
): AdditionalListParams {
  return {
    schemaId: raw.schema_id ?? 'inout_schedule',
    fieldId: raw.field_id ?? 'inout_schedule_id',
    header:
      raw.header ??
      intl.formatMessage({
        id: 'Widget.Scan.Schedule',
        defaultMessage: 'Schedule',
      }),
    nameFieldId: raw.name_field_id ?? 'name',
    notRequiredFieldId: raw.not_required_field_id ?? '',
    listParams: raw.list_params ?? {},
    actionLabel:
      raw.action_label ??
      intl.formatMessage({
        id: 'Widget.Scan.Action.Submit',
        defaultMessage: 'Submit',
      }),
    noItemActionLabel:
      raw.no_item_action_label ??
      intl.formatMessage({
        id: 'Widget.Scan.Action.SubmitWithoutSchedule',
        defaultMessage: 'Submit w/o Schedule',
      }),
  };
}

function useDefaultActions(): RawActionDef[] {
  const intl = useIntl();

  return [
    {
      value: 'out',
      label: intl.formatMessage({
        id: 'Widget.Scan.Action.Out',
        defaultMessage: 'Take out',
      }),
    },
    {
      value: 'in',
      label: intl.formatMessage({
        id: 'Widget.Scan.Action.In',
        defaultMessage: 'Return',
      }),
    },
  ];
}

export function ActionButtons(props: Props): JSX.Element | null {
  const buttons = props.actions.map((action, i) => (
    <Button key={`action-button-${i}`} {...props} action={action} />
  ));

  return <>{buttons}</>;
}

type ButtonProps = {
  data: ScanData;
  size: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  action: Action;
};

function Button(props: ButtonProps): JSX.Element | null {
  return (
    <ButtonContainer>
      <ButtonWidget
        onClick={props.action.execute}
        label={props.action.label}
        size={props.size}
        width={props.width}
        minWidth={props.minWidth}
        maxWidth={props.maxWidth}
        primary={true}
        disabled={!props.data.canSendRequest()}
        styles={{
          button: {flexGrow: 1},
        }}
      />
    </ButtonContainer>
  );
}
