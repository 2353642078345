import React, {useCallback, useEffect, useState} from 'react';
import {api, useApiContext} from '../../../api';
import {MFAForm, MFASetting} from '../../../types/mfa';
import {Spinner} from '../../../common/Spinner';
import {ImageOutputWidget} from '../../../widgets/ImageWidget';
import {TextInputWidget} from '../../../widgets/TextWidget';
import {DefaultButton, PrimaryButton} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {ApiError} from '../../../ApiError';
import {
  Buttons,
  Container,
  Desc,
  ErrorMessage,
  Section,
  Title,
} from '../Elements';
import {Done} from '../Done';

type Props = {
  mfaType: string;
  onCancel: () => void;
  onDone: () => void;
};

type Status = 'loading' | 'loaded' | 'submitting' | 'submitted' | 'error';

export function AppForm(props: Props): JSX.Element {
  const [form, setForm] = useState<MFAForm>();
  const [mfaCode, setMFACode] = useState<string>();
  const [mfa, setMFA] = useState<MFASetting>();
  const ctx = useApiContext();
  const intl = useIntl();
  const [status, setStatus] = useState<Status>('loading');
  const [error, setError] = useState<ApiError | null>(null);

  const submit = useCallback(() => {
    (async () => {
      try {
        setStatus('submitting');
        const newMFA = await api.createMFA(ctx, {
          mfa_type: props.mfaType,
          mfa_code: mfaCode,
        });
        setMFA(newMFA);
        setStatus('submitted');
        props.onDone();
      } catch (e: unknown) {
        if (e instanceof ApiError) {
          setError(e);
          setStatus('error');
        }
      }
    })();
  }, [ctx, props.mfaType, props.onDone, mfaCode]);

  useEffect(() => {
    (async () => {
      const newForm = await api.newMFAForm(ctx, {mfa_type: props.mfaType});
      setForm(newForm);
      setStatus('loaded');
    })();
  }, [ctx, props.mfaType]);

  if (!form) {
    return <Spinner />;
  }

  if (mfa) {
    return (
      <Done
        title={intl.formatMessage({
          id: 'MFA.Add.Done.Title',
          defaultMessage: 'Done',
        })}
        desc={intl.formatMessage({
          id: 'MFA.Add.Done.Desc',
          defaultMessage: 'Two-factor authentication enabled.',
        })}
        onClose={props.onCancel}
      />
    );
  }

  return (
    <Container>
      <Section>
        <Title>{form.props.qrcode.title}</Title>
        <Desc>{form.props.qrcode.desc}</Desc>
        <ImageOutputWidget
          value={form.props.qrcode.value}
          alt={'qrcode'}
          size={200}
          clickable={false}
        />
      </Section>
      <Section>
        <Title>{form.props.mfacode.title}</Title>
        <Desc>{form.props.mfacode.desc}</Desc>
        <TextInputWidget
          onChange={setMFACode}
          multiline={false}
          maxLength={6}
        />
      </Section>
      <Section>
        <Buttons>
          <PrimaryButton
            text={intl.formatMessage({
              id: 'MFA.Action.Add.Submit',
              defaultMessage: 'Submit',
            })}
            onClick={submit}
            disabled={!mfaCode || status === 'submitting'}
          />
          <DefaultButton
            text={intl.formatMessage({
              id: 'Action.Cancel',
              defaultMessage: 'Cancel',
            })}
            onClick={props.onCancel}
          />
        </Buttons>
      </Section>
      {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </Container>
  );
}
