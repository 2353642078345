import {borderColorLightest} from '../../styles';
import {DateRange} from '../DateRange';

export function buildBackgroundImage(
  dateRange: DateRange,
  cellWidth: number,
): string {
  return createImage(dateRange, cellWidth);
}

function fillRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  color: string,
) {
  ctx.fillStyle = color;
  ctx.fillRect(x, y, w, h);
}

function createImage(dateRange: DateRange, cellWidth: number): string {
  const canvas = document.createElement('canvas');
  canvas.width = cellWidth * dateRange.len;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  dateRange.eachDate((date, index) => {
    const color = selectBackgroundColor(date.getDay());
    fillRect(ctx, index * cellWidth, 0, cellWidth - 1, 1, color);
    fillRect(ctx, (index + 1) * cellWidth - 1, 0, 1, 1, borderColorLightest);
  });

  return canvas.toDataURL();
}

function selectBackgroundColor(weekDay: number): string {
  switch (weekDay) {
    case 0:
      return 'rgba(255, 78, 78, 0.1)';
    case 6:
      return 'rgba(39, 117, 255, 0.1)';
    default:
      return 'transparent';
  }
}
