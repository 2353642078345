import {Label, TextField} from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
import {ApiContext} from '../api';

import {CheckboxField} from '../fields/CheckboxField';
import {ItemField} from '../fields/ItemField';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {RelatedSchemas} from '../types/Resource';

const FieldContainer = styled.div`
  margin-right: 2rem;
`;

type Value = any;
type Values = {[key: string]: Value};

type Props = {
  ctx: ApiContext;
  fields: Field[];
  relatedSchemas: RelatedSchemas;
  onSearch?: (values: Values) => void;
  onReset?: () => void;
  actions: Actions;
};

type State = {
  values: Values;
};

type RenderFn = (field: Field) => JSX.Element;

export class FilterFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      values: {},
    };
  }

  onSearch = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.splitValues(this.state.values));
    }
  };

  onReset = () => {
    this.setState({values: {}}, () => {
      this.onSearch();

      if (this.props.onReset) {
        this.props.onReset();
      }
    });
  };

  updateValues(field: Field, value: Value) {
    this.setState({
      values: {
        ...this.state.values,
        [field.id]: value,
      },
    });
  }

  splitValues(values: Values) {
    return Object.keys(values).reduce<Values>((result, key) => {
      const value = values[key];

      if (Array.isArray(value)) {
        if (value.length > 0) {
          result[key] = value.map((v) => (v && v.id) || v);
        }

        return result;
      }

      const vs = String(value)
        .split(/\s+/)
        .filter((v) => v !== '');

      if (vs.length > 0) {
        result[key] = vs;
      }

      return result;
    }, {});
  }

  getValues() {
    return this.splitValues(this.state.values);
  }

  getItemSchema(field: Field) {
    return this.props.relatedSchemas[field.id];
  }

  renderField = (field: Field) => {
    const fns: {[key: string]: RenderFn} = {
      string: this.renderTextField,
      checkbox: this.renderCheckboxField,
      radio: this.renderRadioField,
      resid: this.renderRefField,
      resid_list: this.renderRefField,
      ref: this.renderRefField,
    };
    const fn = fns[field.widget || field.type] || this.renderTextField;
    return fn(field);
  };

  renderTextField = (field: Field) => {
    return (
      <FieldContainer key={field.id}>
        <Label>{field.label}</Label>
        <TextField
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              this.onSearch();
            }
          }}
          onChange={(_, v) => {
            this.updateValues(field, v);
          }}
          value={this.state.values[field.id]}
        />
      </FieldContainer>
    );
  };

  renderCheckboxField = (field: Field) => {
    return (
      <FieldContainer key={field.id}>
        <Label>{field.label}</Label>
        <CheckboxField
          value={this.state.values[field.id]}
          field={field}
          onChange={(v: Value) => {
            this.updateValues(field, v);
          }}
        />
      </FieldContainer>
    );
  };

  renderRadioField = (field: Field) => {
    return (
      <FieldContainer key={field.id}>
        <Label>{field.label}</Label>
        <CheckboxField
          value={this.state.values[field.id]}
          field={field}
          onChange={(v: Value) => {
            this.updateValues(field, v);
          }}
        />
      </FieldContainer>
    );
  };

  renderRefField = (field: Field) => {
    return (
      <FieldContainer key={field.id}>
        <Label>{field.label}</Label>
        <ItemField
          field={field}
          value={this.state.values[field.id]}
          onChange={(vs) => {
            this.updateValues(field, vs[field.id]);
            return true;
          }}
          actions={this.props.actions}
        />
      </FieldContainer>
    );
  };

  renderFields = () => {
    return this.props.fields.map((field) => {
      return this.renderField(field);
    });
  };

  render() {
    return this.renderFields();
  }
}
