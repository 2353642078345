import {CommandBar} from '@fluentui/react';
import {CSSProperties} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {
  bodyColorAlt,
  bodyColorHovered,
  borderColorLightest,
  textColorTheme,
  textColorThemeAlt,
} from '../styles';
import {ToolButton} from '../types/ToolButton';
import {buildItems} from './buttons/Buttons';

type Props = {
  buttons?: ToolButton[];
  farButtons?: ToolButton[];
  style?: CSSProperties;
};

export class Toolbar extends React.Component<Props> {
  getItems() {
    const buttons = this.props.buttons || [];
    return buildItems(buttons);
  }

  getFarItems() {
    const buttons = this.props.farButtons || [];
    return buildItems(buttons);
  }

  isEmpty() {
    return (
      (!this.props.buttons || this.props.buttons.length === 0) &&
      (!this.props.farButtons || this.props.farButtons.length === 0)
    );
  }

  render() {
    if (this.isEmpty()) {
      return null;
    }

    return (
      <Container style={this.props.style}>
        <CommandBar items={this.getItems()} farItems={this.getFarItems()} />
      </Container>
    );
  }
}

const Container = styled.div`
  border-bottom: 1px solid ${borderColorLightest};

  & .ms-CommandBar,
  & .ms-Button {
    color: ${textColorThemeAlt};
    background-color: ${bodyColorAlt};
  }

  & .ms-Button:hover {
    color: ${textColorTheme};
    background-color: ${bodyColorHovered};
  }
`;
