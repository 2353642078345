import styled from 'styled-components';
import {GridProps} from './GridLayout';

export const GridAreaContainer = styled.div.attrs<GridProps>((props) => ({
  style: {
    gridTemplateColumns: props.columns.map((x) => x.size).join(' '),
    gridTemplateRows: props.rows.map((x) => x.size).join(' '),
    gridTemplateAreas: props.areas
      .map((row) => `'` + row.join(' ') + `'`)
      .join(' '),
  },
}))<GridProps>`
  width: 100%;
  height: 100%;
  display: grid;
`;

export const GridArea = styled.div<{area: string}>`
  grid-area: ${(props) => props.area};
  position: relative;
  background-color: white;
`;
