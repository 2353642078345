import {EditorState} from 'draft-js';
import React, {useCallback} from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {
  RichTextInputWidget,
  RichTextOutputWidget,
} from '../widgets/RichTextWidget';

export function RichTextField(props: FieldValueProps): JSX.Element | null {
  const fn = props.onChange;
  const fid = props.field.id;

  const onChange = useCallback(
    (es: EditorState) => {
      fn({[fid]: es});
    },
    [fn, fid],
  );

  if (props.readOnly) {
    return <RichTextOutputWidget value={props.value} />;
  }

  return <RichTextInputWidget value={props.value} onChange={onChange} />;
}
