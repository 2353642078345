import React, {CSSProperties, useState} from 'react';
import styled from 'styled-components';
import {
  buildTargetItemPropsMap,
  TargetItemProps,
} from '../../fields/hooks/useItemProps';
import {bodyColor, borderColorLight, textColor} from '../../styles';
import {Option} from '../../types/Field';
import {RelatedResources} from '../../types/Resource';
import {ResourceDetails} from '../../types/ResourceDetails';
import {array} from '../../util';
import {RenderLabelFn} from '../CheckboxWidget';
import {Item, makeStyleForDeleted} from './Item';
import {SchemaWithoutScreen} from '../../types/Schema';

export function useItems(props: {
  value: string[];
  onChange: (ids: string[]) => void;
  relatedResources?: RelatedResources;
}) {
  const [relates, setRelates] = useState<RelatedResources>({
    ...props.relatedResources,
  });

  const onChange = (v: ResourceDetails[]) => {
    const values = array(v).map((x) => x.id);
    const newRelates = {...relates};

    for (let x of array(v)) {
      newRelates[x.id] = x;
    }

    props.onChange(values);
    setRelates(newRelates);
    return true;
  };

  const toItems = (ids: string[]): ResourceDetails[] => {
    return [...new Set(relatedItems(ids, relates))];
  };

  const items = toItems(props.value);
  const itemsMap = toMap(items);

  return {
    items,
    itemsMap,
    onChange,
  };
}

export function useRenderClickableLabel(
  itemsMap: {[key: string]: ResourceDetails},
  targets: TargetItemProps[],
  setItemId: (id: string) => void,
  setSchemaId: (id: string) => void,
  setShown: (shown: boolean) => void,
  styles?: LabelStyles,
): RenderLabelFn {
  const targetMap = buildTargetItemPropsMap(targets);

  return (option) => {
    const style = makeStyle(itemsMap[option.value]);
    const target = targetMap[itemsMap[option.value].schema_id];

    return (
      <Label
        option={option}
        itemsMap={itemsMap}
        schema={target?.itemSchema}
        nameFieldId={target?.nameFieldId}
        onClick={async () => {
          await setItemId(option.value);

          if (itemsMap[option.value] && itemsMap[option.value].schema_id) {
            await setSchemaId(itemsMap[option.value].schema_id);
          }

          setShown(true);
        }}
        styles={{
          ...styles,
          container: {
            ...styles?.container,
            ...style,
            cursor: 'pointer',
          },
        }}
      />
    );
  };
}

export function useRenderStaticLabel(
  itemsMap: {[key: string]: ResourceDetails},
  targets: TargetItemProps[],
  styles?: LabelStyles,
): RenderLabelFn {
  const targetMap = buildTargetItemPropsMap(targets);

  return (option) => {
    const target = targetMap[itemsMap[option.value].schema_id];

    return (
      <Label
        option={option}
        itemsMap={itemsMap}
        schema={target?.itemSchema}
        nameFieldId={target?.nameFieldId}
        styles={styles}
      />
    );
  };
}

function relatedItems(ids: string[], relates: RelatedResources) {
  if (!ids || !relates) {
    return [];
  }

  return ids.map((id) => relates[id]).filter((item) => !!item);
}

export function buildOptions(items: ResourceDetails[]): Option[] {
  return items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}

type ItemsMap = {[id: string]: ResourceDetails};

function toMap(items: ResourceDetails[]): ItemsMap {
  const m: ItemsMap = {};

  for (let item of items) {
    m[item.id] = item;
  }

  return m;
}

type CheckboxDescriptionProps = {
  text?: string;
};

export function OptionButtonDescription(
  props: CheckboxDescriptionProps,
): JSX.Element | null {
  if (!props.text) {
    return null;
  }

  return <Description>{props.text}</Description>;
}

type LabelProps = {
  option: Option;
  itemsMap: ItemsMap;
  schema?: SchemaWithoutScreen;
  nameFieldId: string;
  onClick?: () => void;
  styles?: LabelStyles;
};

export type LabelStyles = {
  container?: CSSProperties;
};

function Label(props: LabelProps): JSX.Element {
  return (
    <ItemRowContainer style={props.styles?.container} onClick={props.onClick}>
      <Item
        item={props.itemsMap[props.option.value]}
        nameFieldId={props.nameFieldId}
        iconColor={props.schema?.iconColor}
      />
    </ItemRowContainer>
  );
}

function makeStyle(item?: ResourceDetails) {
  return makeStyleForDeleted(item && item.is_deleted);
}

export const OptionButtonContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid ${borderColorLight};
  background-color: ${bodyColor};
`;

export const Description = styled.div`
  margin-bottom: 0.5rem;
  color: ${textColor};
`;

export const ItemRowContainer = styled.div`
  max-width: 200px;
  margin-left: 4px;
`;
