import {DefaultButton, IButtonProps, mergeStyleSets} from '@fluentui/react';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = IButtonProps & {};

export function ScannedValueSelectButton(props: Props): JSX.Element {
  const intl = useIntl();

  return (
    <DefaultButton
      text={intl.formatMessage({id: 'Action.Select', defaultMessage: 'Select'})}
      onClick={props.onClick}
      styles={mergeStyleSets(props.styles, {
        root: {whiteSpace: 'nowrap'},
      })}
    />
  );
}
