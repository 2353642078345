import React, {CSSProperties} from 'react';
import styled from 'styled-components';

const Img = styled.img`
  max-width: min(480px, 90vw);
`;

type Props = {
  style?: CSSProperties;
};

export function Logo(props: Props) {
  return (
    <Img
      src={'/logo'}
      alt={document.title}
      title={document.title}
      style={props.style}
    />
  );
}
