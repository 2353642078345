import React from 'react';
import styled from 'styled-components';
import {foldStateKey} from '../common/DisplayFoldSwitch';
import {removeLocalStorageItem} from '../common/localStorage';
import {MaterialIcon} from '../icons/MaterialIcon';
import {bodyColorHovered} from '../styles';
import {DisplayToggle} from '../types/DisplayToggle';
import {CustomLayout} from '../common/CustomLayout';

type Props = {
  area?: string;
  toggle?: DisplayToggle;
  onClick?: (state: CustomLayout) => void;
} & Partial<CustomLayout>;

export type DisplayUnfoldBarProps = {
  type: string;
  area: string;
  toggle: DisplayToggle;
};

export const DisplayUnfoldBar = ({
  area,
  toggle,
  onClick,
  customRows,
  customColumns,
  customComponents,
}: Props) => {
  if (!area || !toggle || !onClick) {
    return null;
  }

  const icon = selectIcon(toggle.direction);

  return (
    <Container
      onClick={() => {
        if (area && toggle && onClick) {
          removeLocalStorageItem(foldStateKey);

          const layout: CustomLayout = {
            customRows: {...customRows},
            customColumns: {...customColumns},
            customComponents: {...customComponents},
          };

          delete layout.customRows[area];
          delete layout.customColumns[area];
          delete layout.customComponents[area];

          onClick(layout);
        }
      }}>
      <MaterialIcon iconName={icon} />
    </Container>
  );
};

function selectIcon(direction: string): string {
  // reverse icon
  switch (direction) {
    case 'left':
      return 'chevron_right';
    case 'right':
      return 'chevron_left';
    case 'up':
      return 'expand_more';
    case 'down':
      return 'expand_less';
  }

  return 'unfold_more';
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    background: ${bodyColorHovered};
  }
`;
