import React from 'react';
import {AclField} from '../fields/AclField';
import {BarcodeField} from '../fields/BarcodeField';
import {ButtonField} from '../fields/ButtonField';
import {EmbedOutputField} from '../fields/EmbedField';
import {FileField} from '../fields/FileField';
import {ItemCheckboxField} from '../fields/ItemCheckboxField';
import {ItemField} from '../fields/ItemField';
import {ItemRadioField} from '../fields/ItemRadioField';
import {QRCodeField} from '../fields/QRCodeField';
import {ScanField} from '../fields/ScanField';
import {TableField} from '../fields/TableField';
import {WebSocketField} from '../fields/WebSocketField';
import {
  AttachmentFiles,
  RelatedProxies,
  RelatedResources,
} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {SelectWidget} from '../widgets/SelectWidget';
import {
  renderBoolField,
  renderCheckboxField,
  renderColorField,
  renderComboBoxField,
  renderDateField,
  renderDateListField,
  renderImageField,
  renderLinkField,
  renderMarkdownField,
  renderMonthField,
  renderMultilineTextField,
  renderNull,
  renderNumberField,
  renderRadioField,
  renderRichTextField,
  renderTextField,
  renderTextListField,
  renderTimeField,
  renderYearMonthField,
} from './CommonField';
import {FieldValueProps} from './FieldValue';

export type FieldOutputProps = FieldValueProps & {
  item: ResourceDetails;
  relatedResources: RelatedResources;
  relatedProxies: RelatedProxies;
  attachmentFiles: AttachmentFiles;
};

type RenderFieldFunction = (props: FieldOutputProps) => JSX.Element | null;

export function DetailsFieldValue(props: FieldOutputProps) {
  const render =
    widgetFunctions[props.field.widget] ||
    typeFunctions[props.field.type] ||
    renderTextField;

  return render(props);
}

export const renderFileField = (props: FieldOutputProps) => {
  return <FileField {...props} />;
};

const renderSelectField = (props: FieldOutputProps) => {
  return <SelectWidget {...props} />;
};

const renderItemField = (props: FieldOutputProps) => {
  return <ItemField {...props} />;
};

export const renderTableField = (props: FieldOutputProps) => {
  return <TableField {...props} />;
};

const renderAcl = (props: FieldOutputProps) => {
  return <AclField {...props} />;
};

const renderButtonField = (props: FieldOutputProps) => {
  return <ButtonField {...props} />;
};

const renderWebSocketField = (props: FieldOutputProps) => {
  return <WebSocketField {...props} />;
};

const renderScanField = (props: FieldOutputProps) => {
  return <ScanField {...props} />;
};

const renderItemCheckboxField = (props: FieldOutputProps) => {
  return <ItemCheckboxField {...props} />;
};

const renderItemRadioField = (props: FieldOutputProps) => {
  return <ItemRadioField {...props} />;
};

const renderQRCodeField = (props: FieldOutputProps) => {
  return <QRCodeField {...props} />;
};

const renderBarcodeField = (props: FieldOutputProps) => {
  return <BarcodeField {...props} />;
};

const renderEmbedField = (props: FieldOutputProps) => {
  return <EmbedOutputField {...props} />;
};

const widgetFunctions: {[key: string]: RenderFieldFunction} = {
  string: renderTextField,
  text: renderMultilineTextField,
  checkbox: renderCheckboxField,
  select: renderSelectField,
  radio: renderRadioField,
  table: renderTableField,
  link: renderLinkField,
  image: renderImageField,
  color: renderColorField,
  time: renderTimeField,
  month: renderMonthField,
  rich_text: renderRichTextField,
  scanner: renderNull,
  acl: renderAcl,
  button: renderButtonField,
  websocket: renderWebSocketField,
  scan: renderScanField,
  item_checkbox: renderItemCheckboxField,
  item_radio: renderItemRadioField,
  qrcode: renderQRCodeField,
  barcode: renderBarcodeField,
  markdown: renderMarkdownField,
  combobox: renderComboBoxField,
};

const typeFunctions: {[key: string]: RenderFieldFunction} = {
  string: renderTextField,
  string_list: renderTextListField,
  int: renderNumberField,
  float: renderNumberField,
  serial: renderTextField,
  date: renderDateField,
  date_list: renderDateListField,
  year_month: renderYearMonthField,
  resid: renderItemField,
  resid_list: renderItemField,
  file: renderFileField,
  text: renderMultilineTextField,
  bool: renderBoolField,
  embed: renderEmbedField,
};
