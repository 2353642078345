import React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {AclWidget} from '../widgets/AclWidgets';

type Props = FieldValueProps & {};

export function AclField(props: Props): JSX.Element | null {
  const acls = props.value;
  return <AclWidget {...props} acls={acls} />;
}
