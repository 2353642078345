export const getDateText = (date?: Date | null): string => {
  if (!date) {
    return '';
  }

  const y = String(date.getFullYear());
  let m = String(date.getMonth() + 1);
  let d = String(date.getDate());

  if (m.length < 2) {
    m = '0' + m;
  }

  if (d.length < 2) {
    d = '0' + d;
  }

  return [y, m, d].join('-');
};

export const getTimeText = (date?: Date | null): string => {
  if (!date) {
    return '';
  }

  let h = String(date.getHours());
  let m = String(date.getMinutes());

  if (h.length < 2) {
    h = '0' + h;
  }

  if (m.length < 2) {
    m = '0' + m;
  }

  return [h, m].join(':');
};

const oneDayMilliseconds = 86400000;

export function diffDays(a: Date, b: Date): number {
  return Math.ceil((a.getTime() - b.getTime() + 1) / oneDayMilliseconds);
}
