import {ScrollablePane} from '@fluentui/react';
import * as React from 'react';
import {CellProps, Column} from 'react-table';
import styled from 'styled-components';
import {makeRowStyle} from '../common/style/style';
import {Table, TREE_ARROW_COLUMN_ID, TreeTable} from '../common/table/Table';
import {TableToolsSwitch} from '../common/table/TableTools';
import {MaterialIcon} from '../icons/MaterialIcon';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceListForTable} from '../types/ResourceList';
import {ListItem} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {SortOrder} from '../types/SortOrder';
import {ToolButton} from '../types/ToolButton';

export type SimpleListProps = {
  type: string;
  schemaId: string;
  listItem: ListItem;
  tree: boolean;
  buttons?: ToolButton[];
  farButtons?: ToolButton[];
  sorters?: Sorter[];
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Row = styled.div`
  height: 100%;
  padding: 11px 8px 11px 12px;
  box-sizing: border-box;
  flex-grow: 1;
`;

const TreeArrow = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DEFAULT_COLUMN_WIDTH = 250;

type Props = {
  resourceList: ResourceListForTable;
  onRenderRow: (item: ResourceDetails, index: number) => JSX.Element;
  onSelect: (item: ResourceDetails) => void;
  onChangeCurrentPage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeSorter: (id: string, current: SortOrder) => void;
  emptyMessage?: string;
  tree?: boolean;
  disabledIds?: string[];
  selectedIds?: string[];
  lockedIds?: string[];
  expandByDefault?: boolean;
  hideHeader: boolean;
  tools?: TableToolsSwitch;
};

type Res = {
  id: string;
  item: ResourceDetails;
  subRows?: Res[];
  expanded?: boolean;
};

export class SimpleList extends React.Component<Props> {
  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any,
  ): boolean {
    return (
      nextProps.resourceList.timestamp !== this.props.resourceList.timestamp ||
      nextProps.selectedIds !== this.props.selectedIds
    );
  }

  isDisabled(id: string) {
    return (this.props.disabledIds || []).includes(id);
  }

  renderItem(item: ResourceDetails, index: number) {
    if (this.props.onRenderRow) {
      const style = makeRowStyle(item);
      return <Row style={style}>{this.props.onRenderRow(item, index)}</Row>;
    }

    return (
      <div>
        <pre>{JSON.stringify(item, null, 2)}</pre>
      </div>
    );
  }

  renderCell = (cellProps: CellProps<Res>) => {
    const item = cellProps.cell.value;

    if (!item) {
      return null;
    }

    const index = cellProps.row.index;
    return this.renderItem(item, index);
  };

  renderTreeArrow = (cellProps: CellProps<Res>) => {
    const row = cellProps.row as any;
    const width = `${(row.depth + 1) * 2}rem`;
    const eProps = row.getToggleRowExpandedProps({
      style: {width, fontSize: 24},
    });

    const isDisabled = this.isDisabled(row.original.id);

    let onClick = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      eProps.onClick();
    };

    if (isDisabled) {
      onClick = () => {};
      eProps.style.cursor = 'not-allowed';
    }

    return row.canExpand ? (
      <TreeArrow {...eProps} onClick={onClick}>
        {row.isExpanded ? (
          <MaterialIcon iconName={'keyboard_arrow_down'} />
        ) : (
          <MaterialIcon iconName={'keyboard_arrow_right'} />
        )}
      </TreeArrow>
    ) : (
      <div style={{width}} />
    );
  };

  renderTreeTable() {
    const columns: Column<Res>[] = [
      {
        id: TREE_ARROW_COLUMN_ID,
        Cell: this.renderTreeArrow,
        width: 'auto',
      },
      {
        Cell: this.renderCell,
        accessor: 'item',
        minWidth: DEFAULT_COLUMN_WIDTH,
      },
    ];

    if (this.props.expandByDefault) {
      this.props.resourceList.list.forEach((d) => {
        d.expanded = true;
      });
    }

    const rows = convertToTreeRows(
      this.props.resourceList.list.map(convertToRow),
    );

    if (!rows) {
      return null;
    }

    const disabledIds = rows.reduce<string[]>((ids, row) => {
      if (this.isDisabled(row.id)) {
        ids.push(row.id);
        ids.push(...collectIds(row.subRows || []));
      } else {
        ids.push(
          ...collectDisabledIds(
            row.subRows || [],
            this.props.disabledIds || [],
          ),
        );
      }

      return ids;
    }, []);

    return (
      <Container>
        <ScrollablePane>
          <TreeTable<Res>
            hideHeader={this.props.hideHeader}
            tools={this.props.tools}
            columns={columns}
            rows={rows}
            onSelectRow={(row) => {
              this.props.onSelect(row.item);
            }}
            count={this.props.resourceList.count}
            currentPage={this.props.resourceList.currentPage}
            pageSize={this.props.resourceList.pageSize}
            maxPage={this.props.resourceList.maxPage}
            onChangeCurrentPage={this.props.onChangeCurrentPage}
            onChangePageSize={this.props.onChangePageSize}
            emptyMessage={this.props.emptyMessage}
            disabledIds={disabledIds}
            selectedIds={this.props.selectedIds}
            lockedIds={this.props.lockedIds}
            onChangeSorter={this.props.onChangeSorter}
          />
        </ScrollablePane>
      </Container>
    );
  }

  render() {
    if (this.props.tree) {
      return this.renderTreeTable();
    }

    const columns: Column<Res>[] = [
      {
        Cell: this.renderCell,
        accessor: 'item',
        minWidth: DEFAULT_COLUMN_WIDTH,
      },
    ];

    const rows = this.props.resourceList.list.map(convertToRow);

    return (
      <Container>
        <ScrollablePane>
          <Table<Res>
            hideHeader={this.props.hideHeader}
            tools={this.props.tools}
            columns={columns}
            rows={rows}
            onSelectRow={(row) => {
              this.props.onSelect(row.item);
            }}
            count={this.props.resourceList.count}
            currentPage={this.props.resourceList.currentPage}
            pageSize={this.props.resourceList.pageSize}
            maxPage={this.props.resourceList.maxPage}
            onChangeCurrentPage={this.props.onChangeCurrentPage}
            onChangePageSize={this.props.onChangePageSize}
            emptyMessage={this.props.emptyMessage}
            selectedIds={this.props.selectedIds}
            lockedIds={this.props.lockedIds}
            onChangeSorter={this.props.onChangeSorter}
          />
        </ScrollablePane>
      </Container>
    );
  }
}

function convertToRow(item: ResourceDetails): Res {
  return {
    id: item.id,
    item: item,
    expanded: item.expanded,
  };
}

type TreeRows = {[id: string]: TreeRow};

type TreeRow = {
  original: Res;
  subTreeRows: TreeRows;
};

function convertToTreeRows(rows: Res[]): Res[] | undefined {
  const treeRows: TreeRows = {};

  for (let row of rows) {
    if (!row.item.parent_id && !treeRows[row.item.id]) {
      treeRows[row.item.id] = {
        original: row,
        subTreeRows: convertToSubRows(row.item.id, rows),
      };
    }
  }

  return convertToResList(treeRows);
}

function convertToSubRows(parentId: string, rows: Res[]): {} {
  const treeRows: TreeRows = {};

  for (let row of rows) {
    if (row.item.parent_id === parentId) {
      treeRows[row.item.id] = {
        original: row,
        subTreeRows: convertToSubRows(row.item.id, rows),
      };
    }
  }

  return treeRows;
}

function convertToResList(treeRows: TreeRows): Res[] | undefined {
  const result: Res[] = [];

  for (let id of Object.keys(treeRows)) {
    const treeRow = treeRows[id];
    const subRows = convertToResList(treeRow.subTreeRows);
    const res = treeRow.original;
    res.subRows = subRows;
    result.push(res);
  }

  if (result.length === 0) {
    return undefined;
  }

  return result;
}

function collectIds(rows: Res[]): string[] {
  return rows.reduce<string[]>((ids, row) => {
    ids.push(row.id);
    ids.push(...collectIds(row.subRows || []));
    return ids;
  }, []);
}

function collectDisabledIds(rows: Res[], disabledIds: string[]): string[] {
  return rows.reduce<string[]>((ids, row) => {
    if (disabledIds.includes(row.id)) {
      ids.push(row.id);
      ids.push(...collectIds(row.subRows || []));
    } else {
      ids.push(...collectDisabledIds(row.subRows || [], disabledIds));
    }

    return ids;
  }, []);
}
