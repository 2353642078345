import {addDays, getMonthEnd} from '@fluentui/date-time-utilities';
import {diffDays} from '../dates/dates';

export enum MonthOfYear {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

export class DateRange {
  readonly start: Date;
  readonly end: Date;
  readonly len: number;

  constructor(start: Date, end: Date) {
    this.start = start;
    this.end = end;
    this.len = diffDays(end, start);
  }

  static fromMonth(year: number, month: MonthOfYear): DateRange {
    const start = new Date(year, month, 1);
    const end = getMonthEnd(start);
    return DateRange.fromStartEnd(start, end);
  }

  static fromStartEnd(start: Date, end: Date): DateRange {
    return new DateRange(start, end);
  }

  eachDate(fn: (date: Date, index: number) => void) {
    let d = this.start;
    let index = 0;

    while (d.getTime() <= this.end.getTime()) {
      fn(d, index);
      index++;
      d = addDays(d, 1);
    }
  }

  contains(date?: Date | null): boolean {
    if (!date) {
      return false;
    }

    const t = date.getTime();
    return this.start.getTime() <= t && t <= this.end.getTime();
  }

  withStart(start: Date, maxLen?: number) {
    if (start.getTime() >= this.end.getTime()) {
      const end = new Date(start);
      end.setDate(end.getDate() + this.len);
      return new DateRange(start, end);
    }

    if (maxLen && diffDays(this.end, start) > maxLen) {
      const end = new Date(start);
      end.setDate(end.getDate() + maxLen);
      return new DateRange(start, end);
    }

    return new DateRange(start, this.end);
  }

  withEnd(end: Date, maxLen?: number) {
    if (this.start.getTime() >= end.getTime()) {
      const start = new Date(end);
      start.setDate(start.getDate() - this.len);
      return new DateRange(start, end);
    }

    if (maxLen && diffDays(end, this.start) > maxLen) {
      const start = new Date(end);
      start.setDate(start.getDate() - maxLen);
      return new DateRange(start, end);
    }

    return new DateRange(this.start, end);
  }
}

export function newDate(s: string): Date | null {
  if (!s) {
    return null;
  }

  const d = new Date(s);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}
