export function isSame<T = any>(a: Set<T>, b: Set<T>): boolean {
  if (a.size !== b.size) {
    return false;
  }

  for (let v of a) {
    if (!b.has(v)) {
      return false;
    }
  }

  return true;
}

export function minus<T = any>(a: Set<T>, b: Set<T>): Set<T> {
  const diff = new Set(a);

  for (let v of b) {
    diff.delete(v);
  }

  return diff;
}
