import React, {useCallback, useEffect, useState} from 'react';
import {api, ApiContext, Data} from '../api';
import {PARAM_KEY_CHECKED_IDS} from '../consts';
import {useNav} from '../hooks/useNav';
import {Actions} from '../types/Action';
import {FormFiles} from '../types/Form';
import {Resource} from '../types/Resource';
import {selectComponent} from './util';
import {ItemDetailsProps} from './ItemDetails';
import {ItemForm, OnSaveFunc, RelatedValues, Values} from './ItemForm';

type Props = {
  ctx: ApiContext;
  appId: string;
  id?: string;
  mode: ItemFormMode;
  checkedIds?: Set<string>;
  onAfter: (res: Resource) => void;
  onCancel: () => void;
};

const modes = ['new', 'edit', 'copy'] as const;
export type ItemFormMode = typeof modes[number];

export function isItemFormMode(mode: any): mode is ItemFormMode {
  return modes.includes(mode);
}

export function ItemFormContainer(props: Props): JSX.Element | null {
  const [res, onSave] = useEditableResource(props);

  if (!res) {
    return null;
  }

  const compProps = selectComponent<ItemDetailsProps>(
    res.schema.screen.components,
    'item',
  );

  if (!compProps) {
    return null;
  }

  const actions = buildActions();

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <ItemForm
      resource={res}
      actions={actions}
      ctx={props.ctx}
      onCancel={onCancel}
      onSave={onSave}
      labelPosition={compProps.labelPosition}
    />
  );
}

function useEditableResource({
  ctx,
  appId,
  id,
  mode,
  checkedIds,
  onAfter,
}: Props): [Resource | null, OnSaveFunc] {
  const {setNavIfEmpty} = useNav();
  const [res, setRes] = useState<Resource | null>(null);

  useEffect(() => {
    (async () => {
      if (mode === 'new') {
        const data = buildData(checkedIds);
        const res = await api.newByPost(ctx, appId, data);
        setRes(res);
        setNavIfEmpty(res.schema);
        return;
      }

      if (mode === 'copy' && id) {
        const res = await api.copy(ctx, appId, id);
        setRes(res);
        setNavIfEmpty(res.schema);
        return;
      }

      if (id) {
        const res = await api.edit(ctx, appId, id);
        setRes(res);
        setNavIfEmpty(res.schema);
        return;
      }
    })();
  }, [ctx, appId, id, mode, checkedIds, setNavIfEmpty]);

  const onSave = useCallback(
    async (values: Values, files: FormFiles, related: RelatedValues) => {
      if (mode === 'new' || mode === 'copy') {
        const res = await api.create(ctx, appId, values, files, related);
        onAfter(res);
        return;
      }

      if (id) {
        const res = await api.update(ctx, appId, id, values, files, related);
        onAfter(res);
        return;
      }
    },
    [ctx, appId, id, mode, onAfter],
  );

  return [res, onSave];
}

function buildActions(): Actions {
  return {};
}

function buildData(checkedIds?: Set<string>): Data {
  if (!checkedIds) {
    return {};
  }

  return {
    [PARAM_KEY_CHECKED_IDS]: [...checkedIds],
  };
}
