import {Sticky, StickyPositionType} from '@fluentui/react';
import React from 'react';
import {ApiContext} from '../../api';
import {buildEmptyPagerProps} from '../../common/ResourceDetailsTable';
import {SimpleList} from '../../components/SimpleList';
import {
  buildTargetItemPropsMap,
  TargetItemProps,
} from '../../fields/hooks/useItemProps';
import {useMessage} from '../../hooks/useMessage';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceList, ResourceListForTable} from '../../types/ResourceList';
import {AllButton, Header, HeaderTitle} from './elements';
import {Item} from './Item';

type Props = {
  ctx: ApiContext;
  targets: TargetItemProps[];
  list: ResourceListForTable;
  onSelect: (item: ResourceDetails) => void;
  onSelectAll: () => void;
  capacity?: number;
  onUpdateList: (list: ResourceList) => void;
};

export function SelectedItems(props: Props): JSX.Element {
  const pagerProps = buildEmptyPagerProps();
  const targetMap = buildTargetItemPropsMap(props.targets);

  const onRenderRow = (item: ResourceDetails) => {
    const target = targetMap[item.schema_id];

    return (
      <Item
        item={item}
        nameFieldId={target?.nameFieldId}
        iconColor={target?.itemSchema.iconColor}
      />
    );
  };

  const title = useMessage('Widget.Item.Selected', 'Selected');

  return (
    <>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
          <DeselectAllButton props={props} />
        </Header>
      </Sticky>
      <SimpleList
        tools={{}}
        hideHeader={true}
        resourceList={props.list}
        onSelect={props.onSelect}
        onRenderRow={onRenderRow}
        {...pagerProps}
      />
    </>
  );
}

type DeselectAllButtonProps = {
  props: Props;
};

function DeselectAllButton({
  props,
}: DeselectAllButtonProps): JSX.Element | null {
  const text = useMessage('Widget.Item.DeselectAll', 'Deselect All');

  if (props.capacity) {
    return null;
  }

  return <AllButton text={text} onClick={props.onSelectAll} />;
}
