import styled from 'styled-components';

const ContentOnlyLayout = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'main-content';
  `,
  Content: styled.div`
    grid-area: main-content;
    overflow: auto;
  `,
};

export {ContentOnlyLayout};
