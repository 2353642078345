import {useCallback, useContext} from 'react';
import {NavContext, NavProps} from '../context';
import {Schema} from '../types/Schema';

export function useNav() {
  const {nav, setNav} = useContext(NavContext);

  const setNavIfEmpty = useCallback(
    (schema: Schema) => {
      if (isEmpty(nav)) {
        setNav({
          breadcrumb: [
            {
              title: schema.name,
              iconName: schema.iconName,
            },
          ],
          buttons: [],
        });
      }
    },
    [nav, setNav],
  );

  return {
    nav,
    setNav,
    setNavIfEmpty,
  };
}

function isEmpty(nav?: NavProps): boolean {
  if (!nav) {
    return true;
  }

  if (nav.breadcrumb.length === 0) {
    return true;
  }

  if (nav.breadcrumb.length > 1) {
    return false;
  }

  const b = nav.breadcrumb[0];
  return b.title === '' && b.iconName === '';
}
