import {Checkbox} from '@fluentui/react';
import React, {useMemo} from 'react';
import styled from 'styled-components';
import {getDisplayName} from '../../common/displayName';
import {ResourceDetails} from '../../types/ResourceDetails';

type Props = {
  checked: boolean;
  item: ResourceDetails;
  onCheck: (checked: boolean) => void;
  size: number;
  nameFieldId?: string;
};

export function Item(props: Props): JSX.Element | null {
  const styles = useMemo(() => {
    return makeStyles(props.size);
  }, [props.size]);

  const name = getDisplayName(props.item, props.nameFieldId);

  return (
    <Container>
      <Checkbox
        checked={props.checked}
        onChange={(_, checked) => props.onCheck(checked ?? false)}
        label={name}
        styles={styles}
      />
    </Container>
  );
}

function makeStyles(size: number) {
  return {
    root: {},
    label: {
      alignItems: 'center',
    },
    text: {
      fontSize: size + 'px',
      lineHeight: size * 1.5 + 'px',
    },
    checkbox: {
      width: size + 'px',
      height: size + 'px',
    },
  };
}

export type isChecked = (id: string) => boolean;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
