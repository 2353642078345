import {Dropdown} from '@fluentui/react';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  margin-right: 0.5em;
`;

type PageSizeChangerProps = {
  size: number;
  count: number;
  onChange: (size: number) => void;
};

export const PAGE_SIZES = [25, 50, 100];
export const MIN_PAGE_SIZE = Math.min(...PAGE_SIZES);

export function PageSizeChanger(props: PageSizeChangerProps) {
  const options = PAGE_SIZES.map((size: number) => {
    return {
      key: size,
      text: String(size),
      selected: size === props.size,
    };
  });

  return (
    <Container>
      <Label>表示件数</Label>
      <Dropdown
        options={options}
        styles={{
          root: {
            width: '6em',
          },
          caretDownWrapper: {
            height: 30,
          },
          caretDown: {
            display: 'flex',
            alignItems: 'center',
            height: 30,
            fontSize: 24,
          },
        }}
        onChange={(e, opt) => {
          if (opt) {
            props.onChange(opt.key as number);
          }
        }}
      />
    </Container>
  );
}
