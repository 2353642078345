import {useWebSocket} from '../hooks/useWebSocket';
import {Params} from '../types/Params';

type Props = {
  path: string;
  params: Params;
  onMessage: (e: MessageEvent) => void;
};

export function WebSocketWidget({
  path,
  params,
  onMessage,
}: Props): JSX.Element | null {
  useWebSocket(path, params, onMessage);
  return null;
}
