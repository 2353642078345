import {
  DefaultButton,
  ITextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import {History, Location} from 'history';
import React, {RefObject} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {api, ApiContext, MFAProps} from '../api';
import {Banner, BannerProps} from '../common/banner';
import {Logo} from '../common/Logo';
import {bodyColor, textColorLight} from '../styles';
import {IntlShape, useIntl} from 'react-intl';
import {PreventImplicitSubmission} from '../common/PreventImplicitSubmission';

type Props = {
  location: Location;
  history: History;
  intl: IntlShape;
};

type State = {
  username: string;
  password: string;
  error: string;
  status: 'init' | 'require_mfa';
  mfaProps?: MFAProps;
  mfaCode: string;
  banner?: BannerProps;
};

export function SignIn(): JSX.Element | null {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  return <SignInComponent location={location} history={history} intl={intl} />;
}

class SignInComponent extends React.Component<Props, State> {
  private readonly password: RefObject<ITextField>;
  private readonly ctx: ApiContext;

  constructor(props: Props) {
    super(props);

    this.password = React.createRef();

    this.state = {
      username: '',
      password: '',
      error: '',
      status: 'init',
      mfaCode: '',
    };

    this.ctx = api.newContext();
  }

  async componentDidMount() {
    try {
      const resp = await api.getJson(this.ctx, '/signin');

      if (resp.banner) {
        this.setState({banner: resp.banner});
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this.ctx.abort();
  }

  onSignIn = async () => {
    try {
      const result = await api.signIn(
        this.ctx,
        this.state.username,
        this.state.password,
      );

      if (result.status === 'continue') {
        this.setState({
          status: 'require_mfa',
          mfaProps: result.mfaProps,
          error: '',
        });
        return;
      }

      if (result.status === 'success') {
        this.signInSuccess();
      }
    } catch (err) {
      this.signInFailed(err);
    }
  };

  onSignInWithMFA = async () => {
    if (!this.state.mfaProps) {
      this.setState({
        error: this.props.intl.formatMessage({
          id: 'SignIn.Failed',
          defaultMessage: 'Signin failed.',
        }),
      });
      return;
    }

    try {
      const result = await api.signInWithMFA(
        this.ctx,
        this.state.mfaProps?.token,
        this.state.mfaProps?.mfaType,
        this.state.mfaCode,
      );

      if (result.status === 'success') {
        this.signInSuccess();
      }
    } catch (err) {
      this.signInFailed(err);
    }
  };

  signInSuccess() {
    const params = new URLSearchParams(this.props.location.search);
    const path = params.has('to') ? params.get('to') || '/' : '/';
    window.location.replace(path);
  }

  signInFailed(err: unknown) {
    if (err instanceof Error) {
      this.setState({error: err.message});
    } else if (typeof err === 'string') {
      this.setState({error: err});
    }
  }

  renderBanner() {
    if (!this.state.banner) {
      return <Spacer />;
    }

    return (
      <BannerContainer>
        <Banner {...this.state.banner} />
      </BannerContainer>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <MessageBarContainer>
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          styles={{icon: {height: '16px'}}}>
          {this.state.error}
        </MessageBar>
      </MessageBarContainer>
    );
  }

  renderForm() {
    if (this.state.status === 'require_mfa') {
      return this.renderMFAForm();
    }

    return (
      <FormWrapper>
        <form>
          <Field
            placeholder={this.props.intl.formatMessage({
              id: 'SignIn.Username.Title',
              defaultMessage: 'Username',
            })}
            onChange={(_, v) => {
              this.setState({username: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.password.current!.focus();
              }
            }}
            value={this.state.username}
            autoComplete={'off'}
            autoCapitalize={'off'}
            autoCorrect={'off'}
            name={'username'}
          />
          <Field
            type={'password'}
            placeholder={this.props.intl.formatMessage({
              id: 'SignIn.Password.Title',
              defaultMessage: 'Password',
            })}
            componentRef={this.password}
            onChange={(_, v) => {
              this.setState({password: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.onSignIn();
              }
            }}
            value={this.state.password}
            autoComplete={'current-password'}
            name={'password'}
          />
          <PrimaryButton
            text={this.props.intl.formatMessage({
              id: 'SignIn.SignIn.Title',
              defaultMessage: 'Sign in',
            })}
            onClick={this.onSignIn}
            styles={{root: {marginTop: '1rem', width: '100%'}}}
          />
        </form>
      </FormWrapper>
    );
  }

  renderMFAForm() {
    return (
      <FormWrapper>
        <form>
          <Desc>{this.state.mfaProps?.mfaDesc}</Desc>
          <Field
            placeholder={this.props.intl.formatMessage({
              id: 'SignIn.MFACode.Title',
              defaultMessage: 'MFA Code',
            })}
            onChange={(_, v) => {
              this.setState({mfaCode: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.onSignInWithMFA();
              }
            }}
            value={this.state.mfaCode}
            autoComplete={'one-time-code'}
            autoCapitalize={'off'}
            autoCorrect={'off'}
            name={'mfa_code'}
          />
          <PreventImplicitSubmission />
          <PrimaryButton
            text={this.props.intl.formatMessage({
              id: 'SignIn.SignIn.Title',
              defaultMessage: 'Sign in',
            })}
            onClick={this.onSignInWithMFA}
            styles={{root: {marginTop: '1rem', width: '100%'}}}
          />
          <DefaultButton
            text={this.props.intl.formatMessage({
              id: 'Action.Cancel',
              defaultMessage: 'Cancel',
            })}
            onClick={() => {
              this.setState({status: 'init', username: '', password: ''});
            }}
            styles={{root: {marginTop: '1rem', width: '100%'}}}
          />
        </form>
      </FormWrapper>
    );
  }

  render() {
    return (
      <Wrapper>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {this.renderBanner()}
        {this.renderError()}
        {this.renderForm()}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: ${bodyColor};
`;

const BannerContainer = styled.div`
  text-align: initial;
  min-width: 300px;
  max-width: min(80vw, 600px);
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const Spacer = styled.div`
  height: 5rem;
`;

const FormWrapper = styled.div`
  & input {
    display: block;
  }
`;

const Field = styled(TextField)`
  margin-top: 1rem;
  width: 300px;
`;

const LogoContainer = styled.div``;

const MessageBarContainer = styled.div`
  font-size: 14px;
  width: 300px;
`;

const Desc = styled.div`
  width: 300px;
  text-align: left;
  color: ${textColorLight};
  font-size: 0.9rem;
`;
