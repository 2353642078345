import React from 'react';
import {useParams} from 'react-router-dom';
import {useApiContext} from '../api';
import {Item} from '../components/Item';

type URLMatch = {
  appId: string;
  resourceId: string;
};

export function AclScreen(): JSX.Element | null {
  const {appId, resourceId} = useParams<URLMatch>();
  const ctx = useApiContext();

  return (
    <Item appId={appId} id={resourceId + '/acl'} ctx={ctx} mode={'show'} />
  );
}
