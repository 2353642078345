import {getMonthEnd, getMonthStart} from '@fluentui/date-time-utilities';
import {getTheme} from '@fluentui/react';
import React, {CSSProperties, useMemo} from 'react';
import {Cell, CellProps} from 'react-table';
import styled from 'styled-components';
import {buildBackgroundImage} from '../common/calendar/backgroundImage';
import {DateRangeSelector} from '../common/calendar/DateRangeSelector';
import {EventBars, onClickEventFn} from '../common/calendar/EventBar';
import {MonthSelector} from '../common/calendar/MonthSelector';
import {DateRange} from '../common/DateRange';
import {
  CheckboxProps,
  ResourceDetailsTable,
  StickyColumn,
} from '../common/ResourceDetailsTable';
import {nullColumn} from '../common/table/Table';
import {COMMAND_BAR_HEIGHT} from '../consts';
import {Actions} from '../types/Action';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {SortOrder} from '../types/SortOrder';
import {TableTool} from '../types/Table';
import {TableColumn} from '../types/TableColumn';
import {ToolButton} from '../types/ToolButton';
import {formatDate} from '../widgets/common/dateutil';
import {buildColumns} from './GridList';
import {Sorter} from '../types/Sorter';

export type RowCalendarListProps = {
  type: string;
  schemaId: string;
  buttons?: ToolButton[];
  farButtons?: ToolButton[];
  sorters?: Sorter[];
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];
  columns?: TableColumn[];
  allowAdjacent?: boolean;
  customDateRange?: boolean;
  customDateRangeMaxLength?: number;
  checkbox?: boolean;
  unitWidth?: number;
};

type Props = {
  resourceList: ResourceList;
  items: ResourceDetails[];
  columns?: TableColumn[];
  events: {[itemId: string]: ResourceDetails};
  dateRange: DateRange;
  onChangeDateRange: (start: Date, end: Date) => void;
  onSelect: (item: ResourceDetails) => void;
  onClickEvent?: onClickEventFn;
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];
  onChangePageSize: (size: number) => void;
  onChangeCurrentPage: (index: number) => void;
  onChangeSorter: (id: string, current: SortOrder) => void;
  allowAdjacent?: boolean;
  customDateRange?: boolean;
  customDateRangeMaxLength?: number;
  actions: Actions;
  unitWidth?: number;
} & CheckboxProps;

export function RowCalendarList(props: Props): JSX.Element {
  const optColumns = buildOptColumns(props);

  const cols = useMemo(() => {
    return buildFieldColumns(buildColumns(props.resourceList, props.columns));
  }, [props.resourceList, props.columns]);

  return (
    <ResourceDetailsTable
      expandLastColumn={true}
      {...props}
      columns={cols}
      columnsDecorator={(columns) => {
        return [
          ...columns.map((c) => {
            c.sticky = 'left';
            return c;
          }),
          ...optColumns,
        ];
      }}
      headerDecorator={(header: JSX.Element) => {
        return (
          <HeaderContainer>
            {buildMonthSelector(props)}
            {header}
          </HeaderContainer>
        );
      }}
    />
  );
}

const defaultUnitWidth = 36;

function buildOptColumns(props: Props): StickyColumn[] {
  const unitWidth = props.unitWidth || defaultUnitWidth;

  const image = buildBackgroundImage(props.dateRange, unitWidth);
  const style = {backgroundImage: `url(${image})`};

  return [
    {
      Header: () => {
        return buildDaysHeader(props.dateRange, style, unitWidth);
      },
      Cell: (cellProps: CellProps<ResourceDetails>) => {
        const contents = buildContents(
          props.events,
          props.dateRange,
          cellProps.cell,
          unitWidth,
          props.onClickEvent,
          props.allowAdjacent,
        );
        return <Days style={style}>{contents}</Days>;
      },
      width: unitWidth * props.dateRange.len,
      accessor: '__events__',
    },
    nullColumn,
  ];
}

function buildFieldColumns(columns?: TableColumn[]): TableColumn[] {
  if (!columns || columns.length === 0) {
    return defaultColumns;
  }

  return columns;
}

const defaultColumns: TableColumn[] = [
  {
    fieldId: 'name',
    label: '名称',
    visible: true,
    width: 200,
    styles: {
      body: {
        whiteSpace: 'normal',
      },
    },
  },
];

function buildMonthSelector(props: Props): JSX.Element {
  return (
    <MonthSelectorContainer>
      {props.customDateRange ? (
        <DateRangeSelector
          dateRange={props.dateRange}
          onChangeDateRange={(range) => {
            props.onChangeDateRange(range.start, range.end);
          }}
          maxLength={props.customDateRangeMaxLength}
          style={{marginLeft: '0.5rem'}}
        />
      ) : (
        <MonthSelector
          month={new Date()}
          onSelect={(month: Date) => {
            const start = getMonthStart(month);
            const end = getMonthEnd(month);
            props.onChangeDateRange(start, end);
          }}
        />
      )}
    </MonthSelectorContainer>
  );
}

function buildDaysHeader(
  dateRange: DateRange,
  style: CSSProperties,
  unitWidth: number,
): JSX.Element {
  const days: JSX.Element[] = [];

  dateRange.eachDate((date, i) => {
    days.push(<Day key={`header-${i}`} date={date} unitWidth={unitWidth} />);
  });

  return <Days style={style}>{days}</Days>;
}

type DayProps = {
  date: Date;
  unitWidth: number;
};

function Day({date, unitWidth}: DayProps): JSX.Element {
  const d = date.getDate();
  const title = formatDate(date);

  switch (date.getDay()) {
    case 0:
      return (
        <Sunday unitWidth={unitWidth} title={title}>
          {d}
        </Sunday>
      );
    case 6:
      return (
        <Saturday unitWidth={unitWidth} title={title}>
          {d}
        </Saturday>
      );
    default:
      return (
        <Weekday unitWidth={unitWidth} title={title}>
          {d}
        </Weekday>
      );
  }
}

const buildContents = (
  events: {[key: string]: ResourceDetails},
  dateRange: DateRange,
  cell: Cell<ResourceDetails>,
  unitWidth: number,
  onClickEvent?: onClickEventFn,
  allowAdjacent?: boolean,
) => {
  const eventIds: string[] = cell.row.original['events'] || [];
  const evts = eventIds.filter((id) => !!events[id]).map((id) => events[id]);

  return (
    <EventBars
      events={evts}
      onClickEvent={onClickEvent}
      dateRange={dateRange}
      unitWidth={unitWidth}
      allowAdjacent={allowAdjacent}
    />
  );
};

const theme = getTheme();

const HeaderContainer = styled.div`
  display: flex;
`;

const MonthSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: ${COMMAND_BAR_HEIGHT}px;
`;

type CalendarProps = {
  unitWidth: number;
};

function calendarStyle(props: CalendarProps) {
  return {
    style: {
      width: props.unitWidth + 'px',
    },
  };
}

const Weekday = styled.div.attrs<CalendarProps>(calendarStyle)<CalendarProps>``;

const Sunday = styled(Weekday).attrs<CalendarProps>(
  calendarStyle,
)<CalendarProps>`
  color: ${theme.palette.red};
`;

const Saturday = styled(Weekday).attrs<CalendarProps>(
  calendarStyle,
)<CalendarProps>`
  color: ${theme.palette.blueMid};
`;

const Days = styled.div`
  display: flex;
  align-items: center;
  background-repeat: repeat;
  height: 100%;
  width: 100%;
`;
