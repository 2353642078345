import React from 'react';
import {Option} from '../../types/Field';
import {SchemaWithoutScreen} from '../../types/Schema';
import {SelectInputWidget} from '../SelectWidget';

type Props = {
  value: string;
  schemas: SchemaWithoutScreen[];
  onSelect: (schemaId: string) => void;
};

export function SchemaSelector(props: Props): JSX.Element {
  const options = buildOptions(props.schemas);

  return (
    <SelectInputWidget
      value={props.value}
      options={options}
      onChange={(id) => {
        props.onSelect(String(id));
      }}
      horizontal={true}
      multiple={false}
      hideClearButton={true}
      width={280}
    />
  );
}

function buildOptions(schemas: SchemaWithoutScreen[]): Option[] {
  return schemas.map((schema) => ({
    label: schema.name,
    value: schema.id,
  }));
}
