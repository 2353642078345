import React from 'react';
import {materialIconClassName} from './materialIcons';
import {Icon, IStyle} from '@fluentui/react';

type Props = {
  iconName: string;
  className?: string;
  style?: IStyle;
};

export function MaterialIcon(props: Props): JSX.Element | null {
  return (
    <Icon
      iconName={props.iconName}
      className={materialIconClassName(props.iconName, props.className)}
      style={{userSelect: 'none'}}
      styles={{
        root: props.style,
      }}
    />
  );
}
