import {useCallback, useEffect, useMemo, useState} from 'react';
import {api, ApiContext, useApiContext} from '../../api';
import {useForceUpdate} from '../../hooks/useForceUpdate';
import {useWebSocket} from '../../hooks/useWebSocket';
import {Resource} from '../../types/Resource';
import {ResourceDetails} from '../../types/ResourceDetails';
import {array} from '../../util';

export type AntennaScannedDataArgs = {
  schemaId: string;
  antennaId: string;
  antennaExt?: string;
  unknownFieldId: string;
};

export function useAntennaScannedData(
  args: AntennaScannedDataArgs,
): AntennaScannedData | null {
  const [id] = useState<string>(args.antennaId);
  const [ext] = useState<string | undefined>(args.antennaExt);
  const [res, setRes] = useState<Resource>();
  const forceUpdate = useForceUpdate();
  const ctx = useApiContext();

  useEffect(() => {
    (async () => {
      const scanId = buildScanId(id, ext);

      if (scanId === '') {
        return;
      }

      const res = await api.show(ctx, args.schemaId, scanId);
      setRes(res);
    })();
  }, [ctx, id, ext, args.schemaId]);

  const data = useMemo(
    () =>
      AntennaScannedData.newOrNull({
        res,
        unknownFieldId: args.unknownFieldId,
        forceUpdate,
        ctx,
      }),
    [forceUpdate, ctx, res, args.unknownFieldId],
  );

  const onMessage = useCallback(
    (e: MessageEvent) => {
      if (data === null) {
        return;
      }

      if (e.data === '') {
        data.set(empty(args.unknownFieldId));
        return;
      }

      try {
        const json = JSON.parse(e.data);
        data.set(json);
      } catch {
        data.set(empty(args.unknownFieldId));
        return;
      }
    },
    [data, args.unknownFieldId],
  );

  const params = useMemo(() => ({}), []);
  useWebSocket(res?.details.scan, params, onMessage);
  return data;
}

type Args = {
  res: Resource;
  unknownFieldId: string;
  forceUpdate: () => void;
  ctx: ApiContext;
};

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type PartialArgs = PartialBy<Args, 'res'>;

export class AntennaScannedData {
  private readonly schemaId: string;
  private readonly id: string;
  private readonly forceUpdate: () => void;
  private readonly ctx: ApiContext;
  private requesting: boolean;
  private readonly unknownFieldId: string;
  private _unknownIds: string[];

  static newOrNull(args: PartialArgs): AntennaScannedData | null {
    if (isArgs(args)) {
      return new AntennaScannedData(args);
    }

    return null;
  }

  constructor(args: Args) {
    this.schemaId = args.res.schema.id;
    this.id = args.res.id;
    this.forceUpdate = args.forceUpdate;
    this.ctx = args.ctx;
    this.unknownFieldId = args.unknownFieldId;
    this.requesting = false;
    this._unknownIds = getValues(args.res, args.unknownFieldId);
  }

  set(res: DetailsHolder) {
    this._unknownIds = getValues(res, this.unknownFieldId);
    this.forceUpdate();
  }

  get unknownIds(): string[] {
    return this._unknownIds;
  }

  async clear() {
    try {
      await api.request(this.ctx, this.schemaId, this.id, {
        action: 'cancel',
      });
    } catch (e) {
      console.log(e);
    } finally {
      this._unknownIds = [];
      this.forceUpdate();
    }
  }
}

function isArgs(args: PartialArgs): args is Args {
  return !!args.res;
}

function empty(fid: string): DetailsHolder {
  return {
    details: {id: '', [fid]: []},
  };
}

function getValues(res: DetailsHolder, fid: string): string[] {
  return array(res.details[fid]);
}

function buildScanId(id: string, ext?: string): string {
  if (!ext) {
    return `proxy/${id}`;
  }

  return `proxy/${id}.${ext}`;
}

type DetailsHolder = {
  details: ResourceDetails;
};
