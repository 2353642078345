import {FieldValueProps} from '../field/FieldValue';
import React from 'react';

export function LinkWidget(props: FieldValueProps) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={props.value}>
      {props.value}
    </a>
  );
}
