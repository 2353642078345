import styled from 'styled-components';
import {MAIN_HEADER_HEIGHT} from '../consts';

const MobileLayout = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${MAIN_HEADER_HEIGHT}px 1fr 30px;
    grid-template-areas: 'main-header' 'main-content' 'main-footer';
  `,
  Header: styled.div`
    grid-area: main-header;
  `,
  Content: styled.div`
    grid-area: main-content;
    overflow: auto;
  `,
  Footer: styled.div`
    grid-area: main-footer;
  `,
};

export {MobileLayout};
