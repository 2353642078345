import {DefaultButton, PrimaryButton} from '@fluentui/react';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {
  ImportResult,
  ImportResultComponent,
} from '../common/import/ImportResult';
import {Spinner} from '../common/Spinner';
import {borderColorLight} from '../styles';
import {FileInputWidget} from '../widgets/FileWidget';
import {
  Buttons,
  cancelLabel,
  DoneMessage,
  ErrorMessage,
  FormContainer,
  FormDescription,
} from './CommonForm';

type ImportFormProps = {
  appId: string;
  fileTypes?: string[];
  ctx: ApiContext;
  onCancel: () => void;
  onComplete: () => void;
  cancelLabel: string;
};

type Status = 'init' | 'importing' | 'done' | 'error';

export function ImportForm(props: ImportFormProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<Status>('init');
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [result, setResult] = useState<ImportResult | undefined>();
  const intl = useIntl();

  const onSubmit = async () => {
    try {
      setStatus('importing');
      const session = await api.postFormData(
        props.ctx,
        `/app/${props.appId}/import`,
        {},
        {file: files},
      );

      const intId = window.setInterval(async () => {
        const is = await api.getJson(
          props.ctx,
          `/app/${props.appId}/import/session/${session.id}`,
        );

        if (is.done) {
          if (intId) {
            clearInterval(intId);
            setIntervalId(null);
          }

          setResult(is.result);
          setStatus('done');
          props.onComplete();
        }
      }, 1000);

      setIntervalId(intId);
    } catch (e) {
      setStatus('error');

      if (e instanceof Error) {
        setError(e);
      }
    }
  };

  const onCancel = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }

    props.onCancel();
  };

  const cancelButton = (
    <DefaultButton
      onClick={onCancel}
      text={cancelLabel(props.cancelLabel, intl)}
    />
  );

  if (status === 'done') {
    return (
      <FormContainer>
        <DoneMessage>
          {intl.formatMessage({
            id: 'Import.Form.Done',
            defaultMessage: 'Imported.',
          })}
        </DoneMessage>
        <ImportResultContainer>
          <ImportResultComponent result={result} />
        </ImportResultContainer>
        <Buttons>{cancelButton}</Buttons>
      </FormContainer>
    );
  }

  if (status === 'importing') {
    return (
      <FormContainer>
        <Spinner />
        <Buttons>{cancelButton}</Buttons>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <FormDescription>
        {intl.formatMessage({
          id: 'Import.Form.Description',
          defaultMessage: 'Please select files to import.',
        })}
      </FormDescription>
      <FileInputWidget
        files={files}
        onChangeFiles={setFiles}
        onChangeExistingFiles={() => {}}
        existingFiles={[]}
        readOnly={false}
        multiple={false}
        fileTypes={props.fileTypes || ['.csv', '.xlsx']}
      />
      <ErrorMessage error={error} />
      <Buttons>
        <PrimaryButton
          disabled={files.length === 0}
          onClick={onSubmit}
          text={intl.formatMessage({
            id: 'Action.Import',
            defaultMessage: 'Import',
          })}
          styles={{root: {marginRight: '1rem'}}}
        />
        {cancelButton}
      </Buttons>
    </FormContainer>
  );
}

const ImportResultContainer = styled.div`
  padding: 0.5rem;
  border: 1px solid ${borderColorLight};
`;
