import {FormValues} from '../types/Form';
import {Resource} from '../types/Resource';
import {ResourceDetailsParams} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {
  convertFromRichTextState,
  convertToRichTextState,
} from '../widgets/RichTextWidget';

type ConverterFn = (v: any) => any;

const converters: {[key: string]: ConverterFn} = {
  rich_text: convertToRichTextState,
};

function noConverter(v: unknown) {
  return v;
}

type DeconverterFn = (v: any) => any;

const deconverters: {[key: string]: DeconverterFn} = {
  rich_text: convertFromRichTextState,
};

export function convertToFormValues(resource: Resource): FormValues {
  const values: FormValues = {};

  for (let f of resource.schema.fields) {
    const v = resource.details[f.id];

    if (v !== undefined) {
      const converter = converters[f.widget] || noConverter;
      values[f.id] = converter(v);
    }
  }

  return values;
}

export function convertFromFormValues(
  schema: Schema,
  values: FormValues,
): ResourceDetailsParams {
  const details: ResourceDetailsParams = {};

  for (let f of schema.fields) {
    const v = values[f.id];

    if (v !== undefined) {
      const deconverter = deconverters[f.widget] || noConverter;
      details[f.id] = deconverter(v);
    }
  }

  return details;
}
