import {
  ColorPicker,
  DefaultButton,
  getColorFromString,
  IColor,
  Persona,
  PersonaSize,
  SwatchColorPicker,
} from '@fluentui/react';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {Dialog} from '../common/Dialog';
import {useMessage} from '../hooks/useMessage';
import {Option} from '../types/Field';
import {ClearButton} from './common/ClearButton';

const pickers = {
  swatch: SwatchPicker,
  any: AnyPicker,
};

export type PickerType = keyof typeof pickers;

export const DEFAULT_PICKER_TYPE: PickerType = 'swatch';

type Props = {
  pickerType: PickerType;
  value: string;
  onChange: (v: string | undefined) => void;
  options?: Option[];
  readOnly?: boolean;
  columnCount?: number;
  defaultColor: string;
  inline: boolean;
};

export const DEFAULT_COLUMN_COUNT = 5;

export function ColorWidget(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <ColorOutputWidget {...props} />;
  }

  if (props.inline) {
    return <ColorInlineInputWidget {...props} />;
  }

  return <ColorInputWidget {...props} />;
}

function useColor(props: Props): {
  color?: IColor;
  defaultColor?: IColor;
} {
  const specifiedColor = getColorFromString(props.value);
  const defaultColor = getColorFromString(props.defaultColor);
  const color = specifiedColor ?? defaultColor;
  return {color, defaultColor};
}

function ColorOutputWidget(props: Props): JSX.Element | null {
  const {color} = useColor(props);

  if (!color) {
    return null;
  }

  return (
    <Persona
      initialsColor={color.str}
      size={PersonaSize.size24}
      onRenderInitials={nullIcon}
    />
  );
}

function ColorInlineInputWidget(props: Props): JSX.Element {
  const Picker = pickers[props.pickerType];
  const {color, defaultColor} = useColor(props);
  const [selected, setSelected] = useState<IColor | undefined>(color);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  return (
    <Container>
      <Picker
        {...props}
        color={selected}
        defaultColor={defaultColor}
        onChangeColor={(c) => {
          setSelected(c);

          if (timer) {
            clearTimeout(timer);
          }

          const newTimerId = setTimeout(() => {
            props.onChange(c?.str || '');
          }, 100);

          setTimer(newTimerId);
        }}
      />
    </Container>
  );
}

function ColorInputWidget(props: Props): JSX.Element {
  const dialog = useRef<Dialog>(null);
  const intl = useIntl();
  const Picker = pickers[props.pickerType];
  const {color, defaultColor} = useColor(props);
  const [selected, setSelected] = useState<IColor | undefined>(color);

  return (
    <Container>
      <DefaultButton
        style={{
          marginRight: '0.5rem',
          whiteSpace: 'nowrap',
        }}
        text={useMessage('Widget.Item.Select', 'Select')}
        onClick={() => {
          if (dialog.current) {
            dialog.current.showDialog();
          }
        }}
      />
      <Dialog
        ref={dialog}
        executeLabel={intl.formatMessage({
          id: 'Action.Select',
          defaultMessage: 'Select',
        })}
        maxWidth="100%"
        onExecute={async () => {
          props.onChange(selected?.str);
          return true;
        }}
        onCancel={() => {
          setSelected(color);
        }}
        styles={{buttons: {justifyContent: 'flex-end'}}}>
        <Picker
          {...props}
          color={color}
          defaultColor={defaultColor}
          onChangeColor={(c) => setSelected(c)}
        />
      </Dialog>
    </Container>
  );
}

type PickerProps = {
  color?: IColor;
  defaultColor?: IColor;
  onChangeColor: (color: IColor | undefined) => void;
  options?: Option[];
  readOnly?: boolean;
  columnCount?: number;
};

function SwatchPicker(props: PickerProps): JSX.Element {
  const options = buildOptions(props.options);

  return (
    <>
      <SwatchColorPicker
        disabled={props.readOnly}
        columnCount={props.columnCount ?? DEFAULT_COLUMN_COUNT}
        selectedId={props.color?.str || ''}
        cellShape={'circle'}
        cellHeight={30}
        cellWidth={30}
        colorCells={options}
        onChange={(_, v) => {
          const color = getColorFromString(v || '');
          props.onChangeColor(color);
        }}
      />
      <ClearButton
        onClick={() => props.onChangeColor(undefined)}
        styles={{root: {marginTop: '0.5rem'}}}
      />
    </>
  );
}

function AnyPicker(props: PickerProps): JSX.Element {
  const intl = useIntl();

  return (
    <>
      <ColorPicker
        color={props.color || ''}
        alphaType={'none'}
        showPreview={true}
        onChange={(_, v) => {
          props.onChangeColor(v);
        }}
        styles={anyPickerStyles}
      />
      <DefaultButton
        text={intl.formatMessage({
          id: 'Action.Reset',
          defaultMessage: 'Reset',
        })}
        onClick={() => props.onChangeColor(props.defaultColor)}
        styles={{root: {marginTop: '0.5rem', whiteSpace: 'nowrap'}}}
      />
    </>
  );
}

const anyPickerStyles = {
  panel: {
    padding: 0,
  },
};

const nullIcon = () => null;

const buildOptions = (options?: Option[]) => {
  if (!options) {
    return colors;
  }

  return options.map((o) => ({
    label: o.label,
    id: o.value,
    color: o.value,
  }));
};

const colors = [
  {label: 'green', id: '#498205', color: '#498205'},
  {label: 'dark green', id: '#0b6a0b', color: '#0b6a0b'},
  {label: 'teal', id: '#038387', color: '#038387'},
  {label: 'cyan', id: '#005b70', color: '#005b70'},
  {label: 'light blue', id: '#4f6bed', color: '#4f6bed'},
  {label: 'blue', id: '#0078d4', color: '#0078d4'},
  {label: 'dark blue', id: '#004e8c', color: '#004e8c'},
  {label: 'violet', id: '#8764b8', color: '#8764b8'},
  {label: 'purple', id: '#5c2e91', color: '#5c2e91'},
  {label: 'magenta', id: '#881798', color: '#881798'},
  {label: 'light pink', id: '#c239b3', color: '#c239b3'},
  {label: 'pink', id: '#e3008c', color: '#e3008c'},
  {label: 'burgundy', id: '#750b1c', color: '#750b1c'},
  {label: 'dark red', id: '#a4262c', color: '#a4262c'},
  {label: 'light red', id: '#d13438', color: '#d13438'},
  {label: 'orange', id: '#ca5010', color: '#ca5010'},
  {label: 'rust', id: '#8e562e', color: '#8e562e'},
  {label: 'gold', id: '#986f0b', color: '#986f0b'},
  {label: 'warm gray', id: '#7a7574', color: '#7a7574'},
  {label: 'cool gray', id: '#69797e', color: '#69797e'},
];

const Container = styled.div``;
