import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {api, useApiContext} from '../api';
import {PrimaryHeader} from '../common/PrimaryHeader';
import {extractSections} from '../common/Schema';
import {Spinner} from '../common/Spinner';
import {Toolbar} from '../common/Toolbar';
import {ItemDetails} from '../components/ItemDetails';
import {ItemForm, Values} from '../components/ItemForm';
import {FormFiles} from '../types/Form';
import {Resource} from '../types/Resource';
import {useIntl} from 'react-intl';
import {useNav} from '../hooks/useNav';

type Status = 'loading' | 'loaded' | 'editing' | 'error';

export function SystemConfigScreen(): JSX.Element | null {
  const ctx = useApiContext();
  const [status, setStatus] = useState<Status>('loading');
  const [res, setRes] = useState<Resource | null>(null);
  const intl = useIntl();
  const {setNav} = useNav();

  useEffect(() => {
    (async () => {
      try {
        const res = await api.getJson(ctx, '/sys/config');
        setRes(res);
        setNav(res.nav);
        setStatus('loaded');
      } catch (e) {}
    })();
  }, [ctx]);

  const onEdit = () => {
    setStatus('editing');
  };

  const onSaveEdit = async (values: Values, files: FormFiles) => {
    if (!res) {
      return;
    }

    const newRes = await api.update(ctx, res.schema.id, res.id, values, files);
    setRes(newRes);
    setStatus('loaded');
  };

  if (status === 'loading' || !res) {
    return <Spinner />;
  }

  if (status === 'editing') {
    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader
            iconName={'Edit'}
            text={intl.formatMessage({
              id: 'Action.Edit',
              defaultMessage: 'Edit',
            })}
          />
        </ItemHeader>
        <ItemBody>
          <ItemForm
            ctx={ctx}
            resource={res}
            sections={extractSections(res.schema)}
            labelPosition={'left'}
            onCancel={() => {
              setStatus('loaded');
            }}
            onSave={onSaveEdit}
            actions={{}}
          />
        </ItemBody>
      </ItemContainer>
    );
  }

  return (
    <Container>
      <ItemContainer>
        <ItemHeader>
          <Toolbar
            buttons={[
              {
                type: 'edit',
                onClick: onEdit,
              },
            ]}
          />
        </ItemHeader>
        <ItemBody>
          <ItemDetails
            resource={res}
            sections={extractSections(res.schema)}
            labelPosition={'left'}
            actions={{}}
          />
        </ItemBody>
      </ItemContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemHeader = styled.div``;

const ItemBody = styled.div`
  height: 100%;
  overflow: auto;
`;
