import {Resource} from '../../types/Resource';
import {ToolButton} from '../../types/ToolButton';

export function buildCreateRefParams(
  res?: Resource | null,
  button?: ToolButton,
) {
  if (!button) {
    return {};
  }

  if (!button.refFieldId || !res) {
    return {...button.params};
  }

  const fid = button.keyFieldId || 'id';

  return {
    [button.refFieldId]: res.details[fid],
    ...button.params,
  };
}
