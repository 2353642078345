import {
  INavLink,
  INavLinkGroup,
  Nav,
  Panel,
  PanelType,
  ScrollablePane,
} from '@fluentui/react';
import React, {useState} from 'react';
import styled from 'styled-components';
import {MAIN_HEADER_HEIGHT, MENU_WIDTH} from '../consts';
import {MaterialIcon} from '../icons/MaterialIcon';
import {bodyColorHovered, borderColorLight} from '../styles';

type MenuContainerProps = {
  expanded: boolean;
};

type MenuProps = {
  menuItems: INavLink[];
  path: string;
};

export function Menu(props: MenuProps): JSX.Element | null {
  const [expanded, setExpanded] = useState(false);
  const menuText = 'メニュー';
  const menu: INavLinkGroup[] = [
    {
      links: props.menuItems,
    },
  ];

  const open = () => {
    setExpanded(true);
  };

  const close = () => {
    setExpanded(false);
  };

  return (
    <div>
      <MenuButton onClick={open} label={menuText} expanded={expanded} />
      <Panel
        className={'nav-panel'}
        hasCloseButton={false}
        closeButtonAriaLabel="Close"
        styles={{
          content: {
            padding: 0,
          },
          commands: {
            paddingTop: 0,
          },
        }}
        type={PanelType.customNear}
        customWidth={MENU_WIDTH + 'px'}
        isOpen={expanded}
        isLightDismiss={true}
        onDismiss={close}>
        <div>
          <MenuButton onClick={close} label={menuText} expanded={expanded} />
          <NavContainer>
            <Nav
              groups={menu}
              selectedKey={props.path}
              expandButtonAriaLabel={'Expand or collapse'}
              styles={{
                chevronIcon: {
                  display: 'flex',
                  alignItems: 'center',
                  left: 2,
                  fontSize: 24,
                },
              }}
            />
          </NavContainer>
        </div>
      </Panel>
    </div>
  );
}

type MenuButtonProps = {
  onClick: () => void;
  label: string;
  expanded: boolean;
};

function MenuButton(props: MenuButtonProps): JSX.Element | null {
  return (
    <MenuContainer onClick={props.onClick} expanded={props.expanded}>
      <MaterialIcon iconName={'menu'} />
      <MenuText>{props.label}</MenuText>
    </MenuContainer>
  );
}

const MenuContainer = styled.div.attrs<MenuContainerProps>(
  (props: MenuContainerProps) => {
    if (props.expanded) {
      return {
        style: {
          borderBottomColor: borderColorLight,
        },
      };
    }

    return null;
  },
)<MenuContainerProps>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  height: ${MAIN_HEADER_HEIGHT}px;
  border-bottom: 1px solid transparent;
  width: ${MENU_WIDTH}px;

  &:hover {
    background-color: ${bodyColorHovered};
    border-bottom-color ${borderColorLight};
  }
`;

const MenuText = styled.div`
  margin-left: 0.5rem;
`;

const NavContainer = styled(ScrollablePane)`
  margin-top ${MAIN_HEADER_HEIGHT}px;
  width: 100%;

  & .ms-Nav-groupContent {
    margin-bottom: 0;
  }

  & .ms-Icon {
    margin-left: 20px;
    width: 16px;
    font-size: 16px;
  }

  & .ms-Nav-navItems .ms-Nav-navItems .ms-Nav-chevronButton {
    left: 30px;
    
    & + .ms-Button .ms-Nav-linkText {
      margin-left: 16px;
    }
  }

  & .ms-Nav-navItems .ms-Nav-navItems .ms-Nav-navItems .ms-Icon {
      margin-left: 30px;
  }
`;
