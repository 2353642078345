import * as React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {AttachmentFiles} from '../types/Resource';
import {array} from '../util';
import {widgetProps} from '../widgets/common';
import {FileInputWidget, FileOutputWidget} from '../widgets/FileWidget';

export type FileWidgetProps = FieldValueProps & {
  attachmentFiles: AttachmentFiles;
};

export function FileField(props: FileWidgetProps): JSX.Element | null {
  const vs = array(props.value);
  const existingFiles = vs.map((v) => props.attachmentFiles[v]);

  if (props.readOnly) {
    return <FileOutputWidget files={existingFiles} />;
  }

  return (
    <FileInputWidget
      files={[]}
      onChangeFiles={(v) => {
        props.onChange({[props.field.id]: v}, true);
      }}
      existingFiles={existingFiles}
      onChangeExistingFiles={(v) => {
        props.onChange({[props.field.id]: v}, false);
      }}
      readOnly={!props.field.editable}
      fileTypes={props.field.fileTypes}
      multiple={widgetProps(props.field, 'multiple', true)}
    />
  );
}
