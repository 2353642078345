import {Schema} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {selectListComponent} from '../components/util';

export function getSorters(s: Schema): Sorter[] | undefined {
  const list = selectListComponent(s.screen.components);

  if (!list) {
    return [];
  }

  return list.sorters;
}

export function getDefaultSorter(s?: Schema): Sorter {
  if (!s) {
    return getFallbackDefaultSorter();
  }

  const sorters = getSorters(s);

  if (sorters) {
    for (let s of sorters) {
      if (s.default) {
        return s;
      }
    }
  }

  return getFallbackDefaultSorter();
}

function getFallbackDefaultSorter(): Sorter {
  return {
    id: 'updated_at',
    label: '更新日時',
    visible: true,
    default: true,
    defaultOrder: 'desc',
    currentOrder: 'desc',
  };
}
