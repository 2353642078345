import React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {ImageOutputWidget} from '../widgets/ImageWidget';
import {widgetProps} from '../widgets/common';

export function ImageField(props: FieldValueProps) {
  const size = widgetProps(props.field, 'size', undefined);
  const clickable = widgetProps<boolean>(props.field, 'clickable', true);

  return (
    <ImageOutputWidget
      value={props.value}
      alt={props.field.label}
      size={size}
      clickable={clickable}
    />
  );
}
