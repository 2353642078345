import {Schema} from '../types/Schema';
import {Section} from '../types/Section';

export function extractSections(schema: Schema): Section[] {
  const s = schema.screen;

  if (!s) {
    return [];
  }

  for (let key of Object.keys(s.components)) {
    const c = s.components[key];

    if (c.type === 'item') {
      return c.sections || [];
    }
  }

  return [];
}
