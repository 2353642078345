import {Panel} from '@fluentui/react';
import {Location} from 'history';
import React, {RefObject, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {api, ApiContext, buildConfigUrl, makeParams, Query} from '../api';
import {ApiError} from '../ApiError';
import {buildCreateRefParams} from '../common/actions/CreateRef';
import {buildDataForNew} from '../common/actions/util';
import {buttonProps} from '../common/buttons/Buttons';
import {
  buildCheckedItems,
  CheckedItems,
  ItemsMap,
} from '../common/CheckedItems';
import {DateRange, newDate} from '../common/DateRange';
import {Dialog} from '../common/Dialog';
import {AppRequestDialog} from '../common/dialogs/AppRequestDialog';
import {DeleteDialog} from '../common/dialogs/DeleteDialog';
import {ExportDialog} from '../common/dialogs/ExportDialog';
import {ImportDialog} from '../common/dialogs/ImportDialog';
import {ItemFormDialog} from '../common/dialogs/ItemFormDialog';
import {RequestDialog} from '../common/dialogs/RequestDialog';
import {fold, isFolded} from '../common/DisplayFoldSwitch';
import {ErrorMsg} from '../common/ErrorMsg';
import {newDummyNav} from '../common/Nav';
import {
  applyDefaultParams,
  applySortParams,
  excludeCommonParams,
  excludeKnownParams,
  newURLSearchParams,
} from '../common/params';
import {PrimaryHeader} from '../common/PrimaryHeader';
import {buildPagerProps} from '../common/ResourceDetailsTable';
import {extractSections} from '../common/Schema';
import {minus} from '../common/set/set';
import {Spinner} from '../common/Spinner';
import {Toolbar} from '../common/Toolbar';
import {UIActions} from '../common/uiactions/UIActions';
import {BatchEditForm} from '../components/BatchEditForm';
import {
  getType,
  OnSelectRowProps,
  selectAreaAndComponent,
  selectComponent,
  selectListComponent,
} from '../components/util';
import {
  DisplayUnfoldBar,
  DisplayUnfoldBarProps,
} from '../components/DisplayUnfoldBar';
import {Filter, FilterProps, ignoreEmpty} from '../components/Filter';
import {GridList, GridListProps} from '../components/GridList';
import {ItemDetails, ItemDetailsProps} from '../components/ItemDetails';
import {ItemForm} from '../components/ItemForm';
import {
  RowCalendarList,
  RowCalendarListProps,
} from '../components/RowCalendarList';
import {SimpleListProps} from '../components/SimpleList';
import {
  MAIN_HEADER_HEIGHT,
  PARAM_KEY_END_DATE,
  PARAM_KEY_ORDER,
  PARAM_KEY_PAGE,
  PARAM_KEY_SIZE,
  PARAM_KEY_SORT,
  PARAM_KEY_START_DATE,
} from '../consts';
import {NavContext, SetNav} from '../context';
import {getDateText} from '../dates/dates';
import {GridLayout} from '../layout/GridLayout';
import {borderColorLightest} from '../styles';
import {Actions} from '../types/Action';
import {Errors} from '../types/Errors';
import {FormFiles, FormValues} from '../types/Form';
import {Params} from '../types/Params';
import {Resource} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Component, Schema} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {ToolButton} from '../types/ToolButton';
import {downloadByPost} from '../util';
import {getEmptyMessage} from './listscreen/common';
import {
  DialogContents,
  ItemBody,
  ItemContainer,
  ItemHeader,
  ListBody,
  ListContainer,
} from './listscreen/elements';
import {ListHeader} from './listscreen/ListHeader';
import {SimpleListBody} from './listscreen/SimpleListBody';
import {
  CustomLayout,
  mergeComponents,
  mergeSizes,
} from '../common/CustomLayout';
import {getDefaultSorter, getSorters} from '../common/sorter';
import {isFiltered} from '../components/list-util';
import {MessageDialog} from '../common/dialogs/MessageDialog';

type URLMatch = {
  appId: string;
};

type Props = URLMatch & {
  location: Location;
  setNav: SetNav;
};

type Status =
  | 'init'
  | 'loading'
  | 'loaded'
  | 'creating'
  | 'creating_ref'
  | 'creating_dialog'
  | 'editing'
  | 'editing_dialog'
  | 'batch_editing'
  | 'copying'
  | 'deleting'
  | 'requesting_app'
  | 'requesting_item'
  | 'requested'
  | 'importing'
  | 'exporting'
  | 'messaging'
  | 'error'
  | 'fatal';

type State = {
  status: Status;
  errors: Errors;
  resourceList?: ResourceList;
  schema?: Schema;
  defaultParams?: Params;
  serverDefaultParams?: Params;
  focusedId: string;
  focused: Resource | null;
  newResource?: Resource;
  button?: ToolButton;
  buttonParams?: Params;
  sorterId?: string;
  sortOrder?: string;
  filtered?: boolean;
  message: string;
  filterResourceList?: ResourceList;
  filterSchema?: Schema;
  filterShownInPanel?: boolean;
  checkedIds: Set<string>;
  checkedItems: ItemsMap;
  otherParams: {};
} & CustomLayout;

export class ListScreen extends React.Component<Props, State> {
  private readonly batchEditDialog: RefObject<Dialog>;
  private readonly ctx: ApiContext;
  private readonly appId: string;
  private readonly search: string;
  private readonly uiActions: UIActions;

  constructor(props: Props) {
    super(props);

    this.state = {
      status: 'init',
      focusedId: '',
      focused: null,
      errors: {},
      message: '',
      customRows: {},
      customColumns: {},
      customComponents: {},
      checkedIds: new Set(),
      checkedItems: {},
      otherParams: {},
    };

    this.batchEditDialog = React.createRef();
    this.appId = props.appId;
    this.search = props.location.search;
    this.ctx = api.newContext();
    this.uiActions = this.buildUIActions();
  }

  componentDidMount = async () => {
    try {
      const clientDefaultParams = new URLSearchParams(this.search);
      const schema = await api.fetchSchema(
        this.ctx,
        this.appId,
        clientDefaultParams,
      );
      applyDefaultParams(clientDefaultParams, schema);
      const sorter = getDefaultSorter(schema);
      applySortParams(clientDefaultParams, sorter);

      const resourceList = await api.list(
        this.ctx,
        this.appId,
        clientDefaultParams,
      );
      const filterResourceList = await this.fetchFilterResourceList(
        resourceList,
      );

      this.props.setNav(resourceList.nav);

      const layout = this.customLayout(schema) || {
        customRows: {},
        customColumns: {},
        customComponents: {},
      };

      const defaultParams = excludeCommonParams(resourceList.params);
      const serverDefaultParams = excludeKnownParams(
        defaultParams,
        clientDefaultParams,
      );

      this.setState({
        resourceList,
        defaultParams,
        serverDefaultParams,
        schema,
        filterResourceList: filterResourceList,
        filterSchema: filterResourceList?.schema,
        sorterId: sorter.id,
        sortOrder: sorter.defaultOrder ?? 'desc',
        ...layout,
      });
    } catch (e) {
      this.handleError(e);
      this.setState({status: 'fatal'});
      this.props.setNav(newDummyNav());
    }
  };

  componentWillUnmount() {
    this.ctx.abort();
  }

  customLayout(schema: Schema): CustomLayout | null {
    if (!isFolded()) {
      return null;
    }

    const {area, component} = selectAreaAndComponent<FilterProps>(
      schema.screen.components,
      'filter',
    );

    if (!area || !component) {
      return null;
    }

    return fold(this.state, area, component.toggle);
  }

  get mainList(): ResourceList | undefined {
    return this.state.resourceList;
  }

  getAppId(): string {
    if (this.mainList) {
      return this.mainList.schema.id;
    }

    return this.appId;
  }

  getScreen() {
    if (!this.mainList) {
      return null;
    }

    return this.mainList.schema.screen;
  }

  getPropagatingParams(): Params | undefined {
    if (!this.mainList) {
      return;
    }

    const propagatingKeys = ['_screen'];

    const params: Params = {};

    for (let key of propagatingKeys) {
      if (this.mainList.params[key]) {
        params[key] = this.mainList.params[key];
      }
    }

    if (Object.keys(params).length === 0) {
      return;
    }

    return params;
  }

  fetchFilterResourceList = async (resourceList: ResourceList) => {
    const filterProps = selectComponent<FilterProps>(
      resourceList.schema.screen.components,
      'filter',
    );

    if (!filterProps || !filterProps.schemaId) {
      return;
    }

    const schema = await api.fetchSchema(this.ctx, filterProps.schemaId, {});
    const sorter = getDefaultSorter(schema);
    const params = new URLSearchParams(this.search);
    applySortParams(params, sorter);
    return await api.list(this.ctx, filterProps.schemaId, params);
  };

  onSort = async (button: ToolButton, sorter?: Sorter) => {
    const sorterId = (sorter || {}).id;

    if (!sorterId) {
      return;
    }

    const sortOrder =
      this.state.sorterId === sorterId
        ? toggleOrder(this.state.sortOrder)
        : defaultOrder(sorter!);

    const params = Object.assign({}, this.mainList!.params, {
      [PARAM_KEY_SORT]: sorterId,
      [PARAM_KEY_ORDER]: sortOrder,
    });

    const resourceList = await api.list(
      this.ctx,
      this.mainList!.schema.id,
      params,
    );
    this.setState({
      resourceList,
      sorterId,
      sortOrder,
    });
  };

  onChangeDateRange = async (start: Date, end: Date) => {
    if (!this.mainList) {
      return;
    }

    const otherParams = {
      [PARAM_KEY_START_DATE]: getDateText(start),
      [PARAM_KEY_END_DATE]: getDateText(end),
    };

    const params: Params = {...this.mainList.params, ...otherParams};

    const list = await api.list(this.ctx, this.mainList.schema.id, params);

    this.setState({
      resourceList: list,
      filterResourceList: list,
      otherParams: otherParams,
    });
  };

  onSelectRow = async (item: ResourceDetails) => {
    if (!item) {
      return;
    }

    const resId = item.id;
    const appId = item.schema_id || this.appId;

    const listProps = selectListComponent(this.getScreen()!.components);

    if (listProps && listProps.onSelectRow) {
      return this.doSelectRow(listProps.onSelectRow, appId, resId, item);
    }

    return await this.onSelectItem(item);
  };

  doSelectRow = async (
    onSelectRow: OnSelectRowProps,
    appId: string,
    resId: string,
    item: ResourceDetails,
  ) => {
    const typ = getType(onSelectRow, item);
    const url = buildUrl(onSelectRow, appId, resId, item);

    switch (typ) {
      case 'go':
        window.location.assign(url);
        return;
      case 'open-in-new-tab':
        window.open(url, '_blank');
        return;
      case 'show':
        return this.onSelectItem(item);
      case 'nop':
        return;
    }
  };

  onSelectItem = async (item: ResourceDetails) => {
    if (!item) {
      return;
    }

    return await this.onSelectId(
      item.schema_id || this.appId,
      item.id,
      this.getPropagatingParams(),
    );
  };

  onSelectId = async (appId: string, resId: string, query?: Query) => {
    this.setState({
      focusedId: resId,
    });

    const props = selectComponent<ItemDetailsProps>(
      this.getScreen()!.components,
      'item',
    );

    if (!props) {
      const params = makeParams(query);
      window.open(`/app/${appId}/${resId}${params}`);
      return;
    }

    this.setState({
      status: 'loading',
      errors: {},
    });

    if (!resId) {
      this.setState({
        status: 'error',
      });
      return;
    }

    const resource = await api.show(this.ctx, appId, resId, query);

    this.setState({
      focused: resource,
      focusedId: resource.id,
      status: 'loaded',
    });
  };

  onCopy = async () => {
    this.setState({
      status: 'loading',
    });

    const resource = await api.copy(
      this.ctx,
      this.state.focused!.schema.id,
      this.state.focused!.id,
    );

    this.setState({
      newResource: resource,
      status: 'copying',
    });
  };

  onCreate = async (button: ToolButton) => {
    if (button.openIn === 'dialog') {
      this.setState({
        status: 'creating_dialog',
        button: button,
        buttonParams: {...button.params},
      });
      return;
    }

    const props = selectComponent<ItemDetailsProps>(
      this.getScreen()!.components,
      'item',
    );

    const appId = button.itemType || this.getAppId();

    if (!props) {
      window.open(`/app/${appId}/new`);
      return;
    }

    this.setState({
      status: 'loading',
    });

    const params = new URLSearchParams(this.search);
    const data = buildDataForNew(toMap(params), this.state.checkedIds);
    const resource = await api.newByPost(this.ctx, appId, data);

    this.setState({
      newResource: resource,
      status: 'creating',
      button: button,
    });
  };

  onCreateRef = async (button: ToolButton) => {
    const currentRes = this.state.focused!;
    const params = buildCreateRefParams(currentRes, button);

    if (button.openIn === 'dialog') {
      this.setState({
        status: 'creating_dialog',
        button: button,
        buttonParams: params,
      });
      return;
    }

    this.setState({
      status: 'loading',
    });

    const appId = button.itemType || this.appId;
    const resource = await api.new_(this.ctx, appId, params);

    this.setState({
      newResource: resource,
      status: 'creating_ref',
      button: button,
    });
  };

  onEdit = async (button: ToolButton) => {
    if (button.openIn === 'dialog') {
      this.setState({
        status: 'editing_dialog',
        button: button,
        buttonParams: undefined,
      });
      return;
    }

    this.setState({
      status: 'loading',
    });

    try {
      const resource = await api.edit(
        this.ctx,
        this.state.focused!.schema.id,
        this.state.focused!.id,
      );

      this.setState({
        focused: resource,
        focusedId: resource.id,
        status: 'editing',
      });
    } catch (e) {
      this.handleError(e);
      this.setState({
        status: 'messaging',
      });
    }
  };

  onDelete = async (button: ToolButton) => {
    this.setState({
      status: 'deleting',
      button: button,
    });
  };

  onShow = async () => {
    window.open(
      `/app/${this.state.focused!.schema.id}/${this.state.focused!.id}`,
    );
  };

  onExport = async (button: ToolButton) => {
    const appId = button.itemType || this.mainList!.schema.id;
    return this.downloadList(appId, 'export');
  };

  onImport = async (button: ToolButton) => {
    this.setState({
      status: 'importing',
      button,
    });
  };

  onDownload = async (button: ToolButton) => {
    if (button.path) {
      //TODO params?
      window.open(button.path);
      return;
    }

    const appId = button.itemType || this.mainList!.schema.id;
    return this.downloadList(appId, 'download', button.params);
  };

  onLink = async (button: ToolButton) => {
    const path = buttonProps(button, 'path', button.path);
    window.open(path);
  };

  onDownloadItem = async (button: ToolButton) => {
    if (button.path) {
      window.open(button.path);
      return;
    }

    const appId = this.state.focused!.schema.id;
    const path = `${this.state.focused!.id}/download`;
    downloadByPost(`/app/${appId}/${path}`, button.params || {});
  };

  onAppRequest = async (button: ToolButton) => {
    this.setState({
      status: 'requesting_app',
      button,
    });
  };

  onRequestItem = async (button: ToolButton) => {
    await this.setState({
      status: 'requesting_item',
      button,
    });
  };

  onSaveCreate = async (
    itemType: string,
    values: FormValues,
    files: FormFiles,
    relatedValues: any,
  ) => {
    const resource = await api.create(
      this.ctx,
      itemType,
      values,
      files,
      relatedValues,
    );

    const resourceList = await api.list(
      this.ctx,
      this.mainList!.schema.id,
      this.mainList!.params,
    );

    this.setState({
      status: 'loaded',
      resourceList,
      focused: resource,
      focusedId: resource.id,
    });
  };

  onSaveCreateRef = async (
    itemType: string,
    values: FormValues,
    files: FormFiles,
    relatedValues: any,
  ) => {
    await api.create(this.ctx, itemType, values, files, relatedValues);

    const resourceList = await api.list(
      this.ctx,
      this.mainList!.schema.id,
      this.mainList!.params,
    );

    let focused = null;

    if (this.state.focused && this.state.focusedId) {
      focused = await api.show(
        this.ctx,
        this.state.focused.schema.id,
        this.state.focusedId,
      );
    }

    this.setState({
      status: 'loaded',
      resourceList,
      focused,
    });
  };

  onSaveEdit = async (
    values: FormValues,
    files: FormFiles,
    relatedValues: any,
  ) => {
    const resource = await api.update(
      this.ctx,
      this.state.focused!.schema.id,
      this.state.focused!.id,
      values,
      files,
      relatedValues,
    );

    const resourceList = await api.list(
      this.ctx,
      this.mainList!.schema.id,
      this.mainList!.params,
    );

    this.setState({
      status: 'loaded',
      resourceList,
      focused: resource,
      focusedId: resource.id,
    });
  };

  onAfterDelete = async () => {
    try {
      const resourceList = await api.list(
        this.ctx,
        this.mainList!.schema.id,
        this.mainList!.params,
      );

      this.setState({
        status: 'loaded',
        resourceList,
        focused: null,
        focusedId: '',
      });

      return true;
    } catch (e) {
      this.handleError(e);
      return false;
    }
  };

  onOpenSearch = () => {
    this.setState({filterShownInPanel: true});
  };

  onCloseSearch = () => {
    this.setState({filterShownInPanel: false});
  };

  onSearch = async (values: FormValues) => {
    const list = this.state.filterResourceList || this.mainList;

    if (!list) {
      return;
    }

    const specifiedValues = ignoreEmpty(
      values,
      Object.keys(this.state.defaultParams || {}),
    );

    const filtered = isFiltered(specifiedValues, this.state.defaultParams);
    // If filter is cleared, we use `this.appId` to show default list.
    const appId = filtered ? list.schema.id : this.appId;

    const merged = Object.assign(
      {},
      this.state.defaultParams,
      this.state.otherParams,
      this.getBasicListParams(list, appId),
      specifiedValues,
    );

    // Default parameters specified on the server side must be sent to the server even if the value is empty.
    // This is because the server will want to know that an empty value was specified on the server side.
    // On the other hand, default parameters specified on the client side do not need to be sent to the server side if they are empty.
    // There is no need to distinguish between parameters set automatically on the client side and parameters specified by the user.
    const params = ignoreEmpty(
      merged,
      Object.keys(this.state.serverDefaultParams || {}),
    );

    const resourceList = await api.list(this.ctx, appId, params);

    const state: Pick<
      State,
      'resourceList' | 'filtered' | 'filterResourceList'
    > = {
      resourceList,
      filtered,
    };

    if (filtered) {
      state.filterResourceList = resourceList;
    }

    this.setState(state);
  };

  onSearchAndClose = async (values: FormValues) => {
    await this.onSearch(values);
    this.onCloseSearch();
  };

  onResetSearch = async () => {
    this.setState({filtered: false});
  };

  onResetSearchAndClose = async () => {
    await this.onResetSearch();
    this.onCloseSearch();
  };

  onActionEnd = async () => {
    const resourceList = await api.list(
      this.ctx,
      this.mainList!.schema.id,
      this.mainList!.params,
    );

    this.setState({
      resourceList,
    });
  };

  onFold = async (state: CustomLayout) => {
    this.setState(state);
  };

  onUnfold = async (state: CustomLayout) => {
    this.setState(state);
  };

  onCheck = async (ids: Set<string>, checked: boolean) => {
    if (checked) {
      const checkedIds = new Set([...this.state.checkedIds, ...ids]);
      const checkedItems = buildCheckedItems(ids, this.mainList);

      this.setState({
        checkedIds,
        checkedItems: {...this.state.checkedItems, ...checkedItems},
      });
      return;
    }

    const checkedIds = minus(this.state.checkedIds, ids);
    this.setState({checkedIds});
  };

  onBatchEdit = async () => {
    this.batchEditDialog.current!.showDialog();

    this.setState({
      status: 'batch_editing',
    });
  };

  onConfig = async (button: ToolButton) => {
    const schemaId = button.schemaId ?? this.getAppId();
    const url = buildConfigUrl(schemaId);
    window.location.assign(url);
    return;
  };

  getBasicListParams(list: ResourceList, targetAppId: string) {
    const curr = list.params;

    if (list.schema.id === targetAppId) {
      return {
        [PARAM_KEY_SORT]: curr[PARAM_KEY_SORT],
        [PARAM_KEY_ORDER]: curr[PARAM_KEY_ORDER],
        [PARAM_KEY_SIZE]: curr[PARAM_KEY_SIZE],
        [PARAM_KEY_PAGE]: 0,
      };
    }

    const schema =
      this.state.filterSchema?.id === targetAppId
        ? this.state.filterSchema
        : this.state.schema;
    const sorter = getDefaultSorter(schema);

    return {
      [PARAM_KEY_SORT]: sorter.id,
      [PARAM_KEY_ORDER]: sorter.defaultOrder,
    };
  }

  reloadList = async () => {
    const resourceList = await api.list(
      this.ctx,
      this.mainList!.schema.id,
      this.mainList!.params,
    );

    this.setState({
      status: 'loaded',
      resourceList,
    });
  };

  loadItem = async (schemaId: string, id: string) => {
    const res = await api.show(this.ctx, schemaId, id);

    this.setState({
      focused: res,
      focusedId: res.id,
    });

    this.reloadList();
  };

  closeItem = async () => {
    this.setState({
      focused: null,
      focusedId: '',
    });

    this.reloadList();
  };

  handleError(e: any) {
    if (e instanceof ApiError) {
      this.setState({
        errors: e.getMessageMap(),
      });
    }
  }

  resetStatus = () => {
    this.setState({status: 'loaded', errors: {}});
  };

  async downloadList(
    appId: string,
    path: string,
    optParams?: {[key: string]: any},
  ) {
    this.setState({status: 'exporting'});

    const params: Params = {
      ...this.mainList!.params,
      ...optParams,
    };

    if (this.state.checkedIds.size > 0) {
      params.id = [...this.state.checkedIds];
    }

    delete params[PARAM_KEY_SIZE];
    delete params[PARAM_KEY_PAGE];

    try {
      await api.postJson(this.ctx, `/app/${appId}/${path}/check`, params);
    } catch (e) {
      this.handleError(e);
      return;
    }

    downloadByPost(`/app/${appId}/${path}`, params);

    const intervalId = setInterval(() => {
      if (!isDownloading(appId)) {
        this.resetStatus();
        clearInterval(intervalId);
      }
    }, 100);
  }

  getButtonItemType(defaultValue: string) {
    if (!this.state.button || !this.state.button.itemType) {
      return defaultValue;
    }

    return this.state.button.itemType;
  }

  getButtonIconName(defaultValue: string) {
    if (!this.state.button || !this.state.button.iconName) {
      return defaultValue;
    }

    return this.state.button.iconName;
  }

  getButtonText(defaultValue: string) {
    if (!this.state.button || !this.state.button.name) {
      return defaultValue;
    }

    return this.state.button.name;
  }

  listActionMappings(): {[key: string]: any} {
    return {
      create: this.onCreate,
      search: this.onOpenSearch,
      sort: this.onSort,
      download: this.onDownload,
      link: this.onLink,
      export: this.onExport,
      import: this.onImport,
      request: this.onAppRequest,
      batch_edit: this.onBatchEdit,
      config: this.onConfig,
    };
  }

  buildItemButtons(buttons?: ToolButton[]) {
    if (!buttons) {
      return [];
    }

    const mappings: {[key: string]: any} = {
      create_ref: this.onCreateRef,
      edit: this.onEdit,
      delete: this.onDelete,
      copy: this.onCopy,
      show: this.onShow,
      download: this.onDownloadItem,
      request: this.onRequestItem,
      link: this.onLink,
    };

    buttons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    return buttons;
  }

  buildFilterButtons(buttons?: ToolButton[]) {
    if (!buttons) {
      return [];
    }

    const mappings: {[key: string]: any} = {
      //TODO buttons for filter
    };

    buttons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    return buttons;
  }

  buildActions(): Actions {
    //TODO
    return {
      show: (args) => {
        if (args.target.schemaId && args.target.resId) {
          return this.onSelectId(
            args.target.schemaId,
            args.target.resId,
            args.params,
          );
        }

        return this.onSelectItem(args.item);
      },
      reload: () => {
        if (this.state.focusedId && this.state.focused) {
          this.loadItem(this.state.focused.schema.id, this.state.focusedId);
        }
      },
    };
  }

  buildUIActions(): UIActions {
    return new UIActions({
      close: this.closeItem,
      load: this.loadItem,
    });
  }

  renderListHeader(
    buttons?: ToolButton[],
    farButtons?: ToolButton[],
    sorters?: Sorter[],
  ) {
    return (
      <ListHeader
        buttons={buttons}
        farButtons={farButtons}
        mappings={this.listActionMappings()}
        sorterId={this.state.sorterId!}
        sortOrderDesc={!!this.state.sortOrder?.startsWith('desc')}
        sorters={sorters}
      />
    );
  }

  renderItemHeader(buttons: ToolButton[], farButtons: ToolButton[]) {
    return (
      <ItemHeader>
        <Toolbar
          buttons={this.buildItemButtons(buttons)}
          farButtons={this.buildItemButtons(farButtons)}
        />
      </ItemHeader>
    );
  }

  renderSimpleListBody(simpleListProps: SimpleListProps) {
    return (
      <SimpleListBody
        simpleListProps={simpleListProps}
        ctx={this.ctx}
        resourceList={this.mainList}
        setResourceList={(resourceList: ResourceList) => {
          this.setState({
            resourceList,
          });
        }}
        defaultParams={this.state.defaultParams}
        filtered={this.state.filtered}
        onSelectRow={this.onSelectRow}
        focusedId={this.state.focusedId}
      />
    );
  }

  renderSimpleList(props: SimpleListProps) {
    if (!this.mainList) {
      return null;
    }

    return (
      <ListContainer>
        {this.renderListHeader(props.buttons, props.farButtons, props.sorters)}
        {this.renderSimpleListBody(props)}
      </ListContainer>
    );
  }

  renderGridListBody(props: GridListProps) {
    if (!props.columns || props.columns.length === 0) {
      return null;
    }

    const pagerProps = buildPagerProps(
      this.ctx,
      (resourceList: ResourceList) => {
        this.setState({
          resourceList,
        });
      },
      this.mainList,
      this.state.defaultParams,
    );

    return (
      <ListBody>
        <GridList
          {...props}
          resourceList={this.mainList!}
          onSelect={this.onSelectRow}
          selectedIds={[this.state.focusedId]}
          emptyMessage={getEmptyMessage(this.state.filtered)}
          sorters={getSorters(this.mainList!.schema)}
          actions={this.buildActions()}
          onCheck={this.onCheck}
          checkedIds={this.state.checkedIds}
          {...pagerProps}
        />
      </ListBody>
    );
  }

  renderGridList(props: GridListProps) {
    if (!this.mainList) {
      return null;
    }

    return (
      <ListContainer>
        {this.renderListHeader(props.buttons, props.farButtons, props.sorters)}
        {this.renderGridListBody(props)}
        {this.renderCheckedItems()}
      </ListContainer>
    );
  }

  renderRowCalendarList(props: RowCalendarListProps) {
    if (!this.mainList) {
      return null;
    }

    return (
      <ListContainer>
        {this.renderListHeader(props.buttons, props.farButtons, props.sorters)}
        {this.renderRowCalendarListBody(props)}
        {this.renderCheckedItems()}
      </ListContainer>
    );
  }

  renderRowCalendarListBody(props: RowCalendarListProps) {
    const params = this.mainList!.params;
    const start = params['_start_date'];
    const end = params['_end_date'];

    if (typeof start !== 'string' || typeof end !== 'string') {
      return null;
    }

    const s = newDate(start);
    const e = newDate(end);

    if (!s || !e) {
      return null;
    }

    const dateRange = DateRange.fromStartEnd(s, e);

    const pagerProps = buildPagerProps(
      this.ctx,
      (resourceList: ResourceList) => {
        this.setState({
          resourceList,
        });
      },
      this.mainList,
      this.state.defaultParams,
    );

    return (
      <ListBody>
        <RowCalendarList
          {...props}
          resourceList={this.mainList!}
          items={this.mainList!.list}
          events={this.mainList!.relatedResources}
          dateRange={dateRange}
          onChangeDateRange={this.onChangeDateRange}
          onSelect={this.onSelectRow}
          onClickEvent={this.onSelectId}
          actions={this.buildActions()}
          onCheck={this.onCheck}
          checkedIds={this.state.checkedIds}
          {...pagerProps}
        />
      </ListBody>
    );
  }

  renderCheckedItems() {
    return (
      <CheckedItems
        isOpen={!isManipulating(this.state.status)}
        checkedIds={this.state.checkedIds}
        checkedItems={this.state.checkedItems}
        schemaId={this.appId}
        actions={this.buildActions()}
        onClear={() => {
          this.setState({checkedIds: new Set()});
        }}
      />
    );
  }

  renderForm(
    res: Resource,
    onSave: (
      values: FormValues,
      files: FormFiles,
      relatedValues: any,
    ) => Promise<void>,
    title: string,
    iconName: string,
  ) {
    const props = selectComponent<ItemDetailsProps>(
      res.schema.screen.components,
      'item',
    );

    if (!props) {
      return null;
    }

    const sections = extractSections(res.schema);

    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader iconName={iconName} text={title} />
        </ItemHeader>
        <ItemBody>
          <ItemForm
            ctx={this.ctx}
            resource={res}
            sections={sections}
            labelPosition={props.labelPosition}
            fieldSeparator={props.fieldSeparator}
            onCancel={() => {
              this.setState({status: 'loaded', errors: {}});
            }}
            onSave={onSave}
            actions={this.buildActions()}
          />
        </ItemBody>
      </ItemContainer>
    );
  }

  renderItem() {
    const {focused, newResource, status} = this.state;

    if (status === 'init') {
      return null;
    }

    if (status === 'loading') {
      return <Spinner />;
    }

    if (status === 'editing') {
      return this.renderForm(
        focused!,
        this.onSaveEdit,
        '編集',
        'edit-outlined',
      );
    }

    if (status === 'creating' || status === 'creating_ref') {
      const itemType = this.getButtonItemType(this.getAppId());
      const iconName = this.getButtonIconName('add');
      const text = this.getButtonText('作成');
      const onSave =
        status === 'creating' ? this.onSaveCreate : this.onSaveCreateRef;

      return this.renderForm(
        newResource!,
        (values: FormValues, files: FormFiles, relatedValues: any) => {
          return onSave(itemType, values, files, relatedValues);
        },
        text,
        iconName,
      );
    }

    if (status === 'copying') {
      return this.renderForm(
        newResource!,
        (values: FormValues, files: FormFiles, relatedValues: any) => {
          return this.onSaveCreate(
            newResource!.schema.id,
            values,
            files,
            relatedValues,
          );
        },
        'コピー',
        'file_copy-outlined',
      );
    }

    if (focused) {
      const props = selectComponent<ItemDetailsProps>(
        focused.schema.screen.components,
        'item',
      );

      if (!props) {
        return null;
      }

      const sections = extractSections(focused.schema);

      return (
        <>
          <ItemContainer>
            {this.renderItemHeader(props.buttons, props.farButtons)}
            <ItemBody>
              <ItemDetails
                resource={focused}
                sections={sections}
                labelPosition={props.labelPosition}
                fieldSeparator={props.fieldSeparator}
                actions={this.buildActions()}
              />
            </ItemBody>
          </ItemContainer>
        </>
      );
    }

    return null;
  }

  renderDeleteDialog() {
    if (!this.state.focused) {
      return null;
    }

    return (
      <DeleteDialog
        ctx={this.ctx}
        shown={this.state.status === 'deleting'}
        appId={this.state.focused.schema.id}
        resId={this.state.focused.id}
        onAfter={this.onAfterDelete}
        onClose={this.resetStatus}
      />
    );
  }

  renderAppRequestDialog() {
    return (
      <AppRequestDialog
        ctx={this.ctx}
        shown={this.state.status === 'requesting_app'}
        appId={this.mainList?.schema.id}
        params={this.state.button?.params}
        onAfterRequest={() => {}}
        onClose={(resp) => {
          this.uiActions.doAction(resp);
          this.resetStatus();
        }}
        actions={this.buildActions()}
      />
    );
  }

  renderRequestDialog() {
    const target = this.state.focused;

    if (!target) {
      return null;
    }

    return (
      <RequestDialog
        ctx={this.ctx}
        shown={this.state.status === 'requesting_item'}
        target={{
          schemaId: target.details.schema_id,
          resId: target.details.id,
        }}
        params={this.state.button?.params}
        onAfterRequest={() => {}}
        onClose={(resp) => {
          this.uiActions.doAction(resp);
          this.resetStatus();
        }}
        actions={this.buildActions()}
      />
    );
  }

  renderImportDialog() {
    return (
      <ImportDialog
        ctx={this.ctx}
        shown={this.state.status === 'importing'}
        appId={this.state.button?.itemType || this.mainList?.schema.id}
        fileTypes={this.state.button?.fileTypes}
        onClose={this.resetStatus}
        onComplete={this.reloadList}
      />
    );
  }

  renderBatchEditDialog() {
    return (
      <Dialog
        ref={this.batchEditDialog}
        modal={true}
        title={'一括編集'}
        maxWidth="100%">
        {this.renderBatchEditDialogContent()}
      </Dialog>
    );
  }

  renderBatchEditDialogContent() {
    if (!this.mainList) {
      return <Spinner />;
    }

    const load = this.state.status === 'batch_editing';

    return (
      <DialogContents>
        <ErrorMsg messages={this.state.errors['_']} />
        <BatchEditForm
          ctx={this.ctx}
          appId={this.mainList!.schema.id}
          ids={this.state.checkedIds}
          actions={this.buildActions()}
          onAfterSave={this.reloadList}
          onCancel={() => {
            this.batchEditDialog.current!.closeDialog();
            this.resetStatus();
          }}
          load={load}
        />
      </DialogContents>
    );
  }

  renderItemFormDialog() {
    const itemType = this.getButtonItemType(this.getAppId());
    // const iconName = this.getButtonIconName('add');
    const text = this.getButtonText('作成');

    return (
      <ItemFormDialog
        ctx={this.ctx}
        shown={
          this.state.status === 'editing_dialog' ||
          this.state.status === 'creating_dialog'
        }
        actions={this.buildActions()}
        appId={itemType}
        isNew={this.state.status === 'creating_dialog'}
        resId={this.state.focused?.id}
        checkedIds={this.state.checkedIds}
        params={this.state.buttonParams}
        title={text}
        onAfter={this.reloadList}
        onClose={this.resetStatus}
      />
    );
  }

  renderExportDialog() {
    return (
      <ExportDialog
        shown={this.state.status === 'exporting'}
        messages={this.state.errors['_']}
        onClose={this.resetStatus}
      />
    );
  }

  renderMessageDialog() {
    return (
      <MessageDialog
        shown={this.state.status === 'messaging'}
        messages={this.state.errors['_']}
        onClose={this.resetStatus}
      />
    );
  }

  renderFilter(area: string, props: FilterProps) {
    const list = this.state.filterResourceList || this.mainList;

    if (!list) {
      return null;
    }

    return (
      <Filter
        ctx={this.ctx}
        resourceList={list}
        scopes={list.schema.scopes}
        filters={props.filters}
        fields={list.schema.fields}
        relatedSchemas={list.relatedSchemas}
        defaultParams={this.state.defaultParams}
        onSearch={this.onSearch}
        onReset={this.onResetSearch}
        actions={this.buildActions()}
        buttons={this.buildFilterButtons(props.buttons)}
        farButtons={this.buildFilterButtons(props.farButtons)}
        toggleArea={area}
        toggle={props.toggle}
        onClickFoldButton={this.onFold}
        customRows={this.state.customRows}
        customColumns={this.state.customColumns}
        customComponents={this.state.customComponents}
      />
    );
  }

  renderFilterInPanel() {
    return (
      <div>
        <Panel
          className={'filter-panel'}
          hasCloseButton={true}
          closeButtonAriaLabel="Close"
          styles={{
            content: {
              padding: 0,
            },
            commands: {
              marginTop: 0,
              height: MAIN_HEADER_HEIGHT,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderBottom: `1px solid ${borderColorLightest}`,
            },
            closeButton: {
              marginRight: 4,
            },
          }}
          isOpen={this.state.filterShownInPanel}
          onDismiss={this.onCloseSearch}>
          {this.renderFilterInPanelContents()}
        </Panel>
      </div>
    );
  }

  renderFilterInPanelContents() {
    const list = this.state.filterResourceList || this.mainList;

    if (!list) {
      return null;
    }

    return (
      <Filter
        ctx={this.ctx}
        resourceList={list}
        scopes={list.schema.scopes}
        fields={list.schema.fields}
        relatedSchemas={list.relatedSchemas}
        defaultParams={this.state.defaultParams}
        onSearch={this.onSearchAndClose}
        onReset={this.onResetSearchAndClose}
        actions={this.buildActions()}
      />
    );
  }

  renderDisplayUnfoldBar(area: string, props: DisplayUnfoldBarProps) {
    return (
      <DisplayUnfoldBar
        area={props.area}
        toggle={props.toggle}
        onClick={this.onUnfold}
        customRows={this.state.customRows}
        customColumns={this.state.customColumns}
        customComponents={this.state.customComponents}
      />
    );
  }

  renderComponents(components: {[key: string]: any}) {
    const result: {[key: string]: any} = {};

    Object.keys(components).forEach((areaKey) => {
      result[areaKey] = this.renderComponent(areaKey, components[areaKey]);
    });

    return result;
  }

  renderComponent(area: string, component: Component) {
    switch (component.type) {
      case 'filter':
        return this.renderFilter(area, component as FilterProps);
      case 'grid-list':
        return this.renderGridList(component as GridListProps);
      case 'simple-list':
        return this.renderSimpleList(component as SimpleListProps);
      case 'row-calendar-list':
        return this.renderRowCalendarList(component as RowCalendarListProps);
      case 'item':
        return this.renderItem();
      case 'display-unfold-bar':
        return this.renderDisplayUnfoldBar(
          area,
          component as DisplayUnfoldBarProps,
        );
      default:
        return null;
    }
  }

  renderFatalError() {
    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader
            iconName={'Error'}
            type={'error'}
            text={this.state.errors['_']?.join(' ') || 'Error'}
          />
        </ItemHeader>
        <ItemBody />
      </ItemContainer>
    );
  }

  render() {
    if (this.state.status === 'fatal') {
      return this.renderFatalError();
    }

    if (!this.mainList) {
      return <Spinner />;
    }

    const screen = this.getScreen();

    if (!screen) {
      return false;
    }

    const columns = mergeSizes(screen.columns, this.state.customColumns);
    const rows = mergeSizes(screen.rows, this.state.customRows);
    const components = mergeComponents(
      screen.components,
      this.state.customComponents,
    );

    return (
      <>
        <GridLayout
          areas={screen.areas}
          columns={columns}
          rows={rows}
          components={this.renderComponents(components)}
          styles={screen.styles}
        />
        {this.renderAppRequestDialog()}
        {this.renderImportDialog()}
        {this.renderBatchEditDialog()}
        {this.renderDeleteDialog()}
        {this.renderRequestDialog()}
        {this.renderItemFormDialog()}
        {this.renderExportDialog()}
        {this.renderMessageDialog()}
        {this.renderFilterInPanel()}
      </>
    );
  }
}

export function ListScreenContainer(): JSX.Element | null {
  const params = useParams<URLMatch>();
  const {setNav} = useContext(NavContext);
  const location = useLocation();

  return <ListScreen {...params} setNav={setNav} location={location} />;
}

function toMap(params: URLSearchParams): {[key: string]: string[]} {
  const m: {[key: string]: string[]} = {};

  for (let key of params.keys()) {
    m[key] = params.getAll(key);
  }

  return m;
}

function isManipulating(status: Status): boolean {
  return [
    'creating',
    'creating_ref',
    'creating_dialog',
    'editing',
    'editing_dialog',
    'batch_editing',
    'copying',
    'deleting',
    'requesting_app',
    'requesting_item',
    'importing',
    'exporting',
  ].includes(status);
}

function isDownloading(appId: string): boolean {
  return document.cookie
    .split(';')
    .some((item) => item === 'downloading=' + appId);
}

function buildUrl(
  onSelectRow: OnSelectRowProps,
  appId: string,
  resId: string,
  item: ResourceDetails,
): string {
  if (onSelectRow.urlFieldId) {
    return item[onSelectRow.urlFieldId];
  }

  if (onSelectRow.appId && onSelectRow.paramsFieldId) {
    const params = newURLSearchParams(item[onSelectRow.paramsFieldId]);
    return `/app/${onSelectRow.appId}?${params}`;
  }

  // eslint-disable-next-line no-template-curly-in-string
  return onSelectRow.url.replace('${id}', resId);
}

function toggleOrder(order?: string): string {
  if (!order) {
    return 'desc';
  }

  if (order.includes('desc')) {
    return order.replace('desc', 'asc');
  }

  if (order.includes('asc')) {
    return order.replace('asc', 'desc');
  }

  return 'desc';
}

function defaultOrder(sorter: Sorter): string {
  if (isValidOrder(sorter.defaultOrder)) {
    return sorter.defaultOrder;
  }

  return 'desc';
}

function isValidOrder(order: string): boolean {
  return !!order && (order.startsWith('desc') || order.startsWith('asc'));
}
