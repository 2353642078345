import {DefaultButton, IconButton} from '@fluentui/react';
import React, {CSSProperties, MouseEventHandler} from 'react';
import styled from 'styled-components';
import {materialIconClassName} from '../icons/materialIcons';

type Props = {
  iconName?: string;
  label?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  size?: number;
  primary?: boolean;
  disabled?: boolean;
  onClick: () => void;
  styles?: {
    container?: CSSProperties;
    button?: CSSProperties;
  };
};

export function ButtonWidget(props: Props): JSX.Element | null {
  const onClick: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onClick();
  };

  const style = makeStyle(
    props.size,
    props.width,
    props.minWidth,
    props.maxWidth,
    props.styles?.button,
  );

  if (!props.label && props.iconName) {
    return (
      <Container style={props.styles?.container}>
        <IconButton
          onClick={onClick}
          iconProps={{
            iconName: props.iconName,
            className: materialIconClassName(props.iconName),
          }}
          disabled={props.disabled}
          style={style}
          styles={{
            rootDisabled: {
              background: 'none',
            },
          }}
        />
      </Container>
    );
  }

  if (props.label && !props.iconName) {
    return (
      <Container style={props.styles?.container}>
        <DefaultButton
          onClick={onClick}
          text={props.label}
          style={style}
          primary={props.primary}
          disabled={props.disabled}
        />
      </Container>
    );
  }

  if (props.label && props.iconName) {
    return (
      <Container style={props.styles?.container}>
        <DefaultButton
          onClick={onClick}
          text={props.label}
          iconProps={{
            iconName: props.iconName,
            className: materialIconClassName(props.iconName),
          }}
          style={style}
          primary={props.primary}
          disabled={props.disabled}
        />
      </Container>
    );
  }

  return null;
}

function makeStyle(
  size?: number,
  width?: number,
  minWidth?: number,
  maxWidth?: number,
  optStyle?: CSSProperties,
): CSSProperties {
  const style: CSSProperties = {};

  if (size) {
    style.fontSize = size + 'px';
    style.height = size * 2 + 'px';
  }

  if (width) {
    style.width = width + 'px';
  }

  if (minWidth) {
    style.minWidth = minWidth + 'px';
  }

  if (maxWidth) {
    style.maxWidth = maxWidth + 'px';
  }

  return {...style, ...optStyle};
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & .material-icons {
    font-size: 20px;
  }
`;
