import React, {KeyboardEventHandler} from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {YearMonthWidget} from '../widgets/YearMonthWidget';
import {useOnChange} from './hooks/useOnChange';

type Props = FieldValueProps & {
  onKeyUp?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function YearMonthField(props: Props): JSX.Element | null {
  const onChange = useOnChange(props.onChange, props.field.id);
  return <YearMonthWidget {...props} onChange={onChange} />;
}
