import React, {useEffect, useState} from 'react';
import {api, ApiContext} from '../../api';
import {PARAM_KEY_SELECTOR, PARAM_KEY_SIZE} from '../../consts';
import {
  buildTargetItemPropsMap,
  TargetItemProps,
} from '../../fields/hooks/useItemProps';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceList} from '../../types/ResourceList';
import {
  CandidateColumn,
  ColumnContainer,
  FilterColumn,
  SchemaSelectorContainer,
} from './elements';
import {ItemCandidates} from './ItemCandidates';
import {ItemFilter} from './ItemFilter';
import {isMultiple} from './ItemSelector';
import {SchemaSelector} from './SchemaSelector';
import {selectSchemaIdAndParams} from './Step';

type Props = {
  ctx: ApiContext;
  targets: TargetItemProps[];
  selected: ResourceDetails[];
  onSelect: (items: ResourceDetails[]) => void;
  onSelectAll: (items: ResourceDetails[]) => void;
  disabledIds: string[];
};

function useSelectorState() {
  const [candidates, setCandidates] = useState<ResourceList>();
  const [filter, setFilter] = useState<ResourceList>();
  const [filtered, setFiltered] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [stepRoutes, setStepRoutes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  return {
    candidates,
    setCandidates,
    filter,
    setFilter,
    filtered,
    setFiltered,
    stepIndex,
    setStepIndex,
    stepRoutes,
    setStepRoutes,
    loading,
    setLoading,
  };
}

type State = ReturnType<typeof useSelectorState>;

export function FilterAndCandidates(props: Props): JSX.Element {
  const targetMap = buildTargetItemPropsMap(props.targets);
  const [target, setTarget] = useState<TargetItemProps>(props.targets[0]);
  const state = useSelectorState();

  useEffect(() => {
    resetList(props, target, state, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  return (
    <>
      {isMultiple(props.targets) ? (
        <SchemaSelectorContainer>
          <SchemaSelector
            value={target.itemSchema.id}
            schemas={props.targets.map((t) => t.itemSchema)}
            onSelect={(schemaId) => {
              setTarget(targetMap[schemaId]);
            }}
          />
        </SchemaSelectorContainer>
      ) : null}
      <FilterColumn>
        <ColumnContainer>
          <ItemFilter
            {...props}
            {...target}
            itemType={target.itemSchema.id}
            resourceList={state.filter}
            onUpdateList={state.setCandidates}
            actions={{}}
            onUpdateFiltered={state.setFiltered}
            onResetList={() => {
              resetList(props, target, state, state.stepIndex);
            }}
            defaultParams={{
              ...target.defaultParams,
              ...getStepParams(target, state),
            }}
            forceParams={commonSelectorParams}
          />
        </ColumnContainer>
      </FilterColumn>
      <CandidateColumn>
        <ColumnContainer>
          <ItemCandidates
            {...props}
            {...target}
            itemType={target.itemSchema.id}
            list={state.candidates}
            onSelect={props.onSelect}
            onSelectAll={() => {
              const items = merge(props.selected, state.candidates?.list);
              props.onSelectAll(items);
            }}
            onUpdateList={state.setCandidates}
            onUpdateFilter={state.setFilter}
            stepIndex={state.stepIndex}
            onUpdateStepIndex={state.setStepIndex}
            filtered={state.filtered}
            onUpdateFiltered={state.setFiltered}
            forceParams={commonSelectorParams}
            headerTitle={target.candidateTitle}
          />
        </ColumnContainer>
      </CandidateColumn>
    </>
  );
}

async function resetList(
  props: Props,
  target: TargetItemProps,
  state: State,
  stepIndex: number,
) {
  state.setLoading(true);

  const validStepIndex = stepIndex > 0 ? stepIndex : 0;

  const {schemaId, filterSchemaId, params} = selectSchemaIdAndParams(
    target.steps,
    validStepIndex,
    target.itemSchema.id,
    target.defaultParams,
  );

  const list = await api.list(props.ctx, schemaId, {
    ...getStepParams(target, state),
    ...params,
    ...commonSelectorParams,
  });

  const filter = await newFilter(props, list, filterSchemaId);

  state.setCandidates(list);
  state.setFilter(filter);
  state.setStepIndex(validStepIndex);
  state.setFiltered(false);
  state.setLoading(false);
}

async function newFilter(
  props: Props,
  list: ResourceList,
  filterSchemaId?: string,
): Promise<ResourceList> {
  if (filterSchemaId) {
    return await api.list(props.ctx, filterSchemaId, {});
  }

  return list;
}

function getStepParams(props: TargetItemProps, state: State) {
  const step = props.steps[state.stepIndex - 1];

  if (!step) {
    return {};
  }

  return {
    [step.ref_field_id]: state.stepRoutes[state.stepRoutes.length - 1],
  };
}

function merge(selected: ResourceDetails[], candidates?: ResourceDetails[]) {
  const merged = [...selected];

  const selectedIds = new Set(selected.map((x) => x.id));

  for (let x of candidates || []) {
    if (!selectedIds.has(x.id)) {
      merged.push(x);
    }
  }

  return merged;
}

const commonSelectorParams = {
  [PARAM_KEY_SIZE]: '100',
  [PARAM_KEY_SELECTOR]: '1',
};
