import React from 'react';
import {Redirect} from 'react-router-dom';
import {api} from '../api';

interface Props {
  fallback: string;
  children: JSX.Element;
}

function buildTo(fallback: string, path: string, search: string): string {
  if (path === '/' && search === '') {
    return fallback;
  }

  return fallback + '?to=' + path + search;
}

export function Protected(props: Props): JSX.Element | null {
  const currentUser = api.getCurrentUser();

  if (currentUser) {
    return props.children;
  }

  const to = buildTo(
    props.fallback,
    window.location.pathname,
    window.location.search,
  );

  return <Redirect to={to} />;
}
