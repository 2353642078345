import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {buildCreateRefParams} from '../common/actions/CreateRef';
import {DeleteDialog} from '../common/dialogs/DeleteDialog';
import {ItemFormDialog} from '../common/dialogs/ItemFormDialog';
import {RequestDialog} from '../common/dialogs/RequestDialog';
import {extractSections} from '../common/Schema';
import {Toolbar} from '../common/Toolbar';
import {useNav} from '../hooks/useNav';
import {Actions} from '../types/Action';
import {Resource} from '../types/Resource';
import {ToolButton} from '../types/ToolButton';
import {selectComponent} from './util';
import {Mode, OnChangeMode} from './Item';
import {ItemDetails, ItemDetailsProps} from './ItemDetails';

type Props = {
  ctx: ApiContext;
  appId: string;
  id: string;
  onChangeMode: OnChangeMode;
  mode: ItemDetailsMode;
  onAfter: () => void;
  onCancel: () => void;
};

const modes = ['show', 'delete', 'request', 'new_ref', 'download'] as const;
export type ItemDetailsMode = typeof modes[number];

export function isItemDetailsMode(mode: any): mode is ItemDetailsMode {
  return modes.includes(mode);
}

export function ItemDetailsContainer(props: Props): JSX.Element | null {
  const [button, setButton] = useState<ToolButton | undefined>();
  const [res, load] = useResource(props.ctx, props.appId, props.id);

  if (!res) {
    return null;
  }

  const actions = buildActions();

  const compProps = selectComponent<ItemDetailsProps>(
    res.schema.screen.components,
    'item',
  );

  if (!compProps) {
    return null;
  }

  const sections = extractSections(res.schema);

  return (
    <>
      <ItemHeader
        {...props}
        resource={res}
        buttons={compProps.buttons}
        setButton={setButton}
      />
      <ItemDetails
        resource={res}
        actions={actions}
        sections={sections}
        labelPosition={compProps.labelPosition}
      />
      <DeleteDialog
        ctx={props.ctx}
        shown={props.mode === 'delete'}
        appId={res.schema.id}
        resId={res.id}
        onAfter={props.onAfter}
        onClose={props.onCancel}
      />
      <RequestDialog
        ctx={props.ctx}
        shown={props.mode === 'request'}
        target={{
          schemaId: res.details.schema_id,
          resId: res.details.id,
        }}
        params={button?.params}
        onAfterRequest={() => {}}
        onClose={props.onCancel}
        actions={actions}
      />
      <ItemFormDialog
        ctx={props.ctx}
        shown={props.mode === 'new_ref'}
        appId={button?.itemType || res.schema.id}
        params={buildCreateRefParams(res, button)}
        checkedIds={new Set()}
        onAfter={() => {
          load();
          props.onChangeMode('show');
        }}
        onClose={() => {
          props.onChangeMode('show');
        }}
        actions={actions}
        isNew={true}
      />
    </>
  );
}

function useResource(
  ctx: ApiContext,
  appId: string,
  id: string,
): [Resource | null, () => void] {
  const {setNavIfEmpty} = useNav();
  const [res, setRes] = useState<Resource | null>(null);

  const load = useCallback(() => {
    (async () => {
      const res = await api.show(ctx, appId, id);
      setRes(res);
    })();
  }, [ctx, appId, id]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (res) {
      setNavIfEmpty(res.schema);
    }
  }, [res, setNavIfEmpty]);

  return [res, load];
}

function buildActions(): Actions {
  return {};
}

type SetButtonFn = (button: ToolButton) => void;

type ItemHeaderProps = {
  resource: Resource;
  buttons: ToolButton[];
  onChangeMode: OnChangeMode;
  setButton: SetButtonFn;
};

function ItemHeader({
  resource,
  buttons,
  onChangeMode,
  setButton,
}: ItemHeaderProps): JSX.Element | null {
  return (
    <ItemHeaderContainer>
      <Toolbar
        buttons={buildItemButtons(resource, onChangeMode, setButton, buttons)}
      />
    </ItemHeaderContainer>
  );
}

const ItemHeaderContainer = styled.div``;

function buildItemButtons(
  res: Resource,
  onChangeMode: OnChangeMode,
  setButton: SetButtonFn,
  buttons?: ToolButton[],
) {
  if (!buttons) {
    return [];
  }

  const fn = (mode: Mode) => (button: ToolButton) => {
    setButton(button);
    onChangeMode(mode);
  };

  const mappings: {[key: string]: any} = {
    create_ref: fn('new_ref'),
    edit: fn('edit'),
    delete: fn('delete'),
    copy: fn('copy'),
    show: () => {
      window.open(`/app/${res.schema.id}/${res.id}`);
    },
    download: fn('download'),
    request: fn('request'),
  };

  buttons.forEach((button) => {
    button.onClick = mappings[button.type];
  });

  return buttons;
}
