import React from 'react';
import {OnSearch} from '../field/FieldComponent';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Field} from '../types/Field';
import {
  ComboBoxInputWidget,
  ComboBoxOutputWidget,
} from '../widgets/ComboBoxWidget';
import {useOnChange} from './hooks/useOnChange';
import {useOnSearch} from './hooks/useOnSearch';

type Props = {
  value: any;
  field: Field;
  onChange: OnChangeFieldValue;
  onDetermine?: OnSearch;
  readOnly?: boolean;
};

export function ComboBoxField(props: Props): JSX.Element | null {
  const value = String(props.value ?? '');
  const onChange = useOnChange(props.onChange, props.field.id);
  const onSearch = useOnSearch(props.onDetermine, props.field.id);

  if (props.readOnly) {
    return <ComboBoxOutputWidget value={value} />;
  }

  return (
    <ComboBoxInputWidget
      value={value}
      onChange={onChange}
      onDetermine={onSearch}
      options={props.field.options}
    />
  );
}
