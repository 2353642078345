import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {ResponseDialogComponent} from '../../common/ResponseDialog';
import {Spinner} from '../../common/Spinner';
import {ResponseDialog} from '../../types/ResponseDialog';
import {ScanData} from './ScanData';

type Props = {
  data: ScanData;
  size?: number;
  maxWidth?: string;
};

export function MessageDialog(props: Props): JSX.Element | null {
  const actions = {
    reload: () => {
      window.location.reload();
    },
    cancel: () => {
      props.data.clear();
    },
  };

  return (
    <ResponseDialogComponent
      hidden={!shouldShow(props.data)}
      dialog={props.data.getResponseDialog()}
      modal={true}
      disabled={false}
      renderBody={() => (
        <Body
          requesting={props.data.isRequesting()}
          dialog={props.data.getResponseDialog()}
          size={props.size}
        />
      )}
      actions={actions}
      size={props.size}
      maxWidth={props.maxWidth}
    />
  );
}

function shouldShow(data: ScanData): boolean {
  return data.isRequesting() || !!data.getResponseDialog();
}

type BodyProps = {
  requesting: boolean;
  dialog: ResponseDialog | null;
  size?: number;
};

function Body(props: BodyProps): JSX.Element | null {
  if (props.requesting) {
    return <Spinner />;
  }

  if (props.dialog) {
    const style = makeStyle(props.size);
    return <Container style={style}>{props.dialog.message}</Container>;
  }

  return null;
}

function makeStyle(size?: number): CSSProperties {
  if (!size) {
    return {};
  }

  return {
    fontSize: size + 'px',
    paddingBottom: size + 'px',
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 3rem;
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 1rem;
  padding-right: 1rem;
  white-space: pre-wrap;
`;
