import {DefaultButton, PrimaryButton, ScrollablePane} from '@fluentui/react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dialog, DialogButtons, DialogFooter} from '../../common/Dialog';
import {borderColorLightest} from '../../styles';
import {useAntennaScannedData} from './AntennaScannedData';
import {
  ScannedValueSelector,
  ScannedValueSelectorProps,
} from './ScannedValueSelector';

type Props = ScannedValueSelectorProps & {
  hidden: boolean;
  onClose: () => void;
  value: string;
  onSelect: (code: string) => void;
  antennaScanSchemaId: string;
  antennaId: string;
  antennaExt?: string;
  antennaUnknownFieldId: string;
};

export function ScannedValueSelectorDialog(props: Props): JSX.Element | null {
  const ref = useRef<Dialog>(null);
  const intl = useIntl();
  const [selected, setSelected] = useState<string>(props.value);

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  const close = useCallback(() => {
    if (ref.current) {
      ref.current.closeDialog();
    }
  }, []);

  const data = useAntennaScannedData({
    schemaId: props.antennaScanSchemaId,
    antennaId: props.antennaId,
    antennaExt: props.antennaExt,
    unknownFieldId: props.antennaUnknownFieldId,
  });

  if (data === null) {
    return null;
  }

  return (
    <Dialog
      ref={ref}
      hidden={props.hidden}
      onClose={props.onClose}
      title={intl.formatMessage({
        id: 'Widget.AntennaScannedValueSelector.Title',
        defaultMessage: 'Select Scanned Value',
      })}
      maxWidth="100%"
      renderFooter={() => (
        <Footer
          hasValues={data?.unknownIds.length > 0}
          onSelect={() => {
            props.onSelect(selected);
            close();
          }}
          onCancel={() => {
            setSelected(props.value);
            close();
          }}
          onClear={() => {
            data?.clear();
          }}
        />
      )}>
      <div
        style={{position: 'relative', minHeight: '400px', minWidth: '500px'}}>
        <ScrollablePane
          styles={{contentContainer: {display: 'flex', borderTop: border}}}>
          <ScannedValueSelector
            {...props}
            selected={selected}
            onSelect={(code) => {
              setSelected(code);
            }}
            style={{
              flexGrow: 1,
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
            data={data}
          />
        </ScrollablePane>
      </div>
    </Dialog>
  );
}

const border = `1px dashed ${borderColorLightest}`;

type FooterProps = {
  hasValues: boolean;
  onSelect: () => void;
  onCancel: () => void;
  onClear: () => void;
};

function Footer(props: FooterProps): JSX.Element {
  const intl = useIntl();

  return (
    <DialogFooter
      style={{
        borderTop: border,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {props.hasValues ? (
        <DialogButtons>
          <DefaultButton
            text={intl.formatMessage({
              id: 'Widget.AntennaScannedValueSelector.SessionClear',
              defaultMessage: 'Session Clear',
            })}
            onClick={props.onClear}
          />
        </DialogButtons>
      ) : (
        <div />
      )}
      <DialogButtons>
        {props.hasValues ? (
          <PrimaryButton
            onClick={props.onSelect}
            text={intl.formatMessage({
              id: 'Action.Select',
              defaultMessage: 'Select',
            })}
          />
        ) : null}
        <DefaultButton
          onClick={props.onCancel}
          text={intl.formatMessage({
            id: 'Action.Cancel',
            defaultMessage: 'Cancel',
          })}
        />
      </DialogButtons>
    </DialogFooter>
  );
}
