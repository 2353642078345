export function setLocalStorageItem(key: string, value: string) {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage.setItem(key, value);
}

export function getLocalStorageItem(key: string): string | null {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  return localStorage.getItem(key);
}

export function removeLocalStorageItem(key: string) {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage.removeItem(key);
}
