import React from 'react';
import {api, ApiContext} from '../../api';
import {ResponseMessage} from '../../components/RequestForm';
import {PARAM_KEY_FORCE} from '../../consts';
import {Actions} from '../../types/Action';
import {Params} from '../../types/Params';
import {CommonRequestDialog} from './CommonRequestDialog';

export type RequestTarget = {
  schemaId: string;
  resId: string;
};

type Props = {
  ctx: ApiContext;
  shown: boolean;
  target?: RequestTarget;
  params?: Params;
  onAfterRequest: (resp: ResponseMessage) => void;
  onClose: (resp?: ResponseMessage) => void;
  actions: Actions;
};

export function RequestDialog({
  ctx,
  shown,
  target,
  params,
  onAfterRequest,
  onClose,
  actions,
}: Props): JSX.Element | null {
  if (!target) {
    return null;
  }

  return (
    <CommonRequestDialog
      shown={shown}
      onNewRequest={() => {
        return api.newRequest(ctx, target.schemaId, target.resId, params);
      }}
      onRequest={(data) => {
        return api.request(ctx, target.schemaId, target.resId, {
          ...params,
          ...data,
        });
      }}
      onForceRequest={(data) => {
        return api.request(ctx, target.schemaId, target.resId, {
          ...params,
          ...data,
          [PARAM_KEY_FORCE]: true,
        });
      }}
      onAfterRequest={onAfterRequest}
      onClose={onClose}
      actions={actions}
    />
  );
}
