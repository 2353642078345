import {ComboBox, IComboBoxOption} from '@fluentui/react';
import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Option} from '../types/Field';
import {TextOutputWidget} from './TextWidget';

type InputProps = {
  value: string;
  options?: Option[];
  onChange: (v?: string) => void;
  onDetermine?: (v?: string) => void;
};

export function ComboBoxInputWidget(props: InputProps): JSX.Element | null {
  const [tmpValue, setTmpValue] = useState<string>(props.value);
  const opts = useOptions(props.options, tmpValue);
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <Container>
      <ComboBox
        text={props.value}
        selectedKey={props.value}
        options={opts}
        autoComplete={'off'}
        allowFreeform={true}
        autofill={{
          preventValueSelection: true,
        }}
        onChange={(_, opt) => {
          const value = opt ? opt.text : tmpValue;
          props.onChange(value);

          if (props.onDetermine) {
            props.onDetermine(value);
          }
        }}
        onInputValueChange={(value: string) => {
          if (!editing) {
            setTmpValue(value);
          }
        }}
        onCompositionStart={() => {
          setEditing(true);
        }}
        onCompositionEnd={() => {
          setEditing(false);
        }}
      />
    </Container>
  );
}

function useOptions(
  options: Option[] | undefined,
  value: string,
): IComboBoxOption[] {
  const opts = useMemo<IComboBoxOption[]>(() => {
    return (options || []).map((option) => ({
      key: option.value,
      text: option.label,
    }));
  }, [options]);

  return filter(opts, value);
}

function filter(opts: IComboBoxOption[], value: string): IComboBoxOption[] {
  const v = value.toLowerCase();
  return opts.filter((opt) => opt.text.toLowerCase().includes(v));
}

type OutputProps = {
  value: string;
};

export function ComboBoxOutputWidget(props: OutputProps): JSX.Element | null {
  return <TextOutputWidget value={props.value} copy={false} />;
}

const Container = styled.div``;
