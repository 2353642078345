import {Params} from '../../types/Params';

export type Steps = Step[];

export type Step = {
  schema_id: string;
  ref_field_id: string;
  params?: Params;
  filter_schema_id?: string;
};

export function selectSchemaIdAndParams(
  steps: Steps,
  stepIndex: number,
  itemType: string,
  defaultParams: Params,
) {
  if (steps.length > stepIndex) {
    return {
      schemaId: steps[stepIndex].schema_id,
      params: steps[stepIndex].params,
      filterSchemaId: steps[stepIndex].filter_schema_id,
    };
  }

  return {
    schemaId: itemType,
    params: defaultParams,
  };
}
