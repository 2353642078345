import {Persona, PersonaSize} from '@fluentui/react';
import React from 'react';
import {ApiContext} from '../../api';
import {buildPagerProps} from '../../common/ResourceDetailsTable';
import {SimpleList, SimpleListProps} from '../../components/SimpleList';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {Params} from '../../types/Params';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceList} from '../../types/ResourceList';
import {ListItem, Schema} from '../../types/Schema';
import {getEmptyMessage, OnSelectRow, SetResourceList} from './common';
import {ListBody} from './elements';

type Props = {
  simpleListProps: SimpleListProps;
  ctx: ApiContext;
  resourceList?: ResourceList;
  setResourceList: SetResourceList;
  defaultParams?: Params;
  filtered?: boolean;
  onSelectRow: OnSelectRow;
  focusedId: string;
};

export function SimpleListBody(props: Props): JSX.Element | null {
  const pagerProps = buildPagerProps(
    props.ctx,
    (resourceList: ResourceList) => {
      props.setResourceList(resourceList);
    },
    props.resourceList,
    props.defaultParams,
  );

  return (
    <ListBody>
      <SimpleList
        hideHeader={true}
        tools={{
          counter: !props.simpleListProps.tree,
          pagination: true,
          numPaginationButtons: 5,
        }}
        resourceList={props.resourceList!}
        expandByDefault={props.filtered}
        onSelect={props.onSelectRow}
        selectedIds={[props.focusedId]}
        tree={props.simpleListProps.tree}
        onRenderRow={(item: ResourceDetails) =>
          onRenderRow(
            item,
            props.resourceList!.schema,
            props.simpleListProps.listItem,
          )
        }
        emptyMessage={getEmptyMessage(props.filtered)}
        {...pagerProps}
      />
    </ListBody>
  );
}

function onRenderRow(
  item: ResourceDetails,
  schema: Schema,
  listItem: ListItem,
) {
  const iconColor = item.icon_color || schema.iconColor;
  const props = iconColor ? {initialsColor: iconColor} : null;
  const name = item.__name__ || item.name;

  if (!schema.screen || !listItem) {
    return (
      <Persona
        title={name}
        text={name}
        size={PersonaSize.size24}
        onRenderInitials={() => null}
        {...props}
      />
    );
  }

  const {iconName, primaryText, secondaryText, tertiaryText} = listItem;
  const text = item[primaryText] || name;
  const subText = item[secondaryText] || item[tertiaryText];

  const sizeProps =
    !iconName && !secondaryText ? {size: PersonaSize.size24} : {};

  return (
    <Persona
      title={text}
      text={text}
      secondaryText={subText}
      tertiaryText={tertiaryText}
      onRenderInitials={() => (
        <div
          style={{
            fontSize: 24,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <MaterialIcon iconName={iconName} />
        </div>
      )}
      {...sizeProps}
      {...props}
    />
  );
}
