import React from 'react';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {FlagWidget} from '../widgets/FlagWidget';
import {useActionAndArgs} from './hooks/useActionAndArgs';

type Props = {
  value: any;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
};

export function FlagField(props: Props): JSX.Element | null {
  const {action, args} = useActionAndArgs(
    props.field,
    props.schema,
    props.item,
    props.actions,
    'on_press',
    'request_immediately',
  );

  const onChange = (value: boolean) => {
    action({
      ...args,
      data: {
        ...args.data,
        value,
      },
    });
  };

  return <FlagWidget value={toBoolean(props.value)} onChange={onChange} />;
}

function toBoolean(value: any): boolean {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    return value === 'true';
  }

  if (typeof value === 'number') {
    return value === 1;
  }

  return !!value;
}
