import {ActionPropsHolder} from './actionProps';
import {load, onLoad} from './load';
import {close, onClose} from './close';

export function closeOrLoad(
  holder: ActionPropsHolder,
  onClose?: onClose,
  onLoad?: onLoad,
) {
  if (onClose) {
    return close(holder, onClose);
  }

  return load(holder, onLoad);
}
