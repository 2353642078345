import styled from 'styled-components';
import {InfoElemProps, InfoProps} from '../../types/Info';
import {Container} from './TotalCounter';

type Props = {
  infoKey: string;
  info: InfoProps;
};

export function Info(props: Props): JSX.Element | null {
  const elem = props.info.elements[props.infoKey];

  if (!elem) {
    return null;
  }

  return <InfoElem {...elem} />;
}

export function InfoElem(props: InfoElemProps): JSX.Element | null {
  return (
    <Container style={props.styles?.container}>
      <Text style={props.styles?.value}>{props.text}</Text>
    </Container>
  );
}

const Text = styled.div``;
