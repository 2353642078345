import React, {useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {api} from '../api';
import {NavContext} from '../context';
import {ItemScreenComponent, ItemScreenURLMatch} from './ItemScreen';
import {useIntl} from 'react-intl';

class EditItemScreenComponent extends ItemScreenComponent {
  componentDidMount() {
    this.loadEdit();
  }

  loadEdit = async () => {
    this.setState({
      status: 'loading',
    });

    const res = await api.show(this.ctx, this.appId, this.state.resourceId);

    this.setNav(res);
    this.setState({
      newResource: res,
      status: 'editing',
    });
  };
}

export function EditItemScreen(): JSX.Element | null {
  const params = useParams<ItemScreenURLMatch>();
  const {setNav} = useContext(NavContext);
  const location = useLocation();
  const intl = useIntl();

  return (
    <EditItemScreenComponent
      {...params}
      location={location}
      setNav={setNav}
      intl={intl}
    />
  );
}
