import React, {KeyboardEventHandler} from 'react';
import {TextInputWidget, TextOutputWidget} from './TextWidget';

type Props = OutputProps & {
  onChange: (v?: string) => void;
  min?: number;
  max?: number;
  readOnly?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function NumberWidget(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <NumberOutputWidget {...props} />;
  }

  return <NumberInputWidget {...props} />;
}

type OutputProps = {
  value: string;
  copy: boolean;
  prefix?: string;
  suffix?: string;
};

function NumberOutputWidget(props: OutputProps): JSX.Element | null {
  return <TextOutputWidget {...props} digitSeparator={true} />;
}

function NumberInputWidget(props: Props): JSX.Element | null {
  const textProps = buildTextProps(props);
  return <TextInputWidget {...props} textProps={textProps} multiline={false} />;
}

function buildTextProps(props: Props) {
  return {
    type: 'number',
    min: props.min,
    max: props.max,
  };
}
