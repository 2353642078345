import {materialIconNames} from './materialIconNames';
import {materialIconStyles} from './materialIconStyles';

const materialIcons: {[key: string]: string} = {};

for (let style of materialIconStyles) {
  for (let name of materialIconNames) {
    materialIcons[name + style] = name;
  }
}

export {materialIcons};

export function materialIconClassName(
  iconName: string,
  optClassName?: string,
): string {
  const parts = iconName.split('-', 2);
  return className(parts[1] || '', optClassName);
}

function className(style: string, optClassName?: string): string {
  const names = [optClassName, 'material-icons'];

  if (style) {
    names.push('material-icons-' + style);
  }

  return names.filter(emptyString).join(' ');
}

function emptyString(x?: string): boolean {
  return !!x;
}
