import moment from 'moment';
import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {
  Calendar,
  HeaderProps,
  momentLocalizer,
  stringOrDate,
} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import {
  bodyColor,
  bodyColorDisabled,
  bodyColorPrimary,
  textColor,
} from '../styles';
import {ResourceDetails} from '../types/ResourceDetails';

moment.locale('ja', {
  months: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  longDateFormat: {
    LTS: 'HH:mm:ss',
    LT: 'HH:mm',
    L: 'Y/M/D',
    LL: 'Y/M/D',
    LLL: 'Y/M/D HH:mm',
    LLLL: 'Y/M/D (ddd) HH:mm',
  },
});

const localizer = momentLocalizer(moment) as any;
// Avoid the issue of multi days events not being displayed.
// The event from midnight to midnight is treated as an all-day event.
// Therefore, such events are not displayed.
// The startAndEndAreDateOnly function is used to make that determination.
// Override the function so that it is not determined to be an all-day event.
// ref. https://github.com/jquense/react-big-calendar/blob/af82bd75466c1b3ca699cb2427a342bbbbeef0a3/src/TimeGrid.js#L207
localizer.startAndEndAreDateOnly = () => false;

const messages = {
  allDay: '全日',
};

type Props = {
  week?: Date;
  height: string;
  value: {
    start?: Date;
    end?: Date;
  };
  events: ResourceDetails[];
  onSelectTimeRange: (start: Date, end: Date) => void;
  startAccessor: string;
  endAccessor: string;
};

// @ts-ignore
const BigCalendar = withDragAndDrop(Calendar);

export function TimeRangeWidget(props: Props): JSX.Element | null {
  const onSelectTimeRange = props.onSelectTimeRange;
  const [newEvent, setNewEvent] = useState<ResourceDetails | null>(
    buildNewEvent({
      start: props.value.start,
      end: props.value.end,
      startAccessor: props.startAccessor,
      endAccessor: props.endAccessor,
    }),
  );
  const now = useMemo(() => {
    if (props.value.start) {
      return props.value.start;
    }

    return new Date();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeTimeRange = useCallback(
    (start: Date | string, end: Date | string) => {
      if (start instanceof Date && end instanceof Date) {
        onSelectTimeRange(start, end);
        setNewEvent(
          buildNewEvent({
            start,
            end,
            startAccessor: props.startAccessor,
            endAccessor: props.endAccessor,
          }),
        );
      }
    },
    [onSelectTimeRange, props.startAccessor, props.endAccessor],
  );

  const onSelectSlot = useCallback(
    (slotInfo: {
      start: stringOrDate;
      end: stringOrDate;
      slots: Date[] | string[];
      action: 'select' | 'click' | 'doubleClick';
    }) => {
      onChangeTimeRange(slotInfo.start, slotInfo.end);
    },
    [onChangeTimeRange],
  );

  const style = {
    height: props.height,
    minWidth: '960px',
  };

  const events = newEvent ? [newEvent, ...props.events] : [...props.events];

  return (
    <Container style={style}>
      {/*@ts-ignore*/}
      <BigCalendar<ResourceDetails>
        selectable={true}
        date={props.week}
        onNavigate={() => {}}
        defaultView={'week'}
        views={['week']}
        localizer={localizer}
        messages={messages}
        events={events}
        startAccessor={(d) => new Date(d[props.startAccessor])}
        endAccessor={(d) => new Date(d[props.endAccessor])}
        titleAccessor={(d) => d.name}
        toolbar={false}
        showMultiDayTimes={true}
        onSelectSlot={onSelectSlot}
        components={{
          header: Header,
        }}
        scrollToTime={now}
        eventPropGetter={(event) => {
          return {
            style: event.__new__
              ? newStyle
              : isOverDay(event, props)
              ? overDayStyle
              : otherStyle,
          };
        }}
        resizable
        draggableAccessor={newMarker}
        resizableAccessor={newMarker}
        onEventResize={({start, end}) => {
          onChangeTimeRange(start, end);
        }}
        onEventDrop={({start, end}) => {
          onChangeTimeRange(start, end);
        }}
      />
    </Container>
  );
}

const newMarker = '__new__';

function buildNewEvent(props: {
  start?: Date;
  end?: Date;
  startAccessor: string;
  endAccessor: string;
}) {
  if (!props.start || !props.end) {
    return null;
  }

  return {
    id: '__new_resource_id__',
    name: 'New',
    [props.startAccessor]: props.start,
    [props.endAccessor]: props.end,
    [newMarker]: true,
  };
}

function isOverDay(event: ResourceDetails, props: Props): boolean {
  return (
    new Date(event[props.endAccessor]).getTime() -
      new Date(event[props.startAccessor]).getTime() >
    24 * 60 * 60 * 1000
  );
}

const newStyle = {
  backgroundColor: bodyColorPrimary,
  borderColor: bodyColorPrimary,
};

const otherStyle = {
  backgroundColor: bodyColorDisabled,
  borderColor: bodyColor,
  color: textColor,
};

const overDayStyle = {
  backgroundColor: 'rgba(0,0,0,0.1)',
  borderColor: bodyColor,
  color: textColor,
};

type SetTimeRange = (start: Date, end: Date) => void;
type UseTimeRange = [Date | undefined, Date | undefined, SetTimeRange];

export function useTimeRange(): UseTimeRange {
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();

  const setTimeRange = useCallback((start: Date, end: Date) => {
    setStart(start);
    setEnd(end);
  }, []);

  return [start, end, setTimeRange];
}

function Header(props: HeaderProps): JSX.Element | null {
  return (
    <WeekHeader>{props.localizer.format(props.date, 'D (ddd)', '')}</WeekHeader>
  );
}

const Container = styled.div`
  & .rbc-time-header-content .rbc-allday-cell {
    display: none;
  }

  & .rbc-time-header-content .rbc-header {
    border-bottom: none;
  }

  & .rbc-event-content {
    margin-top: 0.5rem;
  }

  & .rbc-event-label {
    white-space: pre-wrap;
  }
`;

const WeekHeader = styled.div`
  font-weight: normal;
  padding: 0.5rem;
`;
