import {DefaultButton, Dropdown} from '@fluentui/react';
import * as React from 'react';
import {ChangeEvent, RefObject} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {FilterFields} from '../common/FilterFields';
import {CheckboxField} from '../fields/CheckboxField';
import {ItemField} from '../fields/ItemField';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Schema} from '../types/Schema';
import {Table} from '../types/Table';
import {TableColumn} from '../types/TableColumn';
import {array} from '../util';
import {DateWidget} from './DateWidget';
import {RadioWidget} from './RadioWidget';

type Props = {
  ctx: ApiContext;
  itemType: string;
  item: ResourceDetails;
  table: Table;
  onChange: (values: any) => void;
  readOnly: boolean;
};

type State = {
  resourceList?: ResourceList;
  list: ResourceDetails[];
  schema: Schema | null;
  valueItems: {key: string; details: {[key: string]: any}}[];
};

type Item = {
  key: string;
  details: ResourceDetails;
};

export class TableWidget extends React.Component<Props, State> {
  private readonly filterFields: RefObject<FilterFields>;
  private readonly ctx: ApiContext;

  constructor(props: Props) {
    super(props);
    this.filterFields = React.createRef();

    this.state = {
      list: [],
      schema: null,
      valueItems: [],
    };

    this.ctx = api.newContext();
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.ctx.abort();
  }

  load = async () => {
    const fid = this.props.table.keyFieldId || 'id';
    const key = this.props.item[fid];

    const params = {
      [this.props.table.refFieldId]: key,
    };

    if (this.props.table.view) {
      params._view = this.props.table.view;
    }

    const resourceList = await api.listAll(
      this.ctx,
      this.props.itemType,
      params,
    );

    const items = this.buildValueItems(resourceList.schema, resourceList.list);

    this.setState({
      resourceList: resourceList,
      valueItems: items,
    });
  };

  onChange = () => {
    if (this.props.onChange) {
      const values = this.state.valueItems.map((item) => item.details);
      this.props.onChange(values);
    }
  };

  onReset = () => {
    const filterParams = this.filterFields.current!.getValues();
    const params = Object.assign({}, filterParams, {
      _view: this.props.table.view,
    });

    api.listAll(this.ctx, this.props.itemType, params).then((r) => {
      this.setState({
        list: r.list,
        resourceList: r,
      });
    });
  };

  onSelectOption(
    ev: ChangeEvent<HTMLSelectElement>,
    itemKey: string,
    fieldId: string,
  ) {
    const selected = Array.from(ev.target.options).find((o) => o.selected);

    if (!selected) {
      return;
    }

    this.updateValue(itemKey, fieldId, selected.value);
  }

  getValue(itemKey: string, fieldId: string) {
    const item = this.state.valueItems.find((item) => item.key === itemKey);

    if (!item) {
      return null;
    }

    return item.details[fieldId];
  }

  updateValue(itemKey: string, fieldId: string, value: any) {
    const index = this.state.valueItems.findIndex((i) => i.key === itemKey);

    if (index < 0) {
      console.warn('item not found');
      return;
    }

    const items = this.state.valueItems;
    const oldItem = this.state.valueItems[index];
    const newItem = Object.assign({}, oldItem, {
      key: itemKey,
      details: Object.assign({}, oldItem.details, {[fieldId]: value}),
    });

    items.splice(index, 1, newItem);
    this.setState({valueItems: items}, () => {
      this.onChange();
    });
  }

  buildKey(item: ResourceDetails, index: number) {
    return 'key-' + index;
    //    return this.props.table.keyFields.map(fid => item[fid]).join("--");
  }

  buildValueItems(schema: Schema, list: ResourceDetails[]) {
    return list.map((item, index) => {
      const refIds = Object.keys(item).filter(
        (fid) => !!(item[fid] && item[fid].id),
      );

      const idValues: {[key: string]: string} = {};

      for (let fid of refIds) {
        idValues[fid] = item[fid].id;
      }

      return {
        key: this.buildKey(item, index),
        details: Object.assign({}, item, idValues),
      };
    });
  }

  buildViewItems(schema: Schema, list: ResourceDetails[]) {
    const options: {[key: string]: {[key: string]: string}} = {};

    for (let field of schema.fields) {
      if (field.options) {
        const opts: {[key: string]: string} = {};

        for (let o of field.options) {
          opts[o.value] = o.label;
        }

        options[field.id] = opts;
      }
    }

    return list.map((item, index) => {
      const details: ResourceDetails = {id: item.id};

      for (let fid of Object.keys(item)) {
        const value = item[fid];

        if (value && value.name) {
          details[fid] = value.name;
          continue;
        }

        if (options[fid]) {
          details[fid] = options[fid][value];
          continue;
        }

        details[fid] = value;
      }

      return {
        key: this.buildKey(item, index),
        details: details,
      };
    });
  }

  buildActions(): Actions {
    return {};
  }

  renderFilters() {
    if (!this.state.resourceList!.schema.views) {
      return null;
    }

    const view = this.state.resourceList!.schema.views.find(
      (v) => v.id === this.props.table.view,
    );

    if (!view) {
      return null;
    }

    const viewSchema = this.state.resourceList!.schema;
    const fields = view.filters
      .map((filter) => viewSchema.fields.find((f) => f.id === filter.fieldId))
      .filter((f) => !!f) as Field[];

    return (
      <Filters>
        <FilterFields
          ctx={this.props.ctx}
          fields={fields}
          relatedSchemas={this.state.resourceList!.relatedSchemas}
          ref={this.filterFields}
          actions={this.buildActions()}
        />
        <DefaultButton
          style={{alignSelf: 'center'}}
          text={'更新'}
          onClick={this.onReset}
        />
      </Filters>
    );
  }

  renderTotal() {
    return (
      <Wrapper>件数: {this.state.resourceList!.list.length.toFixed()}</Wrapper>
    );
  }

  renderHeader() {
    return (
      <thead>
        <Header>
          {this.props.table.columns.map((col: TableColumn, index: number) => {
            const key = 'header-' + index;
            const field = this.state.resourceList!.schema.fields.find(
              (field) => field.id === col.fieldId,
            );

            if (!field) {
              return <HeaderCell key={key} strictWidth={col.width} />;
            }

            return (
              <HeaderCell key={key} strictWidth={col.width}>
                {field.label}
              </HeaderCell>
            );
          })}
        </Header>
      </thead>
    );
  }

  renderBody() {
    const items = this.buildViewItems(
      this.state.resourceList!.schema,
      this.state.resourceList!.list,
    );

    return (
      <tbody>
        {items.map((item, index) => (
          <Row key={`item-${index}`}>{this.renderRow(item)}</Row>
        ))}
      </tbody>
    );
  }

  renderReadonlyValue(
    details: ResourceDetails,
    col: TableColumn,
    field: Field,
    key: string,
  ) {
    // readonly
    const value = details[col.fieldId];

    if (field.widget === 'checkbox') {
      return (
        <Cell key={key}>
          <CheckboxField field={field} value={array(value)} readOnly={true} />
        </Cell>
      );
    }

    if (!value) {
      return <Cell key={key} />;
    }

    if (
      field.type === 'resid' &&
      this.state.resourceList!.relatedResources[value]
    ) {
      return (
        <Cell key={key}>
          {this.state.resourceList!.relatedResources[value].name}
        </Cell>
      );
    }

    if (field.type === 'date') {
      return (
        <Cell key={key}>
          <DateWidget value={value} readOnly={true} />
        </Cell>
      );
    }

    return <Cell key={key}>{value}</Cell>;
  }

  renderRow(item: Item) {
    const details = item.details;
    const itemKey = item.key;

    return (
      <>
        {this.props.table.columns.map((col: TableColumn, index: number) => {
          const key = `cell-${index}`;

          const field = this.state.resourceList!.schema.fields.find(
            (field) => field.id === col.fieldId,
          );

          if (!field) {
            return <Cell key={key} />;
          }

          if (!col.editable || !field.editable) {
            return this.renderReadonlyValue(details, col, field, key);
          }

          return this.renderEditableValue(details, col, field, key, itemKey);
        })}
      </>
    );
  }

  renderEditableValue(
    details: ResourceDetails,
    col: TableColumn,
    field: Field,
    key: string,
    itemKey: string,
  ) {
    if (field.widget === 'checkbox') {
      return (
        <Cell key={key}>
          <CheckboxField
            field={field}
            onChange={(v: any) => {
              this.updateValue(itemKey, field.id, v);
            }}
            value={array(this.getValue(itemKey, field.id))}
          />
        </Cell>
      );
    }

    if (field.widget === 'radio') {
      return (
        <Cell key={key}>
          <RadioWidget
            options={field.options}
            onChange={(v: any) => {
              this.updateValue(itemKey, field.id, v);
            }}
            value={this.getValue(itemKey, field.id)}
          />
        </Cell>
      );
    }

    if (field.widget === 'select') {
      const v = this.getValue(itemKey, field.id) || field.defaultValue;

      return (
        <Cell key={key}>
          <Dropdown
            options={field.options!.map((opt) => {
              return {
                key: opt.value,
                text: opt.label,
                selected: opt.value === v,
              };
            })}
            onChange={(e, opt) => {
              if (opt) {
                this.updateValue(itemKey, field.id, opt.key);
              }
            }}
          />
        </Cell>
      );
    }

    if (
      field.type === 'resid' &&
      field.itemType &&
      this.state.resourceList!.relatedSchemas[field.itemType]
    ) {
      const ids: string[] = this.getValue(itemKey, field.id) || [];

      return (
        <Cell key={key}>
          <ItemField
            value={ids}
            onChange={(vs) => {
              this.updateValue(itemKey, field.id, vs[field.id]);
            }}
            field={field}
            actions={this.buildActions()}
            capacity={1}
          />
        </Cell>
      );
    }

    const value = details[col.fieldId];

    return (
      <Cell key={key}>
        <input type="text" defaultValue={value} />
      </Cell>
    );
  }

  renderTable() {
    return (
      <FormTable>
        {this.renderHeader()}
        {this.renderBody()}
      </FormTable>
    );
  }

  render() {
    if (!this.state.resourceList) {
      return null;
    }

    if (!this.props.table) {
      return null;
    }

    //TODO this.props.readOnly

    return (
      <div>
        {this.renderFilters()}
        {this.renderTotal()}
        {this.renderTable()}
      </div>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const Filters = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px dashed #999;
`;

const FormTable = styled.table`
  border-collapse: collapse;
`;

const Cell = styled.td`
  border: 1px solid #999;
  padding: 0.4em;
`;

type HeaderCellProps = {
  strictWidth?: number;
};

const HeaderCell = styled.th.attrs<HeaderCellProps>((props) => {
  return {
    style: {
      width: `${props.strictWidth || 100}px`,
    },
  };
})<HeaderCellProps>`
  border: 1px solid #999;
  padding: 0.4em;
  text-align: center;
  font-size: 0.8rem;
  font-weight: normal;
`;

const Header = styled.tr`
  background-color: #eee;
`;

const Row = styled.tr``;
