import {MFA} from '../../types/mfa';
import styled from 'styled-components';
import React, {useState} from 'react';
import {Setting} from './Setting';
import {Dialog} from '../../common/Dialog';
import {Form} from './Form';
import {DeleteForm} from './DeleteForm';

type Props = {
  mfa: MFA;
  onUpdate: () => void;
};

export function Settings(props: Props): JSX.Element | null {
  const [mfaType, setMFAType] = useState<string>();
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [delVisible, setDelVisible] = useState<boolean>(false);

  return (
    <Container>
      {props.mfa.settings.map((setting, i) => {
        const onAdd = () => {
          setMFAType(setting.type);
          setAddVisible(true);
        };

        const onDelete = () => {
          setMFAType(setting.type);
          setDelVisible(true);
        };

        return (
          <Setting
            key={`setting-${i}`}
            setting={setting}
            onAdd={onAdd}
            onDelete={onDelete}
          />
        );
      })}
      <Dialog
        hidden={!addVisible}
        onClose={() => setAddVisible(false)}
        maxWidth={'100%'}>
        <Form
          mfaType={mfaType}
          onCancel={() => setAddVisible(false)}
          onDone={props.onUpdate}
        />
      </Dialog>
      <Dialog
        hidden={!delVisible}
        onClose={() => setDelVisible(false)}
        maxWidth={'100%'}>
        <DeleteForm
          mfaType={mfaType}
          onCancel={() => setDelVisible(false)}
          onDone={props.onUpdate}
        />
      </Dialog>
    </Container>
  );
}

const Container = styled.div``;
