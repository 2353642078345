import {
  INavLink,
  INavLinkGroup,
  Nav,
  Panel,
  PanelType,
  ScrollablePane,
} from '@fluentui/react';
import React, {useState} from 'react';
import styled from 'styled-components';
import {MAIN_HEADER_HEIGHT, MENU_WIDTH} from '../../consts';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {
  bodyColorHovered,
  borderColorLight,
  borderColorLightest,
} from '../../styles';

type MenuProps = {
  menuItems: INavLink[];
  path: string;
};

export function MobileMenu(props: MenuProps): JSX.Element | null {
  const [expanded, setExpanded] = useState(false);
  const menuText = '';
  const menu: INavLinkGroup[] = [
    {
      links: props.menuItems,
    },
  ];

  const open = () => {
    setExpanded(true);
  };

  const close = () => {
    setExpanded(false);
  };

  return (
    <div>
      <MenuButton onClick={open} label={menuText} expanded={expanded} />
      <Panel
        className={'nav-panel'}
        hasCloseButton={true}
        closeButtonAriaLabel="Close"
        styles={{
          content: {
            padding: 0,
          },
          commands: {
            paddingTop: 0,
            height: MAIN_HEADER_HEIGHT,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderBottom: `1px solid ${borderColorLightest}`,
          },
          closeButton: {
            marginRight: 4,
          },
        }}
        type={PanelType.customNear}
        customWidth={MENU_WIDTH + 'px'}
        isOpen={expanded}
        isLightDismiss={true}
        onDismiss={close}>
        <NavContainerWrapper>
          <NavContainer>
            <Nav
              groups={menu}
              selectedKey={props.path}
              expandButtonAriaLabel={'Expand or collapse'}
            />
          </NavContainer>
        </NavContainerWrapper>
      </Panel>
    </div>
  );
}

type MenuButtonProps = {
  onClick: () => void;
  label: string;
  expanded: boolean;
};

function MenuButton(props: MenuButtonProps): JSX.Element | null {
  return (
    <MenuContainer onClick={props.onClick}>
      <MaterialIcon iconName={'menu'} />
      <MenuText>{props.label}</MenuText>
    </MenuContainer>
  );
}

const NavContainerWrapper = styled.div`
  height: 100vh;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  height: ${MAIN_HEADER_HEIGHT}px;
  border-bottom: 1px solid transparent;

  &:hover {
    background-color: ${bodyColorHovered};
    border-bottom-color ${borderColorLight};
  }
`;

const MenuText = styled.div`
  margin-left: 0.5rem;
`;

const NavContainer = styled(ScrollablePane)`
  margin-top ${MAIN_HEADER_HEIGHT}px;
  width: 100%;

  & .ms-Nav-groupContent {
    margin-bottom: 0;
  }

  & .ms-Icon {
    margin-left: 20px;
    width: 16px;
  }
`;
