import React from 'react';
import {Field} from '../types/Field';
import {Styles} from '../types/Styles';
import {widgetProps} from '../widgets/common';
import {MarkdownWidget} from '../widgets/MarkdownWidget';

type Props = {
  value: unknown;
  field: Field;
};

export function MarkdownField(props: Props): JSX.Element | null {
  const styles = widgetProps<Styles>(props.field, 'styles', {});
  return <MarkdownWidget value={String(props.value ?? '')} styles={styles} />;
}
