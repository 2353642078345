import * as React from 'react';
import {LegacyRef} from 'react';
import styled from 'styled-components';
import {DialogButton, ResponseDialog} from '../types/ResponseDialog';
import {ButtonWidget} from '../widgets/ButtonWidget';
import {Dialog, DialogButtons, DialogFooter} from './Dialog';

type Actions = {[key: string]: () => void};

type Props = {
  hidden?: boolean;
  dialog?: ResponseDialog | null;
  componentRef?: LegacyRef<Dialog>;
  modal: boolean;
  defaultTitle?: string;
  disabled: boolean;
  renderBody?: () => JSX.Element | null;
  actions: Actions;
  size?: number;
  maxWidth?: string;
};

export function ResponseDialogComponent(props: Props): JSX.Element | null {
  return (
    <Dialog
      hidden={props.hidden}
      ref={props.componentRef}
      modal={props.modal}
      title={props.dialog?.title || props.defaultTitle}
      disabled={props.disabled}
      renderBody={() => {
        if (props.renderBody) {
          return props.renderBody();
        }

        return <MessageContainer>{props.dialog?.message}</MessageContainer>;
      }}
      renderFooter={() => {
        return (
          <ResponseDialogButtons
            buttons={props.dialog?.buttons}
            disabled={props.disabled}
            actions={props.actions}
            size={props.size}
          />
        );
      }}
      maxWidth={props.maxWidth}
    />
  );
}

type DialogButtonsProps = {
  buttons?: DialogButton[];
  disabled: boolean;
  actions: Actions;
  size?: number;
};

function ResponseDialogButtons(props: DialogButtonsProps): JSX.Element | null {
  if (!props.buttons) {
    return null;
  }

  return (
    <DialogFooter>
      <DialogButtons>
        {props.buttons.map((b, i) => (
          <DialogButtonComponent key={`button-${i}`} button={b} {...props} />
        ))}
      </DialogButtons>
    </DialogFooter>
  );
}

function doNothing(): void {}

type DialogButtonProps = {
  button: DialogButton;
  disabled: boolean;
  actions: Actions;
  size?: number;
};

function DialogButtonComponent({
  button,
  disabled,
  actions,
  size,
}: DialogButtonProps): JSX.Element | null {
  const action = actions[button.action] || doNothing;

  switch (button.type) {
    case 'primary':
      return (
        <ButtonWidget
          onClick={action}
          label={button.label || '実行'}
          disabled={disabled}
          primary={true}
          size={size}
        />
      );
    default:
      return (
        <ButtonWidget
          onClick={action}
          label={button.label || 'キャンセル'}
          disabled={disabled}
          size={size}
        />
      );
  }
}

const MessageContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  white-space: pre-wrap;
`;
