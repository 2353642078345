import React from 'react';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Field} from '../types/Field';
import {BarcodeWidget} from '../widgets/BarcodeWidget';
import {selectStringValue} from '../widgets/common';
import {useOnChange} from './hooks/useOnChange';

type Props = {
  value: any;
  onChange: OnChangeFieldValue;
  field: Field;
  readOnly?: boolean;
};

export function BarcodeField(props: Props): JSX.Element | null {
  const value = selectStringValue(props.value);
  const onChange = useOnChange(props.onChange, props.field.id);
  return <BarcodeWidget {...props} value={value} onChange={onChange} />;
}
