import {Persona, PersonaSize} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {api} from '../api';

export function CurrentUser(): JSX.Element {
  const user = api.getCurrentUser();
  return (
    <CurrentUserWithFetch
      id={user.id}
      name={user.name}
      iconColor={user.icon_color}
    />
  );
}

type Props = {
  id: string;
  name: string;
  iconColor: string;
};

function CurrentUserWithFetch(props: Props): JSX.Element {
  const [iconColor, setIconColor] = useState<string>(props.iconColor);

  useEffect(() => {
    const ctx = api.newContext();

    (async () => {
      const user = await api.show(ctx, 'user', props.id);
      setIconColor(user.details.icon_color || user.schema.iconColor);
    })();

    return () => {
      ctx.abort();
    };
  }, [props.id]);

  const personaProps = iconColor ? {initialsColor: iconColor} : null;

  return (
    <Persona text={props.name} size={PersonaSize.size24} {...personaProps} />
  );
}
