import {useCallback} from 'react';
import {OnSearch} from '../../field/FieldComponent';

export function useOnSearch(
  onSearch: OnSearch | undefined,
  fieldId: string,
): (v?: string | string[]) => void {
  return useCallback(
    (v?: string | string[]) => {
      if (onSearch) {
        onSearch({[fieldId]: v || null});
      }
    },
    [onSearch, fieldId],
  );
}
