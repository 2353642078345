import React from 'react';
import {ResourceDetails} from '../../types/ResourceDetails';
import {isChecked} from './Item';
import {Items} from './Items';

type Props = {
  items: ResourceDetails[];
  checkedId: string;
  onChecked: (id: string) => void;
  size: number;
  nameFieldId?: string;
};

export function SingleItemSelector(props: Props): JSX.Element | null {
  const isChecked: isChecked = (id: string): boolean => {
    return props.checkedId === id;
  };

  return (
    <Items
      {...props}
      isChecked={isChecked}
      onChecked={(id: string, checked: boolean) => {
        if (checked) {
          props.onChecked(id);
        } else {
          props.onChecked('');
        }
      }}
    />
  );
}
