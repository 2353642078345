import React from 'react';
import {FilterFieldValueProps} from '../field/FilterFieldValue';
import {widgetProps} from '../widgets/common';
import {MonthWidget} from '../widgets/MonthWidget';
import {useOnChange} from './hooks/useOnChange';

type Props = FilterFieldValueProps;

export const MonthFilterField = (props: Props) => {
  const field = props.field;

  if (field.searchOperator === 'in') {
    return (
      <MonthWidget
        value={props.onGetValue(field.id)}
        onChange={(v: string) => {
          props.onChange({[field.id]: v});
        }}
        readOnly={!field.searchable}
      />
    );
  }

  return <MonthRangeFilterField {...props} />;
};

const MonthRangeFilterField = (props: Props) => {
  const field = props.field;

  const pf = widgetProps(field, 'search_placeholder_from', '〜から');
  const pt = widgetProps(field, 'search_placeholder_to', '〜まで');

  const fromId = '_from_date_' + field.id;
  const toId = '_to_date_' + field.id;

  const onChangeFrom = useOnChange(props.onChange, fromId);
  const onChangeTo = useOnChange(props.onChange, toId);

  return (
    <>
      <MonthWidget
        value={props.onGetValue(fromId)}
        onChange={onChangeFrom}
        readOnly={!field.searchable}
        placeholder={pf}
      />
      <MonthWidget
        value={props.onGetValue(toId)}
        onChange={onChangeTo}
        readOnly={!field.searchable}
        placeholder={pt}
        style={{marginTop: '0.5rem'}}
      />
    </>
  );
};
