import React, {RefObject} from 'react';
import {AreaRow, GridProps, Size} from './GridLayout';
import {GridArea, GridAreaContainer} from './GridArea';
import styled from 'styled-components';

type Props = GridProps;

type State = {
  cells: AreaRow[];
};

export type RowRegion = {
  top: number;
  bottom: number;
  height: number;
};

export type ColumnRegion = {
  left: number;
  right: number;
  width: number;
};

const ShadowContainer = styled(GridAreaContainer)`
  position: absolute;
  top: 0;
  visibility: hidden;
  z-index: -1;
`;

export class GridSizeDetector extends React.Component<Props, State> {
  private readonly rows: RefObject<HTMLDivElement>[];
  private readonly columns: RefObject<HTMLDivElement>[];

  constructor(props: Props) {
    super(props);
    const cells = this.buildCells(this.props.rows, this.props.columns);

    const ref = React.createRef<HTMLDivElement>();

    this.rows = [ref];

    for (let i = 1; i < this.props.rows.length; i++) {
      this.rows.push(React.createRef<HTMLDivElement>());
    }

    this.columns = [ref];

    for (let i = 1; i < this.props.columns.length; i++) {
      this.columns.push(React.createRef<HTMLDivElement>());
    }

    this.state = {
      cells,
    };
  }

  getRowTopBottom(rowIndex: number): RowRegion {
    if (!this.rows[rowIndex] || !this.rows[rowIndex].current) {
      return {
        top: 0,
        bottom: 0,
        height: 0,
      };
    }

    return this.rows[rowIndex].current!.getBoundingClientRect();
  }

  getColumnLeftRight(columnIndex: number): ColumnRegion {
    if (!this.columns[columnIndex] || !this.columns[columnIndex].current) {
      return {
        left: 0,
        right: 0,
        width: 0,
      };
    }

    return this.columns[columnIndex].current!.getBoundingClientRect();
  }

  buildCells(rows: Size[], columns: Size[]) {
    const cells: AreaRow[] = [];
    const r: AreaRow = [];

    for (let j = 0; j < columns.length; j++) {
      r.push(this.cellKey(0, j));
    }

    cells.push(r);

    for (let i = 1; i < rows.length; i++) {
      const r: AreaRow = [];
      r.push(this.cellKey(i, 0));

      for (let j = 1; j < columns.length; j++) {
        r.push(`rest`);
      }

      cells.push(r);
    }

    return cells;
  }

  rowSizes(): number[] {
    const result: number[] = [];

    for (let i = 0; i < this.rows.length; i++) {
      const h = this.rows[i].current!.getBoundingClientRect().height;
      result.push(h);
    }

    return result;
  }

  columnSizes(): number[] {
    const result: number[] = [];

    for (let i = 0; i < this.columns.length; i++) {
      const w = this.columns[i].current!.getBoundingClientRect().width;
      result.push(w);
    }

    return result;
  }

  cellKey(rowIndex: number, columnIndex: number) {
    return `cell_${rowIndex}_${columnIndex}`;
  }

  renderAreas() {
    const areas: JSX.Element[] = [];

    const key = this.cellKey(0, 0);
    areas.push(<GridArea ref={this.rows[0]} area={key} key={key} />);

    for (let i = 1; i < this.rows.length; i++) {
      const key = this.cellKey(i, 0);
      areas.push(<GridArea ref={this.rows[i]} area={key} key={key} />);
    }

    for (let i = 1; i < this.columns.length; i++) {
      const key = this.cellKey(0, i);
      areas.push(<GridArea ref={this.columns[i]} area={key} key={key} />);
    }

    return areas;
  }

  render() {
    return (
      <ShadowContainer
        rows={this.props.rows}
        columns={this.props.columns}
        areas={this.state.cells}>
        {this.renderAreas()}
      </ShadowContainer>
    );
  }
}
