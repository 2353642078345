import * as React from 'react';
import {DateSelectorBase} from './DateSelectorBase';
import {formatDate} from './dateutil';

type Props = {
  value?: Date;
  onSelectDate: (date: Date | null) => void;
  placeholder?: string;
};

export function DateSelector(props: Props): JSX.Element {
  return (
    <DateSelectorBase
      {...props}
      isDayPickerVisible={true}
      dateFormatter={formatDate}
    />
  );
}
