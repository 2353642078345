import {
  Dropdown,
  Icon,
  IDropdownOption,
  ISelectableOption,
} from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
import {textColorTheme} from '../../styles';
import {Sorter} from '../../types/Sorter';
import {SortOrder} from '../../types/SortOrder';

type Props = {
  currentSortOrder: SortOrder;
  sorters: Sorter[];
  onChange: (id: string, current: SortOrder) => void;
};

export function SorterChanger(props: Props): JSX.Element | null {
  const options = props.sorters.map((sorter: Sorter) => {
    return {
      key: sorter.id,
      text: String(sorter.label),
      selected: sorter.id === props.currentSortOrder.sort,
    };
  });

  const styles = {
    root: {
      width: '12em',
    },
  };

  return (
    <Container>
      <Label>並び順</Label>
      <Dropdown
        options={options}
        notifyOnReselect={true}
        styles={styles}
        onRenderOption={(opt) => {
          return opt ? renderOption(opt, props.currentSortOrder) : null;
        }}
        onRenderTitle={(opts) => {
          return opts && opts[0]
            ? renderTitle(opts[0], props.currentSortOrder)
            : null;
        }}
        onChange={(e, opt) => {
          if (opt) {
            props.onChange(opt.key as string, props.currentSortOrder);
          }
        }}
      />
    </Container>
  );
}

function renderOption(opt: ISelectableOption, current: SortOrder) {
  return (
    <Option>
      {renderIcon(opt.key as string, current)}
      {opt.text}
    </Option>
  );
}

function renderIcon(id: string, current: SortOrder) {
  if (id !== current.sort) {
    return <ArrowIcon iconName={''} />;
  }

  const iconName = current.order === 'desc' ? 'SortUp' : 'SortDown';
  return <ArrowIcon iconName={iconName} />;
}

function renderTitle(opt: IDropdownOption, current: SortOrder) {
  return (
    <Option>
      {renderIcon(opt.key as string, current)}
      {opt.text}
    </Option>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  margin-right: 0.5em;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled(Icon)`
  color: ${textColorTheme};
  margin-right: 0.3rem;
`;
