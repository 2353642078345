import React from 'react';
import styled from 'styled-components';
import {ResourceDetails} from '../../types/ResourceDetails';
import {isChecked, Item} from './Item';

type Props = {
  items: ResourceDetails[];
  isChecked: isChecked;
  onChecked: (id: string, checked: boolean) => void;
  size: number;
  nameFieldId?: string;
};

export function Items(props: Props): JSX.Element | null {
  const items = props.items.map((item) => {
    return (
      <Item
        {...props}
        key={`item-${item.id}`}
        item={item}
        checked={props.isChecked(item.id)}
        onCheck={(checked: boolean) => {
          props.onChecked(item.id, checked);
        }}
      />
    );
  });

  return <Container>{items}</Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
