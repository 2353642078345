import * as React from 'react';
import {useState} from 'react';
import styled from 'styled-components';
import {ApiContext, useApiContext} from '../api';
import {convertToFormValues} from '../field/Converter';
import {DetailsField} from '../field/FieldComponent';
import {Sections} from '../sections/Sections';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {RelatedResources, Resource} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Section} from '../types/Section';
import {ToolButton} from '../types/ToolButton';
import {WidgetStyles} from '../types/WidgetStyles';

export type ItemDetailsProps = {
  type: string;
  labelPosition: string;
  fieldSeparator: boolean;
  buttons: ToolButton[];
  farButtons: ToolButton[];
};

type Props = {
  resource: Resource;
  labelPosition?: string;
  fieldSeparator?: boolean;
  sections?: Section[];
  actions: Actions;
};

export function ItemDetails(props: Props): JSX.Element | null {
  const ctx = useApiContext();

  if (!props.resource) {
    return null;
  }

  if (props.sections && props.sections.length > 0) {
    return (
      <Container>
        <FieldsWithSections {...props} ctx={ctx} />
      </Container>
    );
  }

  return (
    <Container>
      <Fields {...props} ctx={ctx} />
    </Container>
  );
}

type FieldsProps = Props & {
  ctx: ApiContext;
};

function FieldsWithSections(props: FieldsProps): JSX.Element {
  const [details, setDetails] = useState<ResourceDetails>(
    props.resource.details,
  );

  const [relatedResources, setRelatedResources] = useState<RelatedResources>(
    props.resource.relatedResources,
  );

  const res = {
    ...props.resource,
    details,
    relatedResources,
  };

  const actions: Actions = {
    ...props.actions,
    patch: (args) => {
      setDetails(args.item);
      setRelatedResources(args.relatedResources);
    },
  };

  const isAvailable = (field: Field): boolean => {
    return field.visible;
  };

  const values = convertToFormValues(res);

  const renderField = (
    field: Field,
    label?: string,
    labelPosition?: string,
    styles?: WidgetStyles,
  ) => {
    return (
      <DetailsField
        ctx={props.ctx}
        field={field}
        label={label}
        resource={res}
        values={values}
        labelPosition={labelPosition || props.labelPosition}
        fieldSeparator={props.fieldSeparator ?? true}
        styles={styles}
        actions={actions}
      />
    );
  };

  return (
    <Sections
      sections={props.sections}
      schema={res.schema}
      resource={res}
      renderField={renderField}
      isAvailable={isAvailable}
    />
  );
}

function Fields(props: FieldsProps): JSX.Element {
  const values = convertToFormValues(props.resource);

  const fields = props.resource.schema.fields.filter(isVisible).map((field) => {
    return (
      <DetailsField
        key={`field-${field.id}`}
        ctx={props.ctx}
        field={field}
        resource={props.resource}
        values={values}
        labelPosition={props.labelPosition}
        fieldSeparator={props.fieldSeparator ?? true}
        actions={props.actions}
      />
    );
  });

  return <>{fields}</>;
}

function isVisible(field: Field): boolean {
  if (field.widget === 'scanner') {
    return false;
  }

  return field.visible;
}

const Container = styled.div`
  background-color: white;
  padding: 0.5rem;
`;
