import {Buttons, Container, Desc, Section, Title} from './Elements';
import {DefaultButton} from '@fluentui/react';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  title: string;
  desc: string;
  onClose: () => void;
};

export function Done(props: Props): JSX.Element {
  const intl = useIntl();

  return (
    <Container>
      <Section>
        <Title>{props.title}</Title>
        <Desc>{props.desc}</Desc>
      </Section>
      <Section>
        <Buttons>
          <DefaultButton
            text={intl.formatMessage({
              id: 'Action.Close',
              defaultMessage: 'Close',
            })}
            onClick={props.onClose}
          />
        </Buttons>
      </Section>
    </Container>
  );
}
