import {DefaultButton} from '@fluentui/react';
import React, {useRef, useState} from 'react';
import {useApiContext} from '../api';
import {Dialog} from '../common/Dialog';
import {ItemDetailsDialog} from '../common/dialogs/ItemDetailsDialog';
import {TargetItemProps} from '../fields/hooks/useItemProps';
import {useMessage} from '../hooks/useMessage';
import {Actions} from '../types/Action';
import {RelatedResources} from '../types/Resource';
import {WidgetStyles} from '../types/WidgetStyles';
import {CheckboxWidget} from './CheckboxWidget';
import {ClearButton} from './common/ClearButton';
import {
  buildOptions,
  OptionButtonContainer,
  OptionButtonDescription,
  useItems,
  useRenderClickableLabel,
  useRenderStaticLabel,
} from './item/ItemOptionButton';
import {ItemSelectorDialog} from './item/ItemSelectorDialog';

type Props = {
  value: string[];
  onChange: (ids: string[]) => void;
  checked: string[];
  onCheck: (ids: string[]) => void;
  onClear: () => void;
  title: string;
  disabledIds: string[];
  actions: Actions;
  capacity?: number;
  styles?: WidgetStyles;
  relatedResources?: RelatedResources;
  checkboxDescription?: string;
  checkboxDescriptionView?: string;
  targets: TargetItemProps[];
  readOnly?: boolean;
};

export function ItemCheckboxWidget(props: Props): JSX.Element {
  if (props.readOnly) {
    return <ItemCheckboxOutputWidget {...props} />;
  }

  return <ItemCheckboxInputWidget {...props} />;
}

export function ItemCheckboxOutputWidget(props: Props): JSX.Element {
  const {items, itemsMap} = useItems(props);
  const options = buildOptions(items);
  const [itemId, setItemId] = useState<string>();
  const [schemaId, setSchemaId] = useState<string>();
  const [shown, setShown] = useState<boolean>(false);
  const ctx = useApiContext();
  const onRenderLabel = useRenderClickableLabel(
    itemsMap,
    props.targets,
    setItemId,
    setSchemaId,
    setShown,
  );

  return (
    <div>
      {items.length > 0 ? (
        <>
          <OptionButtonDescription
            text={props.checkboxDescriptionView || props.checkboxDescription}
          />
          <CheckboxWidget
            value={props.checked}
            options={options}
            readOnly={true}
            onRenderLabel={onRenderLabel}
          />
          <ItemDetailsDialog
            ctx={ctx}
            shown={shown}
            schemaId={schemaId}
            resId={itemId}
            onClose={() => {
              setShown(false);
            }}
            actions={props.actions}
            minWidth={'400px'}
          />
        </>
      ) : null}
    </div>
  );
}

export function ItemCheckboxInputWidget(props: Props): JSX.Element {
  const dialog = useRef<Dialog>(null);
  const {onChange, items, itemsMap} = useItems(props);
  const options = buildOptions(items);
  const onRenderLabel = useRenderStaticLabel(itemsMap, props.targets);

  return (
    <div>
      <DefaultButton
        text={useMessage('Widget.Item.Select', 'Select')}
        onClick={() => {
          if (dialog.current) {
            dialog.current.showDialog();
          }
        }}
        styles={{
          root: {
            marginRight: '0.5rem',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <ClearButton onClick={props.onClear} />
      <ItemSelectorDialog
        {...props}
        componentRef={dialog}
        value={items}
        onSelect={onChange}
      />
      {items.length > 0 ? (
        <OptionButtonContainer>
          <OptionButtonDescription text={props.checkboxDescription} />
          <CheckboxWidget
            value={props.checked}
            options={options}
            onChange={(id, checked) => {
              onChecked(props, id, checked);
            }}
            onRenderLabel={onRenderLabel}
            showCheckAll={true}
            onCheckAll={(checked) => {
              if (checked) {
                props.onCheck(props.value);
              } else {
                props.onCheck([]);
              }
            }}
          />
        </OptionButtonContainer>
      ) : null}
    </div>
  );
}

function onChecked(props: Props, id: string, checked?: boolean) {
  const newValues = [...props.checked];
  const index = newValues.indexOf(id);

  if (checked && index < 0) {
    newValues.push(id);
  } else if (index > -1) {
    newValues.splice(index, 1);
  }

  props.onCheck(newValues);
}
