import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {Spinner} from '../../common/Spinner';
import {AntennaScannedData} from './AntennaScannedData';
import {ScannedValues} from './ScannedValues';

export type ScannedValueSelectorProps = {
  selected: string;
  onSelect: (code: string) => void;
};

type Props = ScannedValueSelectorProps & {
  data: AntennaScannedData | null;
  style: CSSProperties;
};

export function ScannedValueSelector(props: Props): JSX.Element | null {
  if (props.data === null) {
    return <Spinner />;
  }

  return (
    <Container style={props.style}>
      <ScannedValues {...props} data={props.data} />
    </Container>
  );
}

const Container = styled.div``;
