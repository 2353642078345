import React from 'react';
import styled from 'styled-components';
import {
  bodyColorAlt,
  borderColorLightest,
  borderColorMiddle,
  textColor,
} from '../styles';
import {Resource} from '../types/Resource';
import {Schema} from '../types/Schema';
import {Section} from '../types/Section';
import {Styles} from '../types/Styles';
import {Available, RenderField} from './FieldSection';
import {isAvailable, Sections} from './Sections';

type Props = {
  label: string;
  schema: Schema;
  resource: Resource;
  sections?: Section[];
  renderField: RenderField;
  isAvailable: Available;
  styles?: Styles;
};

export function FrameSection(props: Props): JSX.Element | null {
  if (!isAvailable(props)) {
    return null;
  }

  return (
    <FrameContainer style={props.styles?.container}>
      <FrameLabel>{props.label}</FrameLabel>
      <FrameContents>
        <Sections {...props} />
      </FrameContents>
    </FrameContainer>
  );
}

const FrameContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid ${borderColorMiddle};
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  flex-grow: 1;
`;

const FrameLabel = styled.div`
  background: ${bodyColorAlt};
  border-bottom: 1px solid ${borderColorLightest};
  color: ${textColor};
  padding: 0.5rem;
`;

const FrameContents = styled.div`
  padding: 0.5rem;
`;
