import React from 'react';
import styled from 'styled-components';
import {Spinner as OriginalSpinner, SpinnerSize} from '@fluentui/react';

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export function Spinner(): JSX.Element | null {
  return (
    <Wrapper>
      <OriginalSpinner size={SpinnerSize.large} />
    </Wrapper>
  );
}
