import React, {KeyboardEventHandler} from 'react';
import styled from 'styled-components';
import {TextInputWidget, TextOutputWidget} from './TextWidget';
import {useIntl} from 'react-intl';
import {IntlShape} from 'react-intl/src/types';
import {padLeft} from './common/dateutil';

type Props = {
  value?: string;
  onChange: (v?: string) => void;
  readOnly?: boolean;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function YearMonthWidget(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <YearMonthOutputWidget {...props} />;
  }

  return <YearMonthInputWidget {...props} />;
}

export function YearMonthOutputWidget(props: Props): JSX.Element | null {
  const value = extract(props.value);
  const text = buildOutputText(value);

  return (
    <OutputContainer>
      <TextOutputWidget value={text} copy={false} />
    </OutputContainer>
  );
}

export function YearMonthInputWidget(props: Props): JSX.Element | null {
  const value = extract(props.value);
  const affix = useAffix();

  const setYear = (year?: string) => {
    const ym = withYear(value, year);
    props.onChange(ym);
  };

  const setMonth = (month?: string) => {
    const ym = withMonth(value, month);
    props.onChange(ym);
  };

  return (
    <InputContainer>
      <TextInputWidget
        onChange={setYear}
        value={value.yearText}
        multiline={false}
        prefix={affix.yearPrefix}
        suffix={affix.yearSuffix}
        textProps={{type: 'number', min: 1, max: 9999}}
        styles={{field: {textOverflow: 'clip'}}}
        onKeyUp={props.onKeyUp}
      />
      <TextInputWidget
        onChange={setMonth}
        value={value.monthText}
        multiline={false}
        prefix={affix.monthPrefix}
        suffix={affix.monthSuffix}
        textProps={{type: 'number', min: 1, max: 12}}
        styles={{field: {textOverflow: 'clip'}}}
        onKeyUp={props.onKeyUp}
      />
    </InputContainer>
  );
}

type Affix = {
  yearPrefix?: string;
  yearSuffix?: string;
  monthPrefix?: string;
  monthSuffix?: string;
};

function useAffix(): Affix {
  const intl = useIntl();

  return {
    yearPrefix: messageOrUndefined(intl, 'Widget.MonthText.YearPrefix'),
    yearSuffix: messageOrUndefined(intl, 'Widget.MonthText.YearSuffix'),
    monthPrefix: messageOrUndefined(intl, 'Widget.MonthText.MonthPrefix'),
    monthSuffix: messageOrUndefined(intl, 'Widget.MonthText.MonthSuffix'),
  };
}

function messageOrUndefined(intl: IntlShape, id: string): string | undefined {
  const msg = intl.formatMessage({
    id,
  });

  // If msg is blank, it means that no translation exists. In this case, undefined is returned.
  if (msg === ' ') {
    return;
  }

  return msg;
}

const OutputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const layout = /^(\d{4})-(\d{2})$/;

type YearMonth = {
  year: number;
  yearText: string;
  month: number;
  monthText: string;
};

function extract(value?: string): YearMonth {
  if (!value) {
    return empty();
  }

  const matched = value.match(layout);

  if (!matched) {
    console.log('not matched', value);
    return empty();
  }

  const year = parseInt(matched[1], 10);
  const yearText = toText(year);
  const month = parseInt(matched[2], 10);
  const monthText = toText(month);

  return {
    year,
    yearText,
    month,
    monthText,
  };
}

function empty(): YearMonth {
  return {
    year: 0,
    yearText: '',
    month: 0,
    monthText: '',
  };
}

function toText(n: number): string {
  if (n <= 0) {
    return '';
  }

  return String(n);
}

function withYear(ym: YearMonth, year?: string): string {
  return emptyIfAllZero(normalizeYear(year) + '-' + normalizeMonth(ym.month));
}

function withMonth(ym: YearMonth, month?: string): string {
  return emptyIfAllZero(normalizeYear(ym.year) + '-' + normalizeMonth(month));
}

function normalizeYear(year?: string | number): string {
  return normalize(year, 4);
}

function normalizeMonth(month?: string | number): string {
  return normalize(month, 2);
}

function normalize(
  value: string | number | undefined,
  numDigit: number,
): string {
  if (!value) {
    return padLeft('', numDigit);
  }

  if (typeof value === 'string') {
    const i = parseInt(value, 10);

    if (Number.isNaN(i) || i <= 0) {
      return padLeft('', numDigit);
    }
  }

  return padLeft(value, numDigit).substring(0, numDigit);
}

function emptyIfAllZero(ymText: string): string {
  if (ymText === '0000-00') {
    return '';
  }

  return ymText;
}

function buildOutputText(ym: YearMonth): string {
  const year = normalizeYear(ym.year);
  const month = normalizeMonth(ym.month);

  if (year === '0000' && month === '00') {
    return '';
  }

  if (month === '00') {
    return year;
  }

  return year + '/' + month;
}
