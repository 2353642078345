import * as React from 'react';
import styled from 'styled-components';
import {Field} from '../types/Field';
import {TableColumn} from '../types/TableColumn';
import {ResourceList} from '../types/ResourceList';
import {ToolButton} from '../types/ToolButton';
import {
  CheckboxProps,
  ResourceDetailsTable,
} from '../common/ResourceDetailsTable';
import {Sorter} from '../types/Sorter';
import {SortOrder} from '../types/SortOrder';
import {TableTool} from '../types/Table';
import {Actions} from '../types/Action';

export type GridListProps = {
  type: string;
  schemaId: string;
  columns: TableColumn[];
  buttons: ToolButton[];
  farButtons: ToolButton[];
  sorters?: Sorter[];
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];
  checkbox?: boolean;
};

type Props = {
  columns: TableColumn[];
  onSelect: (item: any) => void;
  selectedIds?: string[];
  resourceList: ResourceList;
  onChangeCurrentPage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeSorter: (id: string, current: SortOrder) => void;
  sorters?: Sorter[];
  emptyMessage?: string;
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];
  actions: Actions;
} & CheckboxProps;

const Container = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export function GridList(props: Props) {
  const cols = React.useMemo(() => {
    return buildColumns(props.resourceList, props.columns);
  }, [props.resourceList, props.columns]);

  if (!cols) {
    return null;
  }

  return (
    <Container>
      <ResourceDetailsTable expandLastColumn={false} {...props} />
    </Container>
  );
}

export function buildColumns(
  resourceList: ResourceList,
  columns?: TableColumn[],
): TableColumn[] {
  if (!columns) {
    return [];
  }

  const fields = resourceList.schema.fields.reduce((x, f) => {
    x[f.id] = f;
    return x;
  }, {} as {[key: string]: Field});

  const cols: TableColumn[] = [];

  for (let col of columns) {
    const field = fields[col.fieldId];

    if (!field) {
      continue;
    }

    cols.push({
      ...col,
      label: col.label || field.label,
    });
  }

  return cols;
}
