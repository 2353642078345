import {ChoiceGroup, IChoiceGroupOption} from '@fluentui/react';
import * as React from 'react';
import styled from 'styled-components';
import {textColor, textColorLight} from '../styles';
import {Option} from '../types/Field';
import {buildRenderer, RenderLabelFn} from './CheckboxWidget';
import {ClearButton} from './common/ClearButton';

type Props = {
  value: string | number;
  options?: Option[];
  onChange?: (value: string | number) => void;
  vertical?: boolean;
  readOnly?: boolean;
  noClearButton?: boolean;
  onRenderLabel?: RenderLabelFn;
};

type OptionsProps = {
  value: string | number;
  options: Option[];
  onChange?: (value: string | number) => void;
  readOnly?: boolean;
  onRenderLabel?: RenderLabelFn;
};

function Options(props: OptionsProps): JSX.Element | null {
  const onChange = (v: string) => {
    if (props.onChange) {
      props.onChange(v);
    }
  };

  const opts: IChoiceGroupOption[] = props.options.map((option) => ({
    key: option.value,
    text: option.label,
    onRenderLabel: buildRenderer(option, props.onRenderLabel),
  }));

  return (
    <ChoiceGroup
      selectedKey={props.value ?? ''}
      options={opts}
      onChange={(_, option) => {
        const value = (option || {}).key;
        onChange(value === undefined ? '' : value);
      }}
      disabled={props.readOnly}
    />
  );
}

export function RadioWidget(props: Props): JSX.Element | null {
  const onChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onClear = () => {
    onChange('');
  };

  if (!props.options) {
    return null;
  }

  const Container = props.vertical ? VerticalWrapper : HorizontalWrapper;

  return (
    <Wrapper>
      <Container>
        <RadioWidgetOptions {...props} />
        <RadioWidgetClearButton {...props} onClear={onClear} />
      </Container>
    </Wrapper>
  );
}

function RadioWidgetOptions(props: Props): JSX.Element {
  return (
    <Options
      value={props.value}
      options={props.options || []}
      onChange={props.onChange}
      readOnly={props.readOnly}
      onRenderLabel={props.onRenderLabel}
    />
  );
}

function RadioWidgetClearButton(
  props: Props & {onClear: () => void},
): JSX.Element | null {
  if (props.readOnly) {
    return null;
  }

  if (props.noClearButton) {
    return null;
  }

  return (
    <ClearButton
      onClick={props.onClear}
      styles={{root: {marginTop: '0.5rem'}}}
    />
  );
}

const Wrapper = styled.div``;

const iconSize = 20;

const VerticalWrapper = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-right: 1.5rem;

  & .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    line-height: ${iconSize}px;
  }

  & input:disabled:checked + .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    color: ${textColor};
  }

  & input:disabled:checked + .ms-ChoiceField-field::before {
    &::before,
    &::after {
      border-color: ${textColor};
    }
  }
`;

const HorizontalWrapper = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  & .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    line-height: ${iconSize}px;
  }

  & input:disabled:checked + .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    color: ${textColor};
  }

  & input:disabled:checked + .ms-ChoiceField-field {
    &::before,
    &::after {
      border-color: ${textColorLight};
    }
  }

  & .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }

  & .ms-ChoiceField {
    margin-right: 1.5rem;
    margin-top: 0;
  }
`;
