import {createContext} from 'react';
import {BreadcrumbProps} from './common/BreadcrumbList';
import {ToolButton} from './types/ToolButton';

export type NavProps = {
  breadcrumb: BreadcrumbProps[];
  buttons: ToolButton[];
  isMenuHidden?: boolean;
};

export type SetNav = (nav: NavProps) => void;

export const NavContext = createContext({
  nav: undefined as NavProps | undefined,
  setNav: (nav: NavProps) => {},
});
