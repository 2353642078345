import React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {MonthInputWidget, MonthOutputWidget} from '../widgets/MonthWidget';
import {useOnChange} from './hooks/useOnChange';

type Props = FieldValueProps;

export const MonthField = (props: Props) => {
  if (props.readOnly) {
    return <MonthOutputWidget {...props} />;
  }

  return <MonthInputField {...props} />;
};

const MonthInputField = (props: Props) => {
  const onChange = useOnChange(props.onChange, props.field.id);
  return <MonthInputWidget {...props} onChange={onChange} />;
};
