import React, {CSSProperties} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {ApiContext} from '../api';
import {ErrorMsg} from '../common/ErrorMsg';
import {WrapText} from '../common/WrapText';
import {
  bodyColorError,
  borderColorLightest,
  labelColor,
  textColorError,
  textColorLight,
} from '../styles';
import {Actions} from '../types/Action';
import {ErrorReason, Errors} from '../types/Errors';
import {Field} from '../types/Field';
import {FormValues} from '../types/Form';
import {
  AttachmentFiles,
  RelatedProxies,
  RelatedResources,
  RelatedSchemas,
  Resource,
} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Schema} from '../types/Schema';
import {WidgetStyles} from '../types/WidgetStyles';
import {array} from '../util';
import {widgetProps} from '../widgets/common';
import {DetailsFieldValue} from './DetailsFieldValue';
import {FieldValues, OnChangeFieldValue} from './FieldValue';
import {FilterFieldValue} from './FilterFieldValue';
import {FormFieldValue} from './FormFieldValue';

type CommonDetailsFieldProps = {
  ctx: ApiContext;
  field: Field;
  values: FormValues;
  label?: string;
  labelPosition?: string;
  fieldSeparator: boolean;
  styles?: WidgetStyles;
  actions: Actions;
};

type DetailsFieldProps = CommonDetailsFieldProps & {
  resource: Resource;
};

export function DetailsField(props: DetailsFieldProps) {
  return (
    <RawDetailsField
      {...props}
      {...props.resource}
      item={props.resource.details}
    />
  );
}

type RawDetailsFieldProps = CommonDetailsFieldProps & {
  schema: Schema;
  item: ResourceDetails;
  relatedSchemas: RelatedSchemas;
  relatedResources: RelatedResources;
  relatedProxies: RelatedProxies;
  attachmentFiles: AttachmentFiles;
  timestamp: string;
};

export function RawDetailsField(props: RawDetailsFieldProps) {
  const field = props.field;
  const value = props.values[field.id];
  const FieldContainer = selectFieldContainer(props.labelPosition);
  const borderColor = selectBorderColor(props.fieldSeparator);

  if (props.field.type === 'embed') {
    if (!value || array(value).length === 0) {
      return null;
    }

    return (
      <EmbedFieldContainer>
        <DetailsFieldValue
          {...props}
          ctx={props.ctx}
          field={field}
          value={value}
          readOnly={true}
          onChange={doNothing}
        />
      </EmbedFieldContainer>
    );
  }

  const label = props.label || widgetProps(field, 'label', field.label);

  return (
    <FieldContainer style={{borderColor}}>
      {/* Set to null only if noLabel. If label is empty, only the location is allocated. */}
      {noLabel(props.labelPosition) ? null : (
        <FieldLabel>
          <WrapText text={label} />
        </FieldLabel>
      )}
      <FieldValueContainer>
        <DetailsFieldValue
          {...props}
          ctx={props.ctx}
          field={field}
          value={value}
          readOnly={true}
          onChange={doNothing}
        />
      </FieldValueContainer>
    </FieldContainer>
  );
}

function doNothing(v: any) {}

type CommonFromFieldProps = {
  ctx: ApiContext;
  value: any;
  values: FieldValues;
  field: Field;
  label?: string;
  labelPosition?: string;
  fieldSeparator: boolean;
  onChange: OnChangeFieldValue;
  messages: ErrorReason[];
  styles?: WidgetStyles;
  actions: Actions;
  errors: Errors;
};

type FormFieldProps = CommonFromFieldProps & {
  resource: Resource;
};

export function FormFieldContainer(props: FormFieldProps) {
  return (
    <RawFormFieldContainer
      {...props}
      {...props.resource}
      item={props.resource.details}
    />
  );
}

type RawFormFieldProps = CommonFromFieldProps & {
  schema: Schema;
  item: ResourceDetails;
  relatedSchemas: RelatedSchemas;
  relatedResources: RelatedResources;
  relatedProxies: RelatedProxies;
  attachmentFiles: AttachmentFiles;
  timestamp: string;
};

export function RawFormFieldContainer(
  props: RawFormFieldProps,
): JSX.Element | null {
  const FieldContainer = selectFieldContainer(props.labelPosition);
  const borderColor = selectBorderColor(props.fieldSeparator);

  if (props.field.type === 'embed') {
    return (
      <EmbedFieldContainer>
        <FormFieldValue {...props} readOnly={!props.field.editable} />
      </EmbedFieldContainer>
    );
  }

  const label =
    props.label || widgetProps<string>(props.field, 'label', props.field.label);

  return (
    <FieldContainer
      style={{...makeFieldContainerStyle(props.messages), borderColor}}>
      <FieldLabelWithMark
        label={label}
        required={props.field.required}
        hidden={noLabel(props.labelPosition)}
      />
      <FieldValueContainer>
        <FieldDescription field={props.field} />
        <ErrorMsg messages={props.messages} />
        <FormFieldValue {...props} readOnly={!props.field.editable} />
      </FieldValueContainer>
    </FieldContainer>
  );
}

function makeFieldContainerStyle(errs?: ErrorReason[]): CSSProperties {
  if (errs && errs.length > 0) {
    return {backgroundColor: bodyColorError};
  }

  return {};
}

export type OnGetValue = (key: string) => any;
export type OnSearch = (optValues?: FormValues) => void;

type FilterFieldProps = {
  ctx: ApiContext;
  value: any;
  values: FieldValues;
  field: Field;
  label?: string;
  resourceList: ResourceList;
  labelPosition?: string;
  onGetValue: OnGetValue;
  onSearch: OnSearch;
  onChange: OnChangeFieldValue;
  styles?: WidgetStyles;
  actions: Actions;
};

export function FilterFieldContainer(props: FilterFieldProps) {
  const field = props.field;
  const FieldContainer = NoBorderVerticalFieldContainer;

  // Filter does not use widget_props for label because the layout is different from the others.
  const label = props.label ?? field.searchLabel ?? field.label;

  return (
    <FieldContainer>
      <FieldLabelOrNull label={label} hidden={noLabel(props.labelPosition)} />
      <FieldValueContainer>
        <FilterFieldValue
          {...props}
          field={field}
          schema={props.resourceList.schema}
          relatedSchemas={props.resourceList.relatedSchemas}
          relatedResources={props.resourceList.relatedResources}
          readOnly={false}
          timestamp={props.resourceList.timestamp}
        />
      </FieldValueContainer>
    </FieldContainer>
  );
}

type FieldLabelProps = {
  label: string;
  required?: boolean;
  hidden: boolean;
};

function FieldLabelWithMark({label, required, hidden}: FieldLabelProps) {
  const intl = useIntl();

  if (!label || hidden) {
    return null;
  }

  if (!required) {
    return (
      <FieldLabel>
        <WrapText text={label} />
      </FieldLabel>
    );
  }

  const requiredMark = intl.formatMessage({
    id: 'FieldProps.Required.Mark',
    defaultMessage: '*',
  });
  const requiredLabel = intl.formatMessage({
    id: 'FieldProps.Required.Label',
    defaultMessage: 'Required',
  });

  return (
    <FieldLabel>
      <WrapText text={label} />
      <RequiredMark title={requiredLabel} mark={requiredMark} />
    </FieldLabel>
  );
}

function FieldLabelOrNull({label, hidden}: {label: string; hidden: boolean}) {
  if (!label || hidden) {
    return null;
  }

  return (
    <FieldLabel>
      <WrapText text={label} />
    </FieldLabel>
  );
}

type FieldDescriptionProps = {
  field: Field;
};

function FieldDescription(props: FieldDescriptionProps): JSX.Element | null {
  if (!props.field.description) {
    return null;
  }

  return <Description>{props.field.description}</Description>;
}

function selectFieldContainer(...labelPositions: (string | undefined)[]) {
  for (let p of labelPositions) {
    const c = _selectFieldContainer(p);

    if (c !== null) {
      return c;
    }
  }

  return VerticalFieldContainer;
}

function _selectFieldContainer(labelPosition?: string) {
  if (labelPosition === 'left' || labelPosition === 'left-none') {
    return HorizontalFieldContainer;
  }

  if (labelPosition === 'top' || labelPosition === 'top-none') {
    return VerticalFieldContainer;
  }

  return null;
}

function selectBorderColor(fieldSeparator: boolean): string {
  if (fieldSeparator) {
    return borderColorLightest;
  }

  return 'transparent';
}

function noLabel(labelPosition?: string): boolean {
  return labelPosition !== undefined && labelPosition.endsWith('-none');
}

const FieldLabel = styled.div`
  color: ${labelColor};
  font-size: 0.8rem;
  line-height: 1.5;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const FieldValueContainer = styled.div`
  min-height: 1rem;
`;

const HorizontalFieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid ${borderColorLightest};

  &:last-child {
    border-bottom: none;
  }

  & ${FieldLabel} {
    margin-right: 1em;
    text-align: center;
    padding: 0.3rem;
    flex-grow: 1;
  }

  & ${FieldValueContainer} {
    padding: 0.2rem;
    min-width: calc(100% - 10em);
    width: calc(100% - 10em);
  }
`;

const VerticalFieldContainer = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid ${borderColorLightest};

  &:last-child {
    border-bottom: none;
  }

  & ${FieldLabel} {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }
`;

const EmbedFieldContainer = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${borderColorLightest};

  &:last-child {
    border-bottom: none;
  }
`;

const NoBorderVerticalFieldContainer = styled.div`
  margin-bottom: 1rem;

  & ${FieldLabel} {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }
`;

const RequiredMark = styled.div<{mark: string}>`
  display: inline-block;

  &::after {
    content: '${(props) => props.mark}';
    color: ${textColorError};
    padding: 0.2rem;
    word-break: keep-all;
    border-radius: 4px;
  }
`;

const Description = styled.div`
  color: ${textColorLight};
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;
