import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {api} from '../api';
import {newDummyNav} from '../common/Nav';
import {Spinner} from '../common/Spinner';
import {ItemDetails} from '../components/ItemDetails';
import {containsPatchTrigger} from '../components/ItemForm';
import {ItemFormFields} from '../components/ItemFormFields';
import {convertFromFormValues} from '../field/Converter';
import {FieldValueProps} from '../field/FieldValue';
import {FormFieldValueProps} from '../field/FormFieldValue';
import {borderColorLightest, colors, labelColor} from '../styles';
import {Errors} from '../types/Errors';
import {Resource} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {array} from '../util';
import {widgetProps} from '../widgets/common';
import {AddButton} from '../widgets/composite/AddButton';
import {RemoveButton} from '../widgets/composite/RemoveButton';

type OutputProps = FieldValueProps;

export function EmbedOutputField(props: OutputProps): JSX.Element | null {
  const fieldProps = useEmbeddedField(props);

  if (!fieldProps) {
    return null;
  }

  const allValues = array(props.value);

  if (allValues.length === 0) {
    return null;
  }

  const fs = allValues.map((values, i) => {
    return (
      <CompositeContainer key={`form-${i}`}>
        <EmbedFieldLabelOrNull label={props.label || props.field.label} />
        <ItemDetails
          {...props}
          resource={asResource(props, fieldProps.itemSchema, values)}
        />
      </CompositeContainer>
    );
  });

  return <Container>{fs}</Container>;
}

function asResource(
  props: OutputProps,
  schema: Schema,
  item: ResourceDetails,
): Resource {
  return {
    id: item.id,
    details: item,
    schema: overrideSchema(schema, item),
    relatedSchemas: props.relatedSchemas,
    relatedResources: props.relatedResources,
    relatedProxies: {},
    attachmentFiles: {},
    timestamp: props.timestamp,
    nav: newDummyNav(),
  };
}

function overrideSchema(schema: Schema, details?: ResourceDetails) {
  if (!details) {
    return schema;
  }

  const override = details.__schema__;

  if (!override || !override.fields) {
    return schema;
  }

  const newFields = schema.fields.map((field) => {
    if (!override.fields![field.id]) {
      return field;
    }

    return {
      ...field,
      ...override.fields![field.id],
    };
  });

  return {
    ...schema,
    fields: newFields,
  };
}

type InputProps = FormFieldValueProps;

export function EmbedInputField(props: InputProps): JSX.Element | null {
  const [adding, setAdding] = useState<boolean>(false);
  const label = props.label || props.field.label;
  const fieldSeparator = widgetProps<boolean>(
    props.field,
    'field_separator',
    true,
  );
  const fieldProps = useEmbeddedField(props);

  if (!fieldProps) {
    return null;
  }

  const allValues = array(props.value);

  const fs = allValues.map((values, i) => {
    return (
      <CompositeContainer key={`form-${i}`}>
        <RemoveButton
          label={label}
          size={buttonSize}
          onPress={() => {
            allValues.splice(i, 1);
            props.onChange({[props.field.id]: [...allValues]});
          }}
        />
        <EmbeddedItem
          allValues={allValues}
          index={i}
          props={props}
          fieldProps={fieldProps}
          fieldSeparator={fieldSeparator}
        />
      </CompositeContainer>
    );
  });

  return (
    <Container>
      {fs}
      {adding ? (
        <div style={{height: buttonSize + 'px'}}>
          <Spinner />
        </div>
      ) : (
        <AddButton
          label={label}
          size={buttonSize}
          onPress={async () => {
            try {
              setAdding(true);
              const res = await api.new_(props.ctx, fieldProps.itemSchema.id);
              fieldProps.setItemSchema(res.schema);
              props.onChange({[props.field.id]: [...allValues, res.details]});
            } finally {
              setAdding(false);
            }
          }}
        />
      )}
    </Container>
  );
}

const buttonSize = 32;

type EmbeddedItemProps = {
  allValues: any[];
  index: number;
  props: InputProps;
  fieldProps: CompositeFieldProps;
  fieldSeparator: boolean;
};

function EmbeddedItem({
  allValues,
  index,
  props,
  fieldProps,
  fieldSeparator,
}: EmbeddedItemProps): JSX.Element {
  const [schema, setSchema] = useState<Schema>(
    overrideSchema(fieldProps.itemSchema, allValues[index]),
  );

  return (
    <EmbeddedItemContainer>
      <ItemFormFields
        {...props}
        values={allValues[index]}
        onChange={async (vs) => {
          const v = {
            ...allValues[index],
            ...vs,
          };

          allValues[index] = v;
          props.onChange({[props.field.id]: [...allValues]});

          if (containsPatchTrigger(fieldProps.itemSchema, Object.keys(vs))) {
            try {
              const patchedValues = await api.patch(
                props.ctx,
                schema.id,
                v.id,
                convertFromFormValues(schema, v),
              );

              allValues[index] = {...v, ...patchedValues.details};
              props.onChange({[props.field.id]: [...allValues]});
              setSchema(patchedValues.schema);
            } catch (ignore) {}
          }
        }}
        schema={schema}
        fields={schema.fields}
        errors={toErrors(props.errors, props.field.id, index)}
        fieldSeparator={fieldSeparator}
      />
    </EmbeddedItemContainer>
  );
}

function toErrors(errs: Errors, fid: string, index: number): Errors {
  if (!errs[fid] || !errs[fid][index]) {
    return {};
  }

  const e = errs[fid][index];

  if (typeof e === 'object') {
    //TODO type check
    return e as Errors;
  }

  return {};
}

type CompositeFieldProps = {
  itemSchema: Schema;
  setItemSchema: (schema: Schema) => void;
};

function useEmbeddedField({
  ctx,
  field,
}: FieldValueProps): CompositeFieldProps | null {
  const [schema, setSchema] = useState<Schema>();

  const itemType = field.itemType;

  useEffect(() => {
    (async () => {
      try {
        if (itemType) {
          const schema = await api.fetchSchema(ctx, itemType, {});
          setSchema(schema);
        }
      } catch (ignore) {}
    })();
  }, [ctx, itemType]);

  if (!schema) {
    return null;
  }

  return {
    itemSchema: schema,
    setItemSchema: setSchema,
  };
}

function EmbedFieldLabelOrNull({label}: {label: string}) {
  if (!label) {
    return null;
  }

  return <EmbedFieldLabel>{label}</EmbedFieldLabel>;
}

const Container = styled.div``;

const CompositeContainer = styled.div`
  border: 1px solid ${borderColorLightest};
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const EmbedFieldLabel = styled.div`
  display: inline-block;
  color: ${labelColor};
  font-size: 0.8rem;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0.3rem;
  background-color: ${colors.bodyColorAlt};
  border-right: 1px dashed ${colors.borderColorLightest};
  border-bottom: 1px dashed ${colors.borderColorLightest};
  margin-bottom: -0.5rem;
`;

const EmbeddedItemContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;
