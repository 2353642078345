import React from 'react';
import styled from 'styled-components';
import {Toolbar} from '../../common/Toolbar';
import {ToolButton} from '../../types/ToolButton';
import {Sorter} from '../../types/Sorter';

type Props = {
  buttons?: ToolButton[];
  farButtons?: ToolButton[];
  mappings: {[key: string]: any};
  sorterId: string;
  sortOrderDesc: boolean;
  sorters?: Sorter[];
};

export function ListHeader(props: Props): JSX.Element {
  return (
    <Container>
      <Toolbar
        buttons={buildListButtons(props, props.buttons)}
        farButtons={buildListButtons(props, props.farButtons)}
      />
    </Container>
  );
}

export const Container = styled.div``;

function buildListButtons(props: Props, buttons?: ToolButton[]) {
  if (!buttons) {
    return [];
  }

  const sorters = props.sorters ?? [];

  buttons.forEach((button) => {
    if (button.type === 'sort') {
      if (!button.sorters) {
        button.sorters = sorters;
      }

      button.currentSort = {
        sorterId: props.sorterId,
        desc: props.sortOrderDesc,
      };
    }

    button.onClick = props.mappings[button.type];
  });

  return buttons;
}
