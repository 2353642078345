import React from 'react';
import {useIntl} from 'react-intl';
import {ButtonWidget} from '../ButtonWidget';
import {LogoButton} from './LogoButton';
import {MultipleItemSelector} from './MultipleItemSelector';
import {ScanData} from './ScanData';
import {
  Body,
  ButtonContainer,
  Buttons,
  calcButtonWidth,
  calcWidth,
  DataContainer,
  Header,
  ItemsContainer,
  useHeaderStyle,
} from './UsersAndThings';

type Props = {
  data: ScanData;
  size: number;
};

export function AdditionalList(props: Props): JSX.Element | null {
  const intl = useIntl();
  const headerStyle = useHeaderStyle(props.size);
  const width = calcWidth(props.size);
  const {min, max} = calcButtonWidth(props.size);

  const additionalList = props.data.getCurrentAction()?.additionalList;

  if (!additionalList) {
    return null;
  }

  return (
    <>
      <DataContainer>
        <ItemsContainer style={{flexGrow: 1}} width={width}>
          <Header style={headerStyle}>{additionalList.header}</Header>
          <Body size={props.size}>
            <MultipleItemSelector
              checkedIds={props.data.getAdditionalIds()}
              items={props.data.getAdditionalItems()}
              onChecked={props.data.setAdditionalIds}
              size={props.size}
              nameFieldId={additionalList.nameFieldId}
            />
          </Body>
        </ItemsContainer>
      </DataContainer>
      <Buttons>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.goBack}
            label={intl.formatMessage({
              id: 'Widget.Scan.Action.Back',
              defaultMessage: 'Back',
            })}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            styles={{
              container: {justifyContent: 'start'},
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.doCurrentAction}
            label={additionalList.actionLabel}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            primary={true}
            disabled={
              !props.data.canSendRequest() ||
              props.data.getAdditionalIds().length === 0
            }
            styles={{
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.doCurrentAction}
            label={additionalList.noItemActionLabel}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            primary={true}
            disabled={
              !props.data.canSendRequest() ||
              props.data.getAdditionalIds().length > 0
            }
            styles={{
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <LogoButton />
      </Buttons>
    </>
  );
}
