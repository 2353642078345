import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Field} from '../types/Field';
import {Params} from '../types/Params';
import {RelatedResources} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {widgetProps} from '../widgets/common';
import {useActions} from '../widgets/scan/ActionButtons';
import {ScanWidget} from '../widgets/ScanWidget';

type Props = {
  value: unknown;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  relatedResources: RelatedResources;
};

export function ScanField(props: Props): JSX.Element | null {
  const intl = useIntl();

  const path = widgetProps<string>(
    props.field,
    'path',
    useMemo(() => String(props.value), [props.value]),
  );
  const params = widgetProps<Params>(
    props.field,
    'params',
    useMemo(() => ({}), []),
  );
  const userFieldId = widgetProps<string>(
    props.field,
    'user_field_id',
    'user_id',
  );
  const userLabel = widgetProps<string>(
    props.field,
    'user_label',
    intl.formatMessage({
      id: 'Widget.Scan.User',
      defaultMessage: 'User',
    }),
  );
  const thingFieldId = widgetProps<string>(
    props.field,
    'thing_field_id',
    'thing_instance_id',
  );
  const thingLabel = widgetProps<string>(
    props.field,
    'thing_label',
    intl.formatMessage({
      id: 'Widget.Scan.Thing',
      defaultMessage: 'Thing',
    }),
  );
  const unknownFieldId = widgetProps<string>(
    props.field,
    'unknown_field_id',
    'unknown',
  );
  const cancelTimeout = widgetProps<number>(
    props.field,
    'cancel_timeout',
    60 /* 1 minute */,
  );
  const reloadTimeout = widgetProps<number>(
    props.field,
    'reload_timeout',
    600 /* 10 minutes */,
  );
  const thingAutoCheck = widgetProps<boolean>(
    props.field,
    'thing_auto_check',
    true,
  );
  const size = selectSize(props);
  const actions = useActions(props.field);

  return (
    <ScanWidget
      {...props}
      path={path}
      params={params}
      userFieldId={userFieldId}
      userLabel={userLabel}
      thingFieldId={thingFieldId}
      thingLabel={thingLabel}
      unknownFieldId={unknownFieldId}
      cancelTimeout={cancelTimeout}
      reloadTimeout={reloadTimeout}
      thingAutoCheck={thingAutoCheck}
      size={size}
      actions={actions}
    />
  );
}

function selectSize(props: Props): number {
  if (isNarrowScreen()) {
    return widgetProps<number>(props.field, 'small_size', 20);
  }

  return widgetProps<number>(props.field, 'size', 32);
}

function isNarrowScreen(): boolean {
  return (
    window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches
  );
}
