import React from 'react';
// @ts-ignore
import Barcode from 'react-barcode';
import {TextInputWidget} from './TextWidget';

type Props = {
  value: string;
  onChange: (text?: string) => void;
  size?: number;
  readOnly?: boolean;
};

export function BarcodeWidget(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <BarcodeOutputWidget {...props} />;
  }

  return <BarcodeInputWidget {...props} />;
}

function BarcodeInputWidget(props: Props): JSX.Element {
  return <TextInputWidget {...props} textProps={{}} multiline={false} />;
}

function BarcodeOutputWidget(props: Props): JSX.Element | null {
  if (!props.value) {
    return null;
  }

  return <Barcode value={props.value} displayValue={false} margin={0} />;
}
