import {cancel, onCancel} from './cancel';
import {onClose} from './close';
import {closeOrLoad} from './closeOrLoad';
import {load, onLoad} from './load';
import {onReload, reload} from './reload';

export class UIActions {
  handlers: UIActionHandlerMap;

  constructor(handlers: UIActionHandlerMap) {
    this.handlers = handlers;
  }

  public doAction(holder?: UIActionHolder | null) {
    if (!holder || !holder.action) {
      return;
    }

    switch (holder.action) {
      case 'cancel':
        return cancel(holder, this.handlers.cancel);
      case 'reload':
        return reload(holder, this.handlers.reload);
      case 'load':
        return load(holder, this.handlers.load);
      case 'close_or_load':
        return closeOrLoad(holder, this.handlers.close, this.handlers.load);
    }
  }
}

export type UIActionHolder = {
  action?: UIActionType;
  actionProps?: {[key: string]: any};
};

type UIActionType = 'cancel' | 'reload' | 'load' | 'close_or_load';

type UIActionHandlerMap = {
  cancel?: onCancel;
  close?: onClose;
  load?: onLoad;
  reload?: onReload;
};
