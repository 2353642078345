import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {bodyColorHovered, borderColorLightest, textColor} from '../../styles';
import {isMobile} from '../device';

type Props = {
  numButtons?: number;
  currentPage: number;
  pageSize: number;
  max: number; // 0 origin
  onChange: (index: number) => void;
};

export const Pagination = (props: Props) => {
  function updateIndex(diff: number) {
    const page = props.currentPage + diff;
    setPage(page);
  }

  function setPage(page: number) {
    if (page < 0 || page > props.max) {
      return;
    }

    props.onChange(page);
  }

  return (
    <Container>
      <Start
        onClick={() => {
          setPage(0);
        }}
      />
      <Prev
        onClick={() => {
          updateIndex(-1);
        }}
      />
      <Nums
        max={props.max}
        current={props.currentPage}
        numButtons={props.numButtons}
        onClick={setPage}
      />
      <Next
        onClick={() => {
          updateIndex(+1);
        }}
      />
      <End
        onClick={() => {
          setPage(props.max);
        }}
      />
    </Container>
  );
};

type ButtonProps = {
  onClick: () => void;
};

const Start = (props: ButtonProps) => {
  return <IconButton {...props} iconName={'first_page'} />;
};

const End = (props: ButtonProps) => {
  return <IconButton {...props} iconName={'last_page'} />;
};

const Prev = (props: ButtonProps) => {
  return <IconButton {...props} iconName={'chevron_left'} />;
};

const Next = (props: ButtonProps) => {
  return <IconButton {...props} iconName={'chevron_right'} />;
};

type IconButtonProps = {
  onClick: () => void;
  iconName: string;
};

const IconButton = ({onClick, iconName}: IconButtonProps) => {
  return (
    <Clickable onClick={onClick} style={{fontSize: 24}}>
      <MaterialIcon iconName={iconName} style={{color: textColor}} />
    </Clickable>
  );
};

type NumsProps = {
  numButtons?: number;
  max: number; // 0 origin
  current: number;
  onClick: (page: number) => void;
};

function selectMin(
  current: number,
  max: number,
  numAll: number,
  numLeft: number,
) {
  if (max < numAll) {
    return 0;
  }

  const diff = max - current > numLeft ? numLeft : numAll - 1 - (max - current);
  return Math.max(current - diff, 0);
}

function selectMax(min: number, max: number, numAll: number) {
  if (max < numAll) {
    return max;
  }

  return Math.min(max, min + numAll - 1);
}

const DEFAULT_MAX_NUM_BUTTONS = isMobile() ? 5 : 10;

const Nums = (props: NumsProps) => {
  const numAll = props.numButtons || DEFAULT_MAX_NUM_BUTTONS;
  const numLeft = Math.floor((numAll - 1) / 2);

  const x: ReactElement[] = [];

  const min = selectMin(props.current, props.max, numAll, numLeft);
  const max = selectMax(min, props.max, numAll);

  for (let i = min; i <= max; i++) {
    const key = `page-${i}`;
    const text = (i + 1).toFixed();

    if (i === props.current) {
      x.push(<CurrentNum key={key}>{text}</CurrentNum>);
      continue;
    }

    const onClick = () => {
      props.onClick(i);
    };

    x.push(
      <Clickable key={key} onClick={onClick}>
        {text}
      </Clickable>,
    );
  }

  return <>{x}</>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Part = styled.span`
  display: inline-block;
  box-sizing: border-box;
  font-size: 0.8rem;
  height: 26px;
  min-width: 26px;
  line-height: 26px;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 13px;
  user-select: none;
`;

const Clickable = styled(Part)`
  cursor: pointer;

  &:hover {
    background-color: ${bodyColorHovered};
  }
`;

const CurrentNum = styled(Part)`
  border-color: ${borderColorLightest};
  background-color: ${borderColorLightest};
`;
