import React from 'react';
import {ApiContext} from '../api';
import {RawFormFieldContainer} from '../field/FieldComponent';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Actions} from '../types/Action';
import {Errors} from '../types/Errors';
import {Field} from '../types/Field';
import {
  AttachmentFiles,
  RelatedProxies,
  RelatedResources,
  RelatedSchemas,
} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {Section} from '../types/Section';
import {Values} from './ItemForm';

type Props = {
  sections?: Section[];
  fields: Field[];
} & CommonProps;

export function ItemFormFields(props: Props): JSX.Element | null {
  if (props.sections && props.sections.length > 0) {
    return <ItemFormSections {...props} />;
  }

  return <ItemFormFieldsComponent {...props} />;
}

function ItemFormSections(props: Props): JSX.Element | null {
  return null;
}

function ItemFormFieldsComponent(props: Props): JSX.Element | null {
  const fields = props.fields
    .filter(isVisible)
    .map((field) => (
      <FieldComponent key={`field-${field.id}`} {...props} field={field} />
    ))
    .filter((c) => c !== null);

  return <>{fields}</>;
}

type CommonProps = {
  ctx: ApiContext;
  errors: Errors;
  onChange: OnChangeFieldValue;
  label?: string;
  labelPosition?: string;
  fieldSeparator: boolean;
  values: Values;

  schema: Schema;
  item: ResourceDetails;
  actions: Actions;
  relatedSchemas: RelatedSchemas;
  relatedResources: RelatedResources;
  relatedProxies: RelatedProxies;
  attachmentFiles: AttachmentFiles;
  timestamp: string;
};

type FieldComponentProps = {
  field: Field;
} & CommonProps;

function FieldComponent(props: FieldComponentProps): JSX.Element | null {
  return (
    <RawFormFieldContainer
      {...props}
      value={props.values[props.field.id]}
      messages={props.errors[props.field.id]}
    />
  );
}

function isVisible(field: Field): boolean {
  if (field.widget === 'scanner') {
    return false;
  }

  if (field.widget === 'table') {
    return field.editable;
  }

  return field.editable || field.visible;
}
