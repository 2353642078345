import React from 'react';
import styled from 'styled-components';
import {MaterialIcon} from '../icons/MaterialIcon';
import {bodyColorHovered} from '../styles';
import {DisplayToggle} from '../types/DisplayToggle';
import {getLocalStorageItem, setLocalStorageItem} from './localStorage';
import {CustomLayout} from './CustomLayout';

type Props = {
  area?: string;
  toggle?: DisplayToggle;
  onClick?: (layout: CustomLayout) => void;
} & Partial<CustomLayout>;

export const DisplayFoldSwitch = ({
  area,
  toggle,
  onClick,
  customRows,
  customColumns,
  customComponents,
}: Props) => {
  if (!area || !toggle || !onClick) {
    return null;
  }

  const icon = selectIcon(toggle.direction);

  return (
    <Container
      onClick={() => {
        if (area && toggle && onClick) {
          setLocalStorageItem(foldStateKey, foldStateValue);

          const layout = fold(
            {
              customRows,
              customColumns,
              customComponents,
            },
            area,
            toggle,
          );

          if (layout) {
            onClick(layout);
          }
        }
      }}>
      <MaterialIcon iconName={icon} />
    </Container>
  );
};

export const foldStateKey = 'fold';
const foldStateValue = '1';

export function isFolded(): boolean {
  return getLocalStorageItem(foldStateKey) === foldStateValue;
}

export function fold(
  curr: Partial<CustomLayout>,
  area: string,
  toggle?: DisplayToggle,
): CustomLayout | null {
  if (!toggle) {
    return null;
  }

  const layout: CustomLayout = {
    customRows: {...curr.customRows},
    customColumns: {...curr.customColumns},
    customComponents: {...curr.customComponents},
  };

  if (toggle.rows) {
    layout.customRows[area] = toggle.rows;
  }

  if (toggle.columns) {
    layout.customColumns[area] = toggle.columns;
  }

  layout.customComponents = {
    [area]: {
      type: 'display-unfold-bar',
      area,
      toggle,
    },
  };

  return layout;
}

function selectIcon(direction: string): string {
  switch (direction) {
    case 'left':
      return 'chevron_left';
    case 'right':
      return 'chevron_right';
    case 'up':
      return 'expand_less';
    case 'down':
      return 'expand_more';
  }

  return 'unfold_less';
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;

  &:hover {
    background: ${bodyColorHovered};
  }
`;
