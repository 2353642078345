import React from 'react';
import styled from 'styled-components';
import {textColorError} from '../styles';
import {ErrorReason} from '../types/Errors';

const Container = styled.div`
  color: ${textColorError};
  margin-bottom: 0.2em;
  font-size: 1rem;
`;

type Props = {
  messages?: ErrorReason[];
};

export function ErrorMsg(props: Props) {
  if (!props.messages) {
    return null;
  }

  return (
    <Container>
      {props.messages.map((msg, i) => (
        <Msg key={`msg-${i}`}>{String(msg)}</Msg>
      ))}
    </Container>
  );
}

const Msg = styled.div`
  line-height: 1.5;
  white-space: pre-wrap;
`;
