import {Field} from '../../types/Field';
import {widgetProps} from '../../widgets/common';

export type AntennaProps = {
  antenna: boolean;
  antennaScanSchemaId: string;
  antennaId: string;
  antennaExt?: string;
  antennaUnknownFieldId: string;
};

export function useAntennaProps(field: Field): AntennaProps {
  const antenna = widgetProps<boolean>(field, 'antenna', false);
  const antennaScanSchemaId = widgetProps<string>(
    field,
    'antenna_scan_schema_id',
    'scan',
  );
  const antennaId = widgetProps<string>(field, 'antenna_id', '1');
  const antennaExt = widgetProps<string | undefined>(
    field,
    'antenna_ext',
    undefined,
  );
  const antennaUnknownFieldId = widgetProps<string>(
    field,
    'antenna_unknown_field_id',
    'unknown',
  );

  return {
    antenna,
    antennaScanSchemaId,
    antennaId,
    antennaExt,
    antennaUnknownFieldId,
  };
}
