import * as React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {TextField} from '@fluentui/react';

export function PasswordWidget(props: FieldValueProps): JSX.Element | null {
  if (props.readOnly) {
    return null;
  }

  return (
    <TextField
      type={'password'}
      value={props.value}
      onChange={(_, v) => {
        props.onChange({[props.field.id]: v});
      }}
      autoComplete={'new-password'}
    />
  );
}
