import React from 'react';
import {IntlShape} from 'react-intl';
import styled from 'styled-components';
import {ErrorMsg} from '../common/ErrorMsg';

export function cancelLabel(label: string, intl: IntlShape): string {
  return (
    label ||
    intl.formatMessage({
      id: 'Action.Cancel',
      defaultMessage: 'Cancel',
    })
  );
}

export type MessageProps = {
  error: Error | null;
};

export function ErrorMessage({error}: MessageProps) {
  if (!error) {
    return null;
  }

  const msg = error.message;

  return (
    <ErrorMassageContainer>
      <ErrorMsg messages={[msg]} />
    </ErrorMassageContainer>
  );
}

export const FormContainer = styled.form`
  padding-left: 1rem;
  padding-right: 1rem;
  width: 400px;
`;

export const FormDescription = styled.div`
  margin-bottom: 2rem;
`;

export const ErrorMassageContainer = styled.div`
  margin-top: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const DoneMessage = styled.div`
  margin-bottom: 1rem;
`;
