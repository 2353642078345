import {actionProps, ActionPropsHolder} from './actionProps';

export type onLoad = (schemaId: string, id: string) => void;

export function load(holder: ActionPropsHolder, onLoad?: onLoad) {
  if (!onLoad) {
    return;
  }

  const schemaId = actionProps<string>(holder, 'schemaId', '');
  const id = actionProps<string>(holder, 'id', '');

  if (!schemaId || !id) {
    return;
  }

  onLoad(schemaId, id);
}
