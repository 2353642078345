import {registerIcons} from '@fluentui/react';
import React from 'react';
import {textColorTheme} from '../styles';
import {materialIcons} from './materialIcons';

export function enableCustomIcons() {
  registerIcons({
    icons: {
      ...materialIcons,
      ChevronDown: <i className="material-icons">keyboard_arrow_down</i>,
      CheckMark: <i className="material-icons">check</i>,
      Calendar: <i className="material-icons">event</i>,
      Down: <i className="material-icons">keyboard_arrow_down</i>,
      Up: <i className="material-icons">keyboard_arrow_up</i>,
      SortDown: <i className="material-icons">vertical_align_bottom</i>,
      SortUp: <i className="material-icons">vertical_align_top</i>,
      Cancel: <i className="material-icons">close</i>,
      More: <i className="material-icons">more_horiz</i>,
      Contact: <i className="material-icons">person</i>,
      Info: <i className="material-icons material-icons-outlined">info</i>,
      ErrorBadge: (
        <i className="material-icons material-icons-outlined">cancel</i>
      ),
      'custom-jar': (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
          <g>
            <path
              stroke={`${textColorTheme}`}
              fill={`${textColorTheme}`}
              strokeWidth={1.5}
              d={`M44.3,58.8C53,39.6,63.5,16.5,43.6,9.3V3.8c0-3.4-21.2-3.4-21.2,0v5.5c-19.9,7.2-9.5,30.2-0.8,49.5
c-1.5,0.2-2.7,1.4-2.7,3c0,1.6,1.3,3,3,3h22c1.6,0,3-1.3,3-3C47,60.2,45.8,59,44.3,58.8z M13,25.2c1.4-0.2,2.2-0.8,3-1.3
c2-1.4,3.8-1.4,5.8,0c2.7,1.9,5.4,1.9,8.1,0c2-1.4,3.8-1.4,5.8,0c2.7,1.9,5.4,1.9,8.2,0c2-1.4,3.8-1.4,5.8,0
c0.8,0.6,1.7,1.2,3.2,1.4c0,1.7-0.1,3.5-0.5,5.4c-0.6-0.2-1-0.5-1.6-0.9c-2.7-1.9-5.4-1.9-8.1,0c-2,1.4-3.8,1.4-5.8,0
c-2.7-1.9-5.4-1.9-8.1,0c-2,1.4-3.8,1.4-5.8,0c-2.7-1.9-5.4-1.9-8.2,0c-0.5,0.3-0.9,0.6-1.4,0.8C13.1,28.7,13,26.9,13,25.2z
 M24.4,4.1c2.3-1,14.9-1,17.2,0V9H24.4V4.1z M23.6,11h18.8c6.2,2.1,9.8,5.9,10.5,12.2c-0.8-0.2-1.3-0.5-1.9-0.9
c-2.7-1.9-5.4-1.9-8.1,0c-2,1.4-3.8,1.4-5.8,0c-2.7-1.9-5.4-1.9-8.1,0c-2,1.4-3.8,1.5-5.8,0c-2.7-1.9-5.4-1.9-8.2,0
c-0.6,0.4-1,0.7-1.7,0.9C13.9,16.7,17.6,13,23.6,11z M13.8,32.5c0.9-0.3,1.6-0.7,2.2-1.2c2-1.4,3.8-1.4,5.8,0
c2.7,1.9,5.4,1.9,8.1,0c2-1.4,3.8-1.4,5.8,0c2.7,1.9,5.4,1.9,8.2,0c2-1.4,3.8-1.4,5.8,0c0.6,0.4,1.3,0.9,2.3,1.2
c-1.8,8.4-6.4,18.2-10,26.2H23.8C20.2,50.8,15.7,41,13.8,32.5z M44,62.8H22c-0.5,0-1-0.4-1-1s0.4-1,1-1h22c0.5,0,1,0.4,1,1
S44.5,62.8,44,62.8z`}
            />
          </g>
        </svg>
      ),
    },
  });
}
