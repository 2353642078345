import React from 'react';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {FormValues} from '../types/Form';
import {RelatedResources, RelatedSchemas} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {widgetProps} from '../widgets/common';
import {ItemRadioWidget} from '../widgets/ItemRadioWidget';
import {useItemProps} from './hooks/useItemProps';

type Props = {
  value: any;
  values: FormValues;
  onChange: OnChangeFieldValue;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
  relatedResources: RelatedResources;
  relatedSchemas: RelatedSchemas;
};

export function ItemRadioField(props: Props): JSX.Element | null {
  const itemProps = useItemProps(
    props.field,
    props.item,
    props.relatedSchemas,
    0,
  );

  if (!itemProps) {
    return null;
  }

  const radioFieldId = widgetProps<string>(props.field, 'radio_field_id', '');
  const radioDescription = widgetProps<string>(
    props.field,
    'radio_description',
    '',
  );
  const radioDescriptionView = widgetProps<string>(
    props.field,
    'radio_description_view',
    '',
  );

  const relatedResources = props.relatedResources;

  const onChange = (ids: string[]) => {
    props.onChange({[props.field.id]: ids});
  };

  const onSelect = (id: string) => {
    props.onChange({[radioFieldId]: id});
  };

  const onClear = () => {
    props.onChange({[props.field.id]: [], [radioFieldId]: []});
  };

  const selected: string = String(props.values[radioFieldId]) || '';

  const p = {
    ...itemProps,
    relatedResources,
    radioDescription,
    radioDescriptionView,
    onChange,
    selected,
    onSelect,
    onClear,
  };

  return <ItemRadioWidget {...props} {...p} />;
}
