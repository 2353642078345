import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {usePropsState} from '../hooks/usePropsState';
import {Resource} from '../types/Resource';
import {Schema} from '../types/Schema';
import {
  isItemDetailsMode,
  ItemDetailsContainer,
  ItemDetailsMode,
} from './ItemDetailsContainer';
import {
  isItemFormMode,
  ItemFormContainer,
  ItemFormMode,
} from './ItemFormContainer';

type Props = {
  ctx: ApiContext;
  appId: string;
  id?: string;
  mode: Mode;
  checkedIds?: Set<string>;
  onAfter?: (res: Resource) => void;
};

export type Mode = ItemFormMode | ItemDetailsMode;
export type OnChangeMode = (mode: Mode) => void;

export function Item(props: Props): JSX.Element | null {
  const [id, setId] = usePropsState<string | undefined>(props.id);
  const [mode, setMode] = usePropsState<Mode>(props.mode);
  const onAfter = useOnAfter(setId, setMode, props.onAfter);
  const onCancel = useOnCancel(setMode);
  const schema = useSchema(props.appId);

  if (!schema) {
    return null;
  }

  if (isItemFormMode(mode)) {
    return (
      <Container>
        <ItemFormContainer
          {...props}
          mode={mode}
          onAfter={onAfter}
          onCancel={onCancel}
        />
      </Container>
    );
  }

  if (isItemDetailsMode(mode) && id) {
    return (
      <Container>
        <ItemDetailsContainer
          {...props}
          mode={mode}
          id={id}
          onChangeMode={setMode}
          onAfter={onAfter}
          onCancel={onCancel}
        />
      </Container>
    );
  }

  return null;
}

function useOnAfter(
  setId: (id?: string) => void,
  setMode: (mode: Mode) => void,
  fn?: (res: Resource) => void,
) {
  return useCallback(
    (res?: Resource) => {
      setMode('show');

      if (res) {
        setId(res.id);

        if (fn) {
          fn(res);
        }
      } else {
        setId();
      }
    },
    [fn, setId, setMode],
  );
}

function useOnCancel(setMode: (mode: Mode) => void) {
  return useCallback(() => {
    setMode('show');
  }, [setMode]);
}

function useSchema(appId: string): Schema | null {
  const [schema, setSchema] = useState<Schema | null>(null);

  useEffect(() => {
    (async () => {
      const ctx = api.newContext();
      const s = await api.fetchSchema(ctx, appId, {});
      setSchema(s);
    })();
  }, [appId]);

  return schema;
}

const Container = styled.div``;
