import styled from 'styled-components';
import {Logo} from '../common/Logo';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {Spinner} from '@fluentui/react';
import {api, useApiContext} from '../api';
import {MFA} from '../types/mfa';
import {Settings} from '../components/mfa/Settings';
import {useNav} from '../hooks/useNav';

export function MultiFactorAuth(): JSX.Element | null {
  const {mfa, state, load} = useMFA();
  const {setNav} = useNav();

  useEffect(() => {
    setNav(mfa.nav);
  }, [setNav, mfa.nav]);

  if (state === 'error') {
    return <Layout>error</Layout>;
  }

  if (state === 'loading') {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Settings mfa={mfa} onUpdate={load} />
    </Container>
  );
}

type State = 'loading' | 'loaded' | 'error';

function useMFA(): {mfa: MFA; state: State; load: () => void} {
  const [state, setState] = useState<State>('loading');
  const [mfa, setMFA] = useState<MFA>(empty());

  const ctx = useApiContext();
  const load = useCallback(() => {
    if (!ctx) {
      return;
    }

    (async () => {
      try {
        const mfa = await api.getMFA(ctx);
        setState('loaded');
        setMFA(mfa);
      } catch {
        setState('error');
      }
    })();
  }, [ctx]);

  useEffect(load, [load]);

  return {
    mfa,
    state,
    load,
  };
}

function empty(): MFA {
  return {
    settings: [],
    nav: {
      breadcrumb: [],
      buttons: [],
    },
  };
}

function Layout({children}: {children: ReactNode}): JSX.Element | null {
  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: white;
`;

const LogoContainer = styled.div`
  margin-bottom: 5rem;
`;
