import {FormValue, FormValues} from '../types/Form';
import {Params, ParamValue} from '../types/Params';
import {array} from '../util';

export function isFiltered(
  formValues: FormValues,
  defaultParams?: Params,
): boolean {
  const hasValue = Object.keys(formValues).length > 0;

  if (!hasValue) {
    return false;
  }

  if (!defaultParams) {
    return hasValue;
  }

  // If form values match default params, it is not filtered.
  if (!sameKeys(formValues, defaultParams)) {
    return true;
  }

  for (let [key, val] of Object.entries(defaultParams)) {
    const specified = formValues[key];

    if (!specified || !isSame(specified, val)) {
      return true;
    }
  }

  return false;
}

function sameKeys(f: FormValues, p: Params): boolean {
  return (
    JSON.stringify(Object.keys(f).sort()) ===
    JSON.stringify(Object.keys(p).sort())
  );
}

function isSame(f: FormValue, p: ParamValue): boolean {
  return JSON.stringify(array(f).sort()) === JSON.stringify(array(p).sort());
}
