import React from 'react';
import {widgetProps} from '../widgets/common';
import {TimeInputWidget} from '../widgets/TimeWidget';
import {FilterFieldValueProps} from './FilterFieldValue';

type Props = FilterFieldValueProps;

export const TimeFilterField = (props: Props) => {
  const field = props.field;

  if (field.searchOperator === 'in') {
    return (
      <TimeInputWidget
        value={props.onGetValue(field.id)}
        onChange={(v: string) => {
          props.onChange({[field.id]: v});
        }}
        readOnly={!field.searchable}
      />
    );
  }

  const pf = widgetProps(field, 'search_placeholder_from', '〜から');
  const pt = widgetProps(field, 'search_placeholder_to', '〜まで');

  return (
    <>
      <TimeInputWidget
        value={props.onGetValue('_from_date_' + field.id)}
        onChange={(v: string) => {
          props.onChange({['_from_date_' + field.id]: v});
        }}
        readOnly={false}
        placeholder={pf}
      />
      <TimeInputWidget
        value={props.onGetValue('_to_date_' + field.id)}
        onChange={(v: string) => {
          props.onChange({['_to_date_' + field.id]: v});
        }}
        readOnly={false}
        placeholder={pt}
        style={{marginTop: '0.5rem'}}
      />
    </>
  );
};
