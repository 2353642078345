import {DefaultButton, PrimaryButton} from '@fluentui/react';
import React, {useCallback, useState} from 'react';
import {api, useApiContext} from '../../api';
import {useIntl} from 'react-intl';
import {ApiError} from '../../ApiError';
import {Done} from './Done';
import {
  Buttons,
  Container,
  Desc,
  ErrorMessage,
  Section,
  Title,
} from './Elements';

type Props = {
  mfaType?: string;
  onCancel: () => void;
  onDone: () => void;
};

type Status = 'init' | 'submitting' | 'done' | 'error';

export function DeleteForm(props: Props): JSX.Element | null {
  const ctx = useApiContext();
  const intl = useIntl();
  const [status, setStatus] = useState<Status>('init');
  const [error, setError] = useState<ApiError | null>(null);

  const submit = useCallback(async () => {
    try {
      setStatus('submitting');
      await api.deleteMFA(ctx, {
        mfa_type: props.mfaType,
      });
      setStatus('done');
      props.onDone();
    } catch (e: unknown) {
      if (e instanceof ApiError) {
        setError(e);
        setStatus('error');
      }
    }
  }, [ctx, props.mfaType, props.onDone]);

  if (status === 'done') {
    return (
      <Done
        title={intl.formatMessage({
          id: 'MFA.Delete.Done.Title',
          defaultMessage: 'Done',
        })}
        desc={intl.formatMessage({
          id: 'MFA.Delete.Done.Desc',
          defaultMessage: 'Two-factor authentication disabled.',
        })}
        onClose={props.onCancel}
      />
    );
  }

  return (
    <Container>
      <Section>
        <Title>
          {intl.formatMessage({
            id: 'MFA.Delete.Form.Title',
            defaultMessage: 'Disable MFA',
          })}
        </Title>
        <Desc>
          {intl.formatMessage({
            id: 'MFA.Delete.Form.Desc',
            defaultMessage: 'Disable two-factor authentication.',
          })}
        </Desc>
      </Section>
      <Section>
        <Buttons>
          <PrimaryButton
            text={intl.formatMessage({
              id: 'MFA.Action.Delete.Submit',
              defaultMessage: 'Submit',
            })}
            onClick={submit}
            disabled={status === 'submitting'}
          />
          <DefaultButton
            text={intl.formatMessage({
              id: 'Action.Cancel',
              defaultMessage: 'Cancel',
            })}
            onClick={props.onCancel}
          />
        </Buttons>
      </Section>
      {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </Container>
  );
}
