import {Size} from '../layout/GridLayout';
import {Component} from '../types/Schema';

export type CustomLayout = {
  customRows: {[key: string]: (Size | null)[]};
  customColumns: {[key: string]: (Size | null)[]};
  customComponents: {[key: string]: Component};
};

export function mergeSizes(
  sizes: Size[],
  custom: {[key: string]: (Size | null)[]},
) {
  if (Object.keys(custom).length === 0) {
    return sizes;
  }

  const merged = [...sizes];

  for (let customSizes of Object.values(custom)) {
    for (let i = 0; i < customSizes.length; i++) {
      const customSize = customSizes[i];

      if (customSize) {
        merged[i] = customSize;
      }
    }
  }

  return merged;
}

export function mergeComponents(
  components: {[key: string]: Component},
  custom: {[key: string]: Component},
) {
  if (Object.keys(custom).length === 0) {
    return components;
  }

  const merged: {[key: string]: Component} = {...components};

  for (let [key, comp] of Object.entries(custom)) {
    merged[key] = comp;
  }

  return merged;
}
