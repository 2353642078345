import {Field} from '../types/Field';
import {Resource} from '../types/Resource';
import {Schema} from '../types/Schema';
import {Styles} from '../types/Styles';

type Props = {
  fieldId: string;
  label?: string;
  schema: Schema;
  resource: Resource;
  renderField: RenderField;
  isAvailable: Available;
  labelPosition: string;
  styles?: Styles;
};

export type RenderField = (
  field: Field,
  label?: string,
  labelPosition?: string,
  styles?: Styles,
) => JSX.Element | null;

export type Available = (field: Field) => boolean;

export function FieldSection(props: Props): JSX.Element | null {
  const field = props.schema.fields.find((f) => f.id === props.fieldId);

  if (!field) {
    return null;
  }

  if (!props.isAvailable(field)) {
    return null;
  }

  return props.renderField(
    field,
    props.label,
    props.labelPosition,
    props.styles,
  );
}
