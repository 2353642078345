import * as React from 'react';
import styled from 'styled-components';
import {api, ApiContext, Data} from '../api';
import {isMobile} from '../common/device';
import {DeleteDialog} from '../common/dialogs/DeleteDialog';
import {ItemDetailsDialog} from '../common/dialogs/ItemDetailsDialog';
import {RequestDialog} from '../common/dialogs/RequestDialog';
import {getParamValue} from '../common/params';
import {
  buildPagerProps,
  ResourceDetailsTable,
} from '../common/ResourceDetailsTable';
import {Spinner} from '../common/Spinner';
import {PARAM_KEY_ORDER, PARAM_KEY_SORT} from '../consts';
import {borderColorLightest, textColorDisabled} from '../styles';
import {Actions, ActionTarget} from '../types/Action';
import {FormValues} from '../types/Form';
import {Resource} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Sorter} from '../types/Sorter';
import {TableTool} from '../types/Table';
import {TableColumn} from '../types/TableColumn';
import {TableFilter} from '../types/TableFilter';
import {ResponseMessage} from '../components/RequestForm';

type Props = {
  columns: TableColumn[];
  sorters: Sorter[];
  filters?: TableFilter[];
  onChangeFilter?: (values: FormValues) => void;
  onLoad: () => Promise<ResourceList | null>;
  onAfterRequest: (resp: ResponseMessage) => void;
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];
  timestamp: string;
};

type State = {
  resourceList: ResourceList | null;
  status: 'init' | 'loaded' | 'deleting' | 'requesting_item';
  selected?: SelectedItem;
  focused?: SelectedItem;
};

type SelectedItem = {
  schemaId: string;
  resId: string;
};

export class ReadOnlyTableWidget extends React.Component<Props, State> {
  private readonly ctx: ApiContext;

  constructor(props: Props) {
    super(props);

    this.ctx = api.newContext();

    this.state = {
      resourceList: null,
      status: 'init',
    };
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount(): void {
    this.ctx.abort();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (this.props.timestamp !== prevProps.timestamp) {
      this.load();
    }
  }

  onSelect = (item: ResourceDetails) => {
    const appId = item.schema_id || this.state.resourceList!.schema.id;
    const selected = {schemaId: appId, resId: item.id};

    if (isMobile()) {
      window.open(`/app/${appId}/${item.id}`);
      this.setState({selected});
      return;
    }

    this.setState({selected});
  };

  onCloseDialog = async () => {
    this.setState({selected: undefined});
  };

  load = async () => {
    const resourceList = await this.props.onLoad();
    this.setState({resourceList});
  };

  onShow = async (resource: Resource) => {
    window.open(`/app/${resource.schema.id}/${resource.id}`);
  };

  onDelete = async (target: ActionTarget) => {
    this.setState({
      status: 'deleting',
      focused: target,
    });
  };

  onRequest = async (target: ActionTarget) => {
    this.setState({
      status: 'requesting_item',
      focused: target,
    });
  };

  onRequestImmediately = async (target: ActionTarget, data?: Data) => {
    api.request(this.ctx, target.schemaId, target.resId, data);
  };

  resetStatus = () => {
    this.setState({status: 'loaded'});
  };

  buildActions(): Actions {
    return {
      delete: (args) => this.onDelete(args.target),
      request: (args) => this.onRequest(args.target),
      request_immediately: (args) =>
        this.onRequestImmediately(args.target, args.data),
    };
  }

  renderDeleteDialog() {
    if (!this.state.focused) {
      return null;
    }

    return (
      <DeleteDialog
        ctx={this.ctx}
        shown={this.state.status === 'deleting'}
        appId={this.state.focused!.schemaId}
        resId={this.state.focused!.resId}
        onAfter={(item) => {
          this.load();
        }}
        onClose={this.resetStatus}
      />
    );
  }

  render() {
    if (!this.props.columns) {
      return null;
    }

    if (!this.state.resourceList) {
      return <Spinner />;
    }

    if (this.state.resourceList.list.length === 0) {
      return (
        <Container>
          <EmptyMessage>データがありません</EmptyMessage>
        </Container>
      );
    }

    const pagerProps = buildPagerProps(
      this.ctx,
      (resourceList: ResourceList) => {
        this.setState({resourceList});
      },
      this.state.resourceList,
    );

    return (
      <>
        <Container>
          <ResourceDetailsTable
            {...this.props}
            resourceList={this.state.resourceList}
            onSelect={this.onSelect}
            expandLastColumn={false}
            selectedIds={[this.state.selected?.resId || '']}
            currentSortOrder={{
              sort: getParamValue(
                this.state.resourceList.params,
                PARAM_KEY_SORT,
              ),
              order: getParamValue(
                this.state.resourceList.params,
                PARAM_KEY_ORDER,
              ),
            }}
            actions={this.buildActions()}
            {...pagerProps}
          />
        </Container>
        <ItemDetailsDialog
          ctx={this.ctx}
          shown={!!this.state.selected}
          schemaId={this.state.selected?.schemaId || ''}
          resId={this.state.selected?.resId}
          onClose={this.onCloseDialog}
          actions={this.buildActions()}
        />
        {this.renderDeleteDialog()}
        <RequestDialog
          ctx={this.ctx}
          shown={this.state.status === 'requesting_item'}
          target={this.state.focused}
          onAfterRequest={this.props.onAfterRequest}
          onClose={this.resetStatus}
          actions={this.buildActions()}
        />
      </>
    );
  }
}

const Container = styled.div`
  position: relative;
  height: 100%;
  width: calc(100% - 10px);
  border: 5px solid ${borderColorLightest};
  max-height: 500px;
  overflow: auto;
`;

const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-size: 1rem;
  box-sizing: border-box;
  color: ${textColorDisabled};
`;
