import React from 'react';
import styled from 'styled-components';
import {borderColorLightest} from '../../styles';

export type TotalCountProps = {
  count: number;
};

export const Container = styled.div`
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-color: ${borderColorLightest};
  background-color: ${borderColorLightest};
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  text-align: center;
  white-space: nowrap;
`;

export function TotalCounter(props: TotalCountProps) {
  return <Container>{props.count} 件</Container>;
}
