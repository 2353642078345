import {useQRCode} from 'next-qrcode';
import React from 'react';
import {TextInputWidget} from './TextWidget';

type Props = {
  value: string;
  onChange: (text?: string) => void;
  size?: number;
  readOnly?: boolean;
};

export function QRCodeWidget(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <QRCodeOutputWidget {...props} />;
  }

  return <QRCodeInputWidget {...props} />;
}

function QRCodeInputWidget(props: Props): JSX.Element {
  return <TextInputWidget {...props} textProps={{}} multiline={false} />;
}

function QRCodeOutputWidget(props: Props): JSX.Element | null {
  const elem = useQRCode().Canvas({
    text: props.value,
    options: {
      level: 'medium',
      margin: 0,
      scale: 1,
      width: props.size || 128,
    },
  });

  if (!props.value) {
    return null;
  }

  return elem;
}
