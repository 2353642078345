import {ResponseDialog} from './types/ResponseDialog';

export class ApiError {
  readonly status: number;
  readonly statusText: string;
  readonly body: any;

  constructor(response: Response, body: any) {
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = body;
  }

  getMessageMap(): {[key: string]: string[]} {
    if (this.body && this.body.errors) {
      return this.body.errors;
    }

    return {};
  }

  getDialog(): ResponseDialog | null {
    if (this.body && this.body.dialog) {
      return this.body.dialog as ResponseDialog;
    }

    return null;
  }

  getMessages(): string[] {
    if (this.body && this.body.errors) {
      let messages = [] as string[];

      Object.keys(this.body.errors).forEach((key) => {
        messages = messages.concat(this.body.errors[key]);
      });

      return messages;
    }

    return [] as string[];
  }

  getMessage(): string {
    return this.getMessages().join('\n');
  }

  get message(): string {
    return this.getMessage();
  }
}
