import React from 'react';
import styled from 'styled-components';
import {Resource} from '../types/Resource';
import {Schema} from '../types/Schema';
import {Section} from '../types/Section';
import {Styles} from '../types/Styles';
import {Available, RenderField} from './FieldSection';
import {isAvailable, Sections} from './Sections';

type Props = {
  direction: string;
  schema: Schema;
  resource: Resource;
  sections?: Section[];
  renderField: RenderField;
  isAvailable: Available;
  styles?: Styles;
};

const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
`;

export function FlexSection(props: Props): JSX.Element | null {
  if (!isAvailable(props)) {
    return null;
  }

  if (props.direction === 'row') {
    return (
      <RowFlexContainer style={props.styles?.container}>
        <Sections {...props} />
      </RowFlexContainer>
    );
  }

  return (
    <ColumnFlexContainer style={props.styles?.container}>
      <Sections {...props} />
    </ColumnFlexContainer>
  );
}
