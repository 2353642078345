import {ResourceDetails} from '../types/ResourceDetails';

export function getDisplayName(
  d: ResourceDetails,
  nameFieldId?: string,
): string {
  if (nameFieldId) {
    return d[nameFieldId] ?? displayName(d);
  }

  return displayName(d);
}

function displayName(d: ResourceDetails): string {
  return d.__name__ ?? d.name ?? '';
}
