import React, {CSSProperties, useContext, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {Dialog} from '../common/Dialog';
import {Logo} from '../common/Logo';
import {newDummyNav} from '../common/Nav';
import {Spinner} from '../common/Spinner';
import {MarkdownMessage} from '../components/MarkdownMessage';
import {SearchBox} from '../components/SearchBox';
import {NavContext, NavProps} from '../context';
import {MaterialIcon} from '../icons/MaterialIcon';
import {
  bodyColor,
  bodyColorHovered,
  borderColorMiddle,
  textColor,
  textColorTheme,
} from '../styles';
import {MenuItem} from '../types/HomeMenu';
import './Home.css';

type HomeProps = {
  minWidth: string;
  maxWidth: string;
};

type Props = HomeProps & {
  setNav: (nav: NavProps) => void;
};

type State = {
  menuItems: MenuItem[];
  showFolder: boolean;
  folder?: MenuItem;
};

export class HomeComponent extends React.Component<Props, State> {
  static defaultProps = {
    minWidth: '400px',
    maxWidth: '800px',
  };

  private ctx: ApiContext;

  constructor(props: Props) {
    super(props);
    this.ctx = api.newContext();

    this.state = {
      menuItems: [],
      showFolder: false,
    };
  }

  componentDidMount(): void {
    this.load();
    this.props.setNav(newDummyNav());
  }

  componentWillUnmount() {
    this.ctx.abort();
  }

  load = async () => {
    try {
      const home = await api.listHomeMenu(this.ctx);

      this.setState({menuItems: home.menu});
      this.props.setNav(home.nav);
    } catch (err) {}
  };

  showFolder = (link: MenuItem): void => {
    this.setState({
      showFolder: true,
      folder: link,
    });
  };

  closeFolder = (): void => {
    this.setState({
      showFolder: false,
      folder: undefined,
    });
  };

  makeStyle(): CSSProperties {
    return {
      minWidth: this.props.minWidth,
      maxWidth: this.props.maxWidth,
    };
  }

  renderMenuItem = (link: MenuItem, index: number) => {
    const iconName = link.icon || 'help_outline';

    return (
      <MenuLink key={`menu-${index}`} href={link.url}>
        <IconContainer>
          <MaterialIcon iconName={iconName} className={'eapp-home-icon'} />
        </IconContainer>
        <MenuLabel>{link.name}</MenuLabel>
      </MenuLink>
    );
  };

  renderMenuFolder = (link: MenuItem, index: number) => {
    return (
      <MenuFolder
        key={`menu-${index}`}
        link={link}
        onPress={() => {
          this.showFolder(link);
        }}
      />
    );
  };

  renderMenuCategory = (link: MenuItem, index: number) => {
    if (!link.links || link.links.length === 0) {
      return null;
    }

    return (
      <MenuCategoryContainer key={`menu-${index}`}>
        <MenuCategory>{link.name}</MenuCategory>
        <SubMenuContainer>
          {link.links.map(this.renderMenuEntry)}
        </SubMenuContainer>
      </MenuCategoryContainer>
    );
  };

  renderMenuEntry = (link: MenuItem, index: number) => {
    if (link.type === 'markdown') {
      return this.renderMarkdown(link, index);
    }

    if (link.type === 'search') {
      return this.renderSearch(link, index);
    }

    if (link.url) {
      return this.renderMenuItem(link, index);
    }

    if (!link.isExpanded) {
      return this.renderMenuFolder(link, index);
    }

    return this.renderMenuCategory(link, index);
  };

  renderMarkdown(link: MenuItem, index: number) {
    return (
      <MarkdownContainer key={`menu-${index}`}>
        <MarkdownMessage text={link.text} styles={link.styles} />
      </MarkdownContainer>
    );
  }

  renderSearch(link: MenuItem, index: number) {
    return (
      <SearchBoxContainer key={`menu-${index}`}>
        <SearchBox
          onSearch={(query) => {
            window.location.href = link.url + query;
          }}
        />
      </SearchBoxContainer>
    );
  }

  renderMenu() {
    if (this.state.menuItems.length === 0) {
      return null;
    }

    return (
      <>
        <div>{this.state.menuItems.map(this.renderMenuEntry)}</div>
        <MenuFolderDialog
          shown={this.state.showFolder}
          link={this.state.folder}
          renderBody={(link) => {
            return (
              <MenuLinks>
                {link.links.map((child, i) => {
                  return this.renderMenuEntry(child, i);
                })}
              </MenuLinks>
            );
          }}
          onClose={this.closeFolder}
        />
      </>
    );
  }

  render() {
    if (!this.state.menuItems) {
      return <Spinner />;
    }

    const style = this.makeStyle();

    return (
      <Wrapper>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <MenuContainer style={style}>{this.renderMenu()}</MenuContainer>
      </Wrapper>
    );
  }
}

export function Home(props: Partial<HomeProps>): JSX.Element {
  const {setNav} = useContext(NavContext);
  return <HomeComponent {...props} setNav={setNav} />;
}

type MenuFolderProps = {
  onPress: () => void;
  link: MenuItem;
};

function MenuFolder(props: MenuFolderProps): JSX.Element | null {
  const iconName = props.link.icon || 'folder_open';

  return (
    <MenuLink onClick={props.onPress}>
      <IconContainer>
        <MaterialIcon iconName={iconName} className={'eapp-home-icon'} />
      </IconContainer>
      <MenuLabel>{props.link.name}</MenuLabel>
    </MenuLink>
  );
}

type MenuFolderDialogProps = {
  shown: boolean;
  link?: MenuItem;
  onClose: () => void;
  renderBody: (link: MenuItem) => JSX.Element | null;
};

function MenuFolderDialog(props: MenuFolderDialogProps): JSX.Element | null {
  const ref = useRef<Dialog<MenuItem>>(null);

  useEffect(() => {
    if (props.shown && ref && ref.current && props.link !== undefined) {
      ref.current.showDialog(props.link);
    }
  }, [props.shown, props.link, ref]);

  if (!props.link) {
    return null;
  }

  return (
    <Dialog<MenuItem>
      ref={ref}
      onClose={props.onClose}
      title={props.link.name}
      renderBody={() => props.renderBody(props.link!)}
    />
  );
}

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${bodyColor};
  padding: 1rem;
`;

const MenuContainer = styled.div`
  margin-top: 1em;
  text-align: left;
`;

const MenuCategoryContainer = styled.div``;

const MenuCategory = styled.div`
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  padding: 2px 5px;
  border-bottom: 1px solid ${borderColorMiddle};
  color: ${textColor};
`;

const MenuLink = styled.a`
  box-sizing: border-box;
  margin-right: 1em;
  width: 100px;
  height: 100px;
  padding: 15px 5px 5px;
  color: ${textColorTheme};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  background-color: ${bodyColor};

  &:hover {
    background-color: ${bodyColorHovered};
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  height: 35px;
`;

const MenuLabel = styled.div`
  margin-top: 5px;
  white-space: pre;
  font-size: 0.9rem;
  height: 30px;
`;

const MenuLinks = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const SearchBoxContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const MarkdownContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SubMenuContainer = styled.div`
  padding-left: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  & ${MenuCategory} {
    border-bottom-style: dashed;
    margin-top: 0.5em;
    font-size: 0.9rem;
  }

  & ${MenuCategoryContainer} {
    flex-basis: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 3rem;
`;
