import React from 'react';
import styled from 'styled-components';
import {COMMAND_BAR_HEIGHT} from '../consts';
import {MaterialIcon} from '../icons/MaterialIcon';
import {
  bodyColorError,
  bodyColorTheme,
  textColorError,
  textColorInverse,
} from '../styles';

type PrimaryHeaderContainerType = {
  type?: PrimaryHeaderType;
};

type PrimaryHeaderType = 'norma' | 'error';

type Props = {
  type?: PrimaryHeaderType;
  iconName: string;
  text: string;
};

export function PrimaryHeader(props: Props) {
  return (
    <PrimaryHeaderContainer type={props.type}>
      <MaterialIcon iconName={props.iconName} />
      <Label>{props.text}</Label>
    </PrimaryHeaderContainer>
  );
}

const PrimaryHeaderContainer = styled.div<PrimaryHeaderContainerType>`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: ${COMMAND_BAR_HEIGHT}px;
  background-color: ${(props: PrimaryHeaderContainerType) =>
    props.type === 'error' ? bodyColorError : bodyColorTheme};
  color: ${(props: PrimaryHeaderContainerType) =>
    props.type === 'error' ? textColorError : textColorInverse};

  & .material-icons {
    font-size: 24px;
  }
`;

const Label = styled.div`
  margin-left: 0.5rem;
`;
