import {ScrollablePane} from '@fluentui/react';
import React, {CSSProperties, RefObject} from 'react';
import {Styles} from '../types/Styles';
import {GridArea, GridAreaContainer} from './GridArea';
import {GridSizeDetector} from './GridSizeDetector';
import {HorizontalHandle} from './HorizontalHandle';
import {VerticalHandle} from './VerticalHandle';

export type Size = {
  size: string;
  min?: number;
  max?: number;
};

export type GridProps = {
  rows: Size[];
  columns: Size[];
  areas: AreaRow[];
};

export type AreaRow = string[];

type Props = GridProps & {
  components: {[key: string]: JSX.Element | null};
  styles?: Styles;
};

type State = {
  rows: Size[];
  columns: Size[];
  areas: AreaRow[];
  areaKeys: string[];
};

export type HandleProps = {
  area: string;
};

export type Resize = (sizes: Size[]) => void;

const handleRadius = 5;
const handlePrefix = '_handle_';
const verticalHandlePrefix = handlePrefix + 'v_';
const horizontalHandlePrefix = handlePrefix + 'h_';

export const clearSelection = () => {
  const sel = window.getSelection();

  if (sel) {
    sel.empty();
    sel.removeAllRanges();
  }
};

export class GridLayout extends React.Component<Props, State> {
  private readonly sizeDetector: RefObject<GridSizeDetector>;

  constructor(props: Props) {
    super(props);

    this.sizeDetector = React.createRef();
    this.state = buildState(props);
  }

  static getDerivedStateFromProps(nextProps: Readonly<Props>) {
    return buildState(nextProps);
  }

  extractRowIndex(area: string): number {
    const index = area.replace(horizontalHandlePrefix, '');
    return parseInt(index, 10);
  }

  extractColumnIndex(area: string): number {
    const index = area.replace(verticalHandlePrefix, '');
    return parseInt(index, 10);
  }

  setColumns = (columns: Size[]) => {
    this.setState({columns});
  };

  setRows = (rows: Size[]) => {
    this.setState({rows});
  };

  getStyle(area: string): CSSProperties | undefined {
    if (!this.props.styles) {
      return;
    }

    return this.props.styles[area];
  }

  render() {
    return (
      <div style={{position: 'relative', height: '100%'}}>
        <GridSizeDetector
          ref={this.sizeDetector}
          rows={this.state.rows}
          columns={this.state.columns}
          areas={this.state.areas}
        />
        <GridAreaContainer
          rows={this.state.rows}
          columns={this.state.columns}
          areas={this.state.areas}>
          {this.state.areaKeys.map((area) => {
            if (area.startsWith(verticalHandlePrefix)) {
              return (
                <VerticalHandle
                  handleRadius={handleRadius}
                  key={area}
                  area={area}
                  columns={this.state.columns}
                  columnIndex={this.extractColumnIndex(area)}
                  setColumns={this.setColumns}
                  sizeDetector={this.sizeDetector}
                />
              );
            }

            if (area.startsWith(horizontalHandlePrefix)) {
              return (
                <HorizontalHandle
                  handleRadius={handleRadius}
                  key={area}
                  area={area}
                  rows={this.state.rows}
                  rowIndex={this.extractRowIndex(area)}
                  setRows={this.setRows}
                  sizeDetector={this.sizeDetector}
                />
              );
            }

            return (
              <GridArea area={area} key={area} style={this.getStyle(area)}>
                <ScrollablePane>{this.props.components[area]}</ScrollablePane>
              </GridArea>
            );
          })}
        </GridAreaContainer>
      </div>
    );
  }
}

const buildState = (props: Props): State => {
  const {areas, columns, rows} = injectHandles(props);
  const areaKeys = collectAreaKeys(areas);

  return {
    areas,
    areaKeys,
    rows,
    columns,
  };
};

const hasLimit = (s1: Size, s2?: Size) => {
  if (!s2) {
    return false;
  }

  return (
    (hasOneLimit(s1) && hasOneLimit(s2)) || hasBothLimit(s1) || hasBothLimit(s2)
  );
};

const hasOneLimit = (s: Size) => {
  if (!s) {
    return false;
  }

  return (s.max !== undefined || s.min !== undefined) && s.max !== s.min;
};

const hasBothLimit = (s: Size) => {
  if (!s) {
    return false;
  }

  return s.max !== undefined && s.min !== undefined && s.max !== s.min;
};

const injectHandles = (props: GridProps): GridProps => {
  const result1 = injectHorizontalHandles(props.areas, props.rows);
  const result2 = injectVerticalHandles(result1.areas, props.columns);

  return {
    areas: result2.areas,
    columns: result2.columns,
    rows: result1.rows,
  };
};

const injectHorizontalHandles = (areas: AreaRow[], rows: Size[]) => {
  const resultAreas: AreaRow[] = [];
  const {sizes: resultRows, injectedIndexes: rowToInject} =
    injectHandleSize(rows);

  for (let i = 0; i < areas.length; i++) {
    resultAreas.push(areas[i]);

    const handleIndex = rowToInject.indexOf(i);

    if (handleIndex < 0) {
      continue;
    }

    const areaRow: AreaRow = [];

    for (let j = 0; j < areas[i].length; j++) {
      if (areas[i][j] === areas[i + 1][j]) {
        areaRow.push(areas[i][j]);
        continue;
      }

      areaRow.push(`${horizontalHandlePrefix}${i + handleIndex + 1}`);
    }

    resultAreas.push(areaRow);
  }

  return {
    areas: resultAreas,
    rows: resultRows,
  };
};

const injectVerticalHandles = (areas: AreaRow[], columns: Size[]) => {
  const resultAreas: AreaRow[] = [];

  const {sizes: resultColumns, injectedIndexes: colToInject} =
    injectHandleSize(columns);

  for (let i = 0; i < areas.length; i++) {
    const areaRow: AreaRow = [];

    for (let j = 0; j < areas[i].length; j++) {
      areaRow.push(areas[i][j]);

      const handleIndex = colToInject.indexOf(j);

      if (handleIndex < 0) {
        continue;
      }

      if (areas[i][j + 1] && areas[i][j + 1] !== areas[i][j]) {
        areaRow.push(`${verticalHandlePrefix}${j + handleIndex + 1}`);
        continue;
      }

      areaRow.push(areas[i][j]);
    }

    resultAreas.push(areaRow);
  }

  return {
    areas: resultAreas,
    columns: resultColumns,
  };
};

const injectHandleSize = (sizeList: Size[]) => {
  const sizes: Size[] = [];
  const injectedIndexes = new Set<number>();

  for (let i = 0; i < sizeList.length; i++) {
    sizes.push(sizeList[i]);

    if (hasLimit(sizeList[i], sizeList[i + 1])) {
      sizes.push({
        size: '1px',
      });
      injectedIndexes.add(i);
    }
  }

  return {
    sizes,
    injectedIndexes: Array.from(injectedIndexes).sort(),
  };
};

const collectAreaKeys = (areas: string[][]): string[] => {
  const flatAreas = areas.flat(1);
  return Array.from(new Set(flatAreas));
};
