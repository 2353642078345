import {PARAM_KEY_CHECKED_IDS} from '../../consts';
import {Params, ParamValue} from '../../types/Params';

export function buildDataForNew(
  params: Params | undefined,
  checkedIds: Set<string>,
): {[key: string]: ParamValue} {
  const data: {[key: string]: ParamValue} = {
    ...params,
  };

  if (checkedIds.size > 0) {
    data[PARAM_KEY_CHECKED_IDS] = [...checkedIds];
  }

  return data;
}
