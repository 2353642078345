export const MAIN_HEADER_HEIGHT = 44;
export const COMMAND_BAR_HEIGHT = 44;
export const MENU_WIDTH = 270;

export const PARAM_KEY_SORT = '_sort';
export const PARAM_KEY_ORDER = '_order';
export const PARAM_KEY_PAGE = '_page';
export const PARAM_KEY_SIZE = '_size';
export const PARAM_KEY_CHECKED_IDS = '_checked_ids';

export const PARAM_KEY_SELECTOR = '_selector';
export const PARAM_KEY_FORCE = '_force';

export const PARAM_KEY_START_DATE = '_start_date';
export const PARAM_KEY_END_DATE = '_end_date';
