import * as React from 'react';
import {CSSProperties} from 'react';
import styled, {StyledComponent} from 'styled-components';
import {Field} from '../../types/Field';
import {InfoProps} from '../../types/Info';
import {Sorter} from '../../types/Sorter';
import {SortOrder} from '../../types/SortOrder';
import {TableTool, TableToolType} from '../../types/Table';
import {TableColumn} from '../../types/TableColumn';
import {ColumnSelector} from './ColumnSelector';
import {Info} from './Info';
import {PageSizeChanger} from './PageSizeChanger';
import {Pagination} from './Pagination';
import {SorterChanger} from './SorterChanger';
import {TotalCounter} from './TotalCounter';

export type TableToolsSwitch = {
  pagination?: boolean;
  numPaginationButtons?: number;
  counter?: boolean;
  pageSizeChanger?: boolean;
  columnSelector?: boolean;
  sorterChanger?: boolean;
  info?: boolean;
};

export type TableToolbarProps = {
  tools?: TableToolsSwitch;
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];

  count: number;
  currentPage: number;
  pageSize: number;
  maxPage: number;
  onChangePageSize: (size: number) => void;
  onChangeCurrentPage: (index: number) => void;

  allColumns?: TableColumn[];
  currentColumns?: TableColumn[];
  fields?: Field[];
  onChangeColumns?: (columns: TableColumn[]) => void;

  currentSortOrder?: SortOrder;
  sorters?: Sorter[];
  onChangeSorter: (id: string, current: SortOrder) => void;

  info?: InfoProps;
};

type ToolMap = {
  [key in TableToolType]: (props: Props, tool: TableTool) => JSX.Element | null;
};

type Props = TableToolbarProps & {
  style?: CSSProperties;
};

export function needTableTools(props: Props): boolean {
  return (
    needPagination(props) ||
    needTotalCount(props) ||
    needColumnSelector(props) ||
    needPageSizeChanger(props) ||
    needSortChanger(props) ||
    needInfo(props)
  );
}

export function TableTools(props: Props) {
  if (!needTableTools(props)) {
    return null;
  }

  const map: ToolMap = {
    counter: renderTotalCount,
    pagination: renderPagination,
    'column-selector': renderColumnSelector,
    'page-size-changer': renderPageSizeChanger,
    'sorter-changer': renderSortChanger,
    info: renderInfo,
  };

  if (props.tableTools || props.tableFarTools) {
    return (
      <Container style={props.style}>
        {renderTools(props, Tool, map, props.tableTools)}
        {renderTools(props, FarTool, map, props.tableFarTools)}
      </Container>
    );
  }

  return (
    <Container style={props.style}>
      {renderTools(props, Tool, map, [{type: 'counter'}, {type: 'pagination'}])}
      {renderTools(props, Tool, map, [
        {type: 'page-size-changer'},
        {type: 'column-selector'},
        {type: 'sorter-changer'},
      ])}
    </Container>
  );
}

function renderTools(
  props: Props,
  Elem: StyledComponent<'div', any>,
  map: ToolMap,
  list?: TableTool[],
) {
  const filtered = (list || []).filter((t) => !!map[t.type]);

  if (filtered.length === 0) {
    return null;
  }

  return (
    <Tools>
      {filtered.map((t, i) => (
        <Elem key={`table-tool-${t.type}-${i}`}>{map[t.type](props, t)}</Elem>
      ))}
    </Tools>
  );
}

function needPagination(props: TableToolbarProps): boolean {
  if (props.tools && !props.tools.pagination) {
    return false;
  }

  return props.maxPage >= 1;
}

function renderPagination(props: TableToolbarProps, tableTool: TableTool) {
  if (!needPagination(props)) {
    return null;
  }

  const num = selectNumPaginationButtons(props, tableTool);

  return (
    <Pagination
      numButtons={num}
      currentPage={props.currentPage}
      onChange={props.onChangeCurrentPage}
      max={props.maxPage}
      pageSize={props.count}
    />
  );
}

function selectNumPaginationButtons(
  props: TableToolbarProps,
  tableTool: TableTool,
): number | undefined {
  if (tableTool.numButtons && tableTool.numButtons > 0) {
    return tableTool.numButtons;
  }

  if (props.tools) {
    return props.tools.numPaginationButtons;
  }

  return;
}

function needTotalCount(props: TableToolbarProps): boolean {
  return !(props.tools && !props.tools.counter);
}

function renderTotalCount(props: TableToolbarProps) {
  if (!needTotalCount(props)) {
    return null;
  }

  return <TotalCounter {...props} />;
}

function needColumnSelector(props: TableToolbarProps): boolean {
  if (props.tools && !props.tools.columnSelector) {
    return false;
  }

  if (
    !props.allColumns ||
    !props.currentColumns ||
    !props.fields ||
    !props.onChangeColumns
  ) {
    return false;
  }

  // we don't need column selector when only one column exists
  return props.allColumns.length !== 1;
}

function renderColumnSelector(props: TableToolbarProps) {
  if (!needColumnSelector(props)) {
    return null;
  }

  return (
    <ColumnSelector
      allColumns={props.allColumns!}
      currentColumns={props.currentColumns!}
      fields={props.fields!}
      onChange={props.onChangeColumns!}
    />
  );
}

function needPageSizeChanger(props: TableToolbarProps): boolean {
  return !(props.tools && !props.tools.pageSizeChanger);
}

function renderPageSizeChanger(props: TableToolbarProps) {
  if (!needPageSizeChanger(props)) {
    return null;
  }

  return (
    <PageSizeChanger
      size={props.pageSize}
      count={props.count}
      onChange={props.onChangePageSize}
    />
  );
}

function needSortChanger(props: TableToolbarProps): boolean {
  if (props.tools && !props.tools.sorterChanger) {
    return false;
  }

  return !!(props.sorters && props.currentSortOrder);
}

function renderSortChanger(props: TableToolbarProps) {
  if (!needSortChanger(props)) {
    return null;
  }

  return (
    <SorterChanger
      onChange={props.onChangeSorter}
      sorters={props.sorters!}
      currentSortOrder={props.currentSortOrder!}
    />
  );
}

function needInfo(props: TableToolbarProps): boolean {
  if (props.tools && !props.tools.info) {
    return false;
  }

  return !!props.info;
}

function renderInfo(props: TableToolbarProps, tool: TableTool) {
  if (!needInfo(props)) {
    return null;
  }

  if (!tool.key) {
    return null;
  }

  return <Info info={props.info!} infoKey={tool.key} />;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.3rem;
  box-sizing: border-box;
`;

const Tool = styled.div`
  margin-right: 0.5rem;

  &:first-child {
    margin-left: 0.3rem;
  }
`;

const FarTool = styled.div`
  margin-left: 0.5rem;

  &:last-child {
    margin-right: 0.3rem;
  }
`;

const Tools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.3rem;
`;
