import React from 'react';
import {widgetProps} from '../widgets/common';
import {DateWidget} from '../widgets/DateWidget';
import {FilterFieldValueProps} from './FilterFieldValue';
import {useIntl} from 'react-intl';

type Props = FilterFieldValueProps;

export const DateFilterField = (props: Props) => {
  const intl = useIntl();
  const field = props.field;

  if (field.searchOperator === 'in') {
    return (
      <DateWidget
        value={props.onGetValue(field.id)}
        onChange={(v: string) => {
          props.onChange({[field.id]: v});
        }}
        readOnly={!field.searchable}
      />
    );
  }

  const pf = widgetProps(
    field,
    'search_placeholder_from',
    intl.formatMessage({id: 'Field.DateFilter.From', defaultMessage: 'From'}),
  );
  const pt = widgetProps(
    field,
    'search_placeholder_to',
    intl.formatMessage({id: 'Field.DateFilter.To', defaultMessage: 'To'}),
  );

  return (
    <>
      <DateWidget
        value={props.onGetValue('_from_date_' + field.id)}
        onChange={(v: string) => {
          props.onChange({['_from_date_' + field.id]: v});
        }}
        readOnly={false}
        placeholder={pf}
      />
      <DateWidget
        value={props.onGetValue('_to_date_' + field.id)}
        onChange={(v: string) => {
          props.onChange({['_to_date_' + field.id]: v});
        }}
        readOnly={false}
        placeholder={pt}
        style={{marginTop: '0.5rem'}}
      />
    </>
  );
};
