import {getTimeZone, NAME_TIME_ZONE} from './timezone';
import {Errors} from './types/Errors';
import {Params} from './types/Params';

export function array(v: any) {
  if (Array.isArray(v)) {
    return v;
  }

  if (v === undefined || v === null) {
    return [];
  }

  return [v];
}

export function add(vs: any[], v: any): any[] {
  return vs.concat(v);
}

export function del(vs: any[], v: any): any[] {
  return vs.filter((x) => x !== v);
}

export function unique(vs: any[]): any[] {
  return [...new Set([...vs])];
}

export function newErrors(msg: string): Errors {
  return {_: [msg]};
}

export function downloadByPost(url: string, params: Params) {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);

  const tz = document.createElement('input');
  tz.setAttribute('type', 'hidden');
  tz.setAttribute('name', NAME_TIME_ZONE);
  tz.setAttribute('value', getTimeZone());
  form.appendChild(tz);

  const json = document.createElement('input');
  json.setAttribute('type', 'hidden');
  json.setAttribute('name', 'json');
  json.setAttribute('value', JSON.stringify(params));
  form.appendChild(json);
  document.body.appendChild(form);

  form.submit();

  document.body.removeChild(form);
}

export function isContentOnly() {
  return (
    new URLSearchParams(window.location.search).get('layout') === 'content-only'
  );
}
