import React from 'react';
import {Resource} from '../types/Resource';
import {Schema} from '../types/Schema';
import {Section} from '../types/Section';
import {Available, FieldSection, RenderField} from './FieldSection';
import {FlexSection} from './FlexSection';
import {FrameSection} from './FrameSection';

type Props = {
  schema: Schema;
  resource: Resource;
  sections?: Section[];
  renderField: RenderField;
  isAvailable: Available;
};

type CheckProps = {
  resource: Resource;
  sections?: Section[];
  isAvailable: Available;
};

export function Sections(props: Props): JSX.Element | null {
  if (!props.sections) {
    return null;
  }

  if (!isAvailable(props)) {
    return null;
  }

  return (
    <>
      {renderSections(
        props.sections,
        props.schema,
        props.resource,
        props.renderField,
        props.isAvailable,
      )}
    </>
  );
}

function renderSections(
  sections: Section[],
  schema: Schema,
  resource: Resource,
  renderField: RenderField,
  isAvailable: Available,
) {
  return sections
    .map((s, i) => (
      <SectionByType
        key={`section-${i}`}
        section={s}
        schema={schema}
        resource={resource}
        renderField={renderField}
        isAvailable={isAvailable}
      />
    ))
    .filter((c) => c !== null);
}

type SectionProps = {
  schema: Schema;
  resource: Resource;
  section: Section;
  renderField: RenderField;
  isAvailable: Available;
};

type CheckSectionProps = {
  resource: Resource;
  section: Section;
  isAvailable: Available;
};

function SectionByType(props: SectionProps): JSX.Element | null {
  const s = props.section;

  switch (s.type) {
    case 'flex':
      return (
        <FlexSection
          {...props}
          direction={s['direction']}
          sections={s['sections']}
          styles={s['styles']}
        />
      );
    case 'frame':
      return (
        <FrameSection
          {...props}
          label={s['label']}
          sections={s['sections']}
          styles={s['styles']}
        />
      );
    case 'field':
      return (
        <FieldSection
          {...props}
          fieldId={s['fieldId']}
          label={s['label']}
          labelPosition={s['labelPosition']}
          styles={s['styles']}
        />
      );
    case 'fragment':
      return <Sections {...props} sections={s['sections']} />;
  }

  return null;
}

export function isAvailable(props: CheckProps): boolean {
  if (!props.sections) {
    return false;
  }

  for (let s of props.sections) {
    if (
      isAvailableByType({
        section: s,
        resource: props.resource,
        isAvailable: props.isAvailable,
      })
    ) {
      return true;
    }
  }

  return false;
}

function isAvailableByType(props: CheckSectionProps): boolean {
  const s = props.section;

  switch (s.type) {
    case 'flex':
      return isAvailable({
        sections: s.sections,
        resource: props.resource,
        isAvailable: props.isAvailable,
      });
    case 'frame':
      return isAvailable({
        sections: s.sections,
        resource: props.resource,
        isAvailable: props.isAvailable,
      });
    case 'field':
      const field = props.resource.schema.fields.find(
        (f) => f.id === s.fieldId,
      );

      if (!field) {
        return false;
      }

      return props.isAvailable(field);
  }

  return false;
}
