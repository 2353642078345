import * as React from 'react';
import {DateSelectorBase} from './DateSelectorBase';
import {formatMonth} from './dateutil';

type Props = {
  value?: Date;
  onSelectDate: (date: Date | null) => void;
  placeholder?: string;
};

export function MonthSelector(props: Props): JSX.Element {
  return (
    <DateSelectorBase
      {...props}
      isDayPickerVisible={false}
      dateFormatter={formatMonth}
    />
  );
}
