import * as React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {array} from '../util';
import {DateListWidget} from '../widgets/DateListWidget';

type Props = FieldValueProps & {
  placeholder?: string;
};

export function DateListField(props: Props): JSX.Element | null {
  return (
    <DateListWidget
      value={array(props.value)}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      onChange={(v) => props.onChange({[props.field.id]: v})}
      style={(props.styles || {}).value}
    />
  );
}
