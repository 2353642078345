import React from 'react';
import {api, ApiContext} from '../../api';
import {Spinner} from '../../common/Spinner';
import {Filter as FilterComponent, ignoreEmpty} from '../../components/Filter';
import {Actions} from '../../types/Action';
import {FormValues} from '../../types/Form';
import {Params} from '../../types/Params';
import {ResourceList} from '../../types/ResourceList';

export type Props = {
  ctx: ApiContext;
  defaultParams: Params;
  actions: Actions;
  resourceList?: ResourceList;

  itemType: string;

  forceParams: Params;
  onResetList: () => void;
  onUpdateList: (list: ResourceList) => void;
  onUpdateFiltered: (filtered: boolean) => void;
};

export function ItemFilter(props: Props): JSX.Element {
  if (!props.resourceList) {
    return <Spinner />;
  }

  return (
    <FilterComponent
      {...props}
      fields={props.resourceList.schema.fields}
      relatedSchemas={props.resourceList.relatedSchemas}
      resourceList={props.resourceList}
      onSearch={(values: FormValues) => {
        onSearch(values, props);
      }}
      onReset={() => {
        onReset(props);
      }}
    />
  );
}

async function onSearch(values: FormValues, props: Props) {
  const params = {...values};
  // An empty default parameter is also considered to have been specified by the user.
  const specifiedParams = ignoreEmpty(params, Object.keys(props.defaultParams));
  const filtered = Object.keys(specifiedParams).length > 0;

  if (!filtered) {
    return props.onResetList();
  }

  // Remove any default parameter that is specified as empty.
  const validParams = ignoreEmpty(
    {
      ...props.defaultParams,
      ...specifiedParams,
    },
    [],
  );

  const resourceList = await api.list(props.ctx, props.itemType, {
    ...validParams,
    ...props.forceParams,
  });

  props.onUpdateFiltered(filtered);
  props.onUpdateList(resourceList);
}

function onReset(props: Props) {
  return props.onResetList();
}
