import React from 'react';
import {api, ApiContext} from '../../api';
import {ResponseMessage} from '../../components/RequestForm';
import {PARAM_KEY_FORCE} from '../../consts';
import {Actions} from '../../types/Action';
import {Params} from '../../types/Params';
import {CommonRequestDialog} from './CommonRequestDialog';

type Props = {
  ctx: ApiContext;
  shown: boolean;
  appId?: string;
  params?: Params;
  onAfterRequest: (resp: ResponseMessage) => void;
  onClose: (resp?: ResponseMessage) => void;
  actions: Actions;
};

export function AppRequestDialog({
  ctx,
  shown,
  appId,
  params,
  onAfterRequest,
  onClose,
  actions,
}: Props): JSX.Element | null {
  if (!appId) {
    return null;
  }

  return (
    <CommonRequestDialog
      shown={shown}
      onNewRequest={() => {
        return api.newAppRequest(ctx, appId, params);
      }}
      onRequest={() => {
        return api.appRequest(ctx, appId, params);
      }}
      onForceRequest={() => {
        return api.appRequest(ctx, appId, {
          ...params,
          [PARAM_KEY_FORCE]: true,
        });
      }}
      onAfterRequest={onAfterRequest}
      onClose={onClose}
      actions={actions}
    />
  );
}
