import {array} from '../../util';

export function formatDate(date?: Date | null): string {
  if (!date) {
    return '';
  }

  return [
    padLeft(date.getFullYear(), 4),
    padLeft(date.getMonth() + 1, 2),
    padLeft(date.getDate(), 2),
  ].join('/');
}

export function formatDateUTC(date?: Date | null): string {
  if (!date) {
    return '';
  }

  return (
    date.getUTCFullYear() +
    '-' +
    pad(date.getUTCMonth() + 1) +
    '-' +
    pad(date.getUTCDate()) +
    'T' +
    pad(date.getUTCHours()) +
    ':' +
    pad(date.getUTCMinutes()) +
    ':' +
    pad(date.getUTCSeconds()) +
    'Z'
  );
}

export function formatMonth(date?: Date | null): string {
  if (!date) {
    return '';
  }

  return [padLeft(date.getFullYear(), 4), padLeft(date.getMonth() + 1, 2)].join(
    '/',
  );
}

function pad(str: string | number): string {
  return padLeft(str, 2);
}

export function padLeft(str: string | number, n: number): string {
  return String(str).padStart(n, '0');
}

export function toDate(str?: string): Date | undefined {
  if (!str || !str.split) {
    return;
  }

  const unixTime = Date.parse(str);

  if (!isNaN(unixTime)) {
    return new Date(unixTime);
  }

  const ymd = str.split('/');

  return new Date(
    parseInt(ymd[0], 10),
    parseInt(ymd[1], 10) - 1,
    parseInt(ymd[2], 10),
  );
}

export function toDates(strs?: string[] | string): Date[] {
  const ss = array(strs);
  return ss.map((s) => toDate(s)).filter((d) => !!d) as Date[];
}

export function formatTime(time: Date): string {
  return pad(time.getHours()) + ':' + pad(time.getMinutes());
}

export const calendarStringsJa = {
  months: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  shortMonths: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  days: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
  shortDays: ['日', '月', '火', '水', '木', '金', '土'],
  goToToday: '今日',
  prevMonthAriaLabel: '先月',
  nextMonthAriaLabel: '次月',
  prevYearAriaLabel: '前年',
  nextYearAriaLabel: '翌年',
  closeButtonAriaLabel: '閉じる',
};
