import {useIntl} from 'react-intl';

export function useMessage(key: string, def: string): string {
  const intl = useIntl();

  return intl.formatMessage({
    id: key,
    defaultMessage: def,
  });
}
