import React, {useCallback, useState} from 'react';
import {ScannedValueSelectButton} from './ScannedValueSelectButton';
import {ScannedValueSelectorDialog} from './ScannedValueSelectorDialog';

type Props = {
  value: string;
  onSelect: (v: string) => void;
  antennaScanSchemaId: string;
  antennaId: string;
  antennaExt?: string;
  antennaUnknownFieldId: string;
};

export function AntennaScannedValueSelector(props: Props): JSX.Element | null {
  const [hidden, setHidden] = useState<boolean>(true);
  const onClick = useCallback(() => {
    setHidden(false);
  }, []);

  return (
    <div>
      <ScannedValueSelectButton onClick={onClick} />
      {hidden ? null : (
        <ScannedValueSelectorDialog
          {...props}
          hidden={hidden}
          onClose={() => {
            setHidden(true);
          }}
          selected={props.value}
          onSelect={props.onSelect}
        />
      )}
    </div>
  );
}
