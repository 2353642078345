import * as React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {widgetProps} from '../widgets/common';
import {RadioWidget} from '../widgets/RadioWidget';

type Props = FieldValueProps & {
  vertical?: boolean;
};

export function RadioField(props: Props) {
  const noClearButton = widgetProps<boolean>(
    props.field,
    'no_clear_button',
    false,
  );

  const vertical = widgetProps<boolean>(
    props.field,
    'vertical',
    props.vertical ?? false,
  );

  return (
    <RadioWidget
      value={props.value}
      options={props.field.options}
      onChange={(v) => props.onChange({[props.field.id]: v})}
      readOnly={props.readOnly}
      vertical={vertical}
      noClearButton={noClearButton}
    />
  );
}
