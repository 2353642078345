import React from 'react';
import styled from 'styled-components';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {bodyColorHovered, colors} from '../../styles';

type Props = {
  onPress: () => void;
  size: number;
  label?: string;
};

export function AddButton(props: Props): JSX.Element | null {
  return (
    <Container onClick={props.onPress}>
      <IconContainer
        style={{height: props.size + 'px', width: props.size + 'px'}}>
        <MaterialIcon iconName={'add'} />
      </IconContainer>
      <LabelOrNull label={props.label} size={props.size} />
    </Container>
  );
}

type LabelOrNullProps = {
  label?: string;
  size: number;
};

export function LabelOrNull({
  label,
  size,
}: LabelOrNullProps): JSX.Element | null {
  if (!label) {
    return null;
  }

  return <Label style={{lineHeight: size + 'px'}}>{label}</Label>;
}

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${colors.bodyColorAlt};

  &:hover {
    background-color: ${bodyColorHovered};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const Label = styled.div`
  font-size: 0.8rem;
  padding-right: 0.5rem;
`;
