import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {ErrorReason} from '../../types/Errors';
import {Dialog} from '../Dialog';
import {ErrorMsg} from '../ErrorMsg';
import {Spinner} from '../Spinner';

type Props = {
  shown: boolean;
  messages?: ErrorReason[];
  onClose: () => void;
};

export function MessageDialog(props: Props): JSX.Element | null {
  const ref = useRef<Dialog>(null);

  useEffect(() => {
    if (ref && ref.current && props.shown) {
      ref.current.showDialog();
    }
  }, [ref, props.shown]);

  const hasMessage = props.messages && props.messages.length > 0;

  const contents = hasMessage ? (
    <ErrorMsg messages={props.messages} />
  ) : (
    <Spinner />
  );

  return (
    <Dialog
      ref={ref}
      hidden={!props.shown}
      modal={!hasMessage}
      onClose={props.onClose}
      maxWidth="90vw">
      <Container>{contents}</Container>
    </Dialog>
  );
}

const Container = styled.div`
  padding: 1.5rem;
`;
