import React, {RefObject} from 'react';
import styled from 'styled-components';
import {borderColorLight} from '../styles';
import {GridArea} from './GridArea';
import {clearSelection, HandleProps, Resize, Size} from './GridLayout';
import {GridSizeDetector} from './GridSizeDetector';

type Props = HandleProps & {
  handleRadius: number;
  columns: Size[];
  setColumns: Resize;
  columnIndex: number;
  sizeDetector: RefObject<GridSizeDetector>;
};

type State = {
  startX: number;
  left: number;
  min: number;
  max: number;
  innerOffset: number;
  leftWidth: number;
  rightWidth: number;
};

export class VerticalHandle extends React.Component<Props, State> {
  private readonly element: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.element = React.createRef();

    this.state = {
      startX: 0,
      left: 0,
      innerOffset: 0,
      min: 0,
      max: 0,
      leftWidth: 0,
      rightWidth: 0,
    };
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.dragEnd);
  }

  mouseX(e: {clientX: number}) {
    let x = e.clientX - this.state.innerOffset;

    if (x > this.state.max) {
      x = this.state.max;
    }

    if (x < this.state.min) {
      x = this.state.min;
    }

    return x - this.state.left;
  }

  setX(e: {clientX: number}) {
    const newX = this.mouseX(e);
    const diff = newX - this.state.startX;
    const cols = [...this.props.columns];
    cols[this.props.columnIndex - 1].size = this.state.leftWidth + diff + 'px';
    cols[this.props.columnIndex + 1].size = this.state.rightWidth - diff + 'px';
    this.props.setColumns(cols);
  }

  detectRange() {
    const left = this.props.sizeDetector.current!.getColumnLeftRight(
      this.props.columnIndex - 1,
    );
    const right = this.props.sizeDetector.current!.getColumnLeftRight(
      this.props.columnIndex + 1,
    );

    const leftSize = this.props.columns[this.props.columnIndex - 1];
    const rightSize = this.props.columns[this.props.columnIndex + 1];

    const minCandidates = [];

    if (leftSize.min !== undefined) {
      minCandidates.push(left.left + leftSize.min);
    }

    if (rightSize.max !== undefined) {
      minCandidates.push(right.right - rightSize.max - this.props.handleRadius);
    }

    minCandidates.sort((a, b) => b - a);

    const maxCandidates = [];

    if (leftSize.max !== undefined) {
      maxCandidates.push(left.left + leftSize.max);
    }

    if (rightSize.min !== undefined) {
      maxCandidates.push(right.right - rightSize.min - this.props.handleRadius);
    }

    maxCandidates.sort((a, b) => a - b);

    return {
      left: left.left,
      min: minCandidates[0],
      max: maxCandidates[0],
    };
  }

  getWidth(columnIndex: number): number {
    return this.props.sizeDetector.current!.getColumnLeftRight(columnIndex)
      .width;
  }

  getLeft(columnIndex: number): number {
    return this.props.sizeDetector.current!.getColumnLeftRight(columnIndex)
      .left;
  }

  dragStart = (e: React.MouseEvent) => {
    if (!this.props.sizeDetector.current) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    this.toActualSize();
    document.addEventListener('mousemove', this.drag);
    document.addEventListener('mouseup', this.dragEnd);
    document.body.style.cursor = 'col-resize';
    const handleBox = e.currentTarget.getBoundingClientRect();
    const innerOffset = e.clientX - handleBox.left;
    const leftWidth = this.getWidth(this.props.columnIndex - 1);
    const rightWidth = this.getWidth(this.props.columnIndex + 1);
    const {left, min, max} = this.detectRange();
    const startX = this.getLeft(this.props.columnIndex) - left;
    this.setState({
      startX,
      left,
      min,
      max,
      innerOffset,
      leftWidth,
      rightWidth,
    });
    clearSelection();
  };

  drag = (e: MouseEvent) => {
    clearSelection();
    this.setX(e);
  };

  dragEnd = (e: MouseEvent) => {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.dragEnd);
    document.body.style.cursor = 'auto';
    clearSelection();
    this.setX(e);
  };

  toActualSize() {
    const sizes = this.props.sizeDetector.current!.columnSizes();

    this.props.columns.forEach((x, i) => {
      x.size = sizes[i] + 'px';
    });

    this.props.setColumns(this.props.columns);
  }

  render() {
    return (
      <VerticalHandleArea
        ref={this.element}
        onMouseDown={this.dragStart}
        area={this.props.area}>
        <Handle radius={this.props.handleRadius} />
      </VerticalHandleArea>
    );
  }
}

const VerticalHandleArea = styled(GridArea)`
  position: relative;
  background-color: ${borderColorLight};
  z-index: 100;
  &:hover {
    cursor: col-resize;
  }
`;

const Handle = styled.div<{radius: number}>`
  margin-left: -${(props) => props.radius}px;
  width: ${(props) => props.radius * 2 + 1}px;
  height: 100%;
`;
