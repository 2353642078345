import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceList} from '../../types/ResourceList';

export type SetResourceList = (list: ResourceList) => void;

export type OnSelectRow = (item: ResourceDetails) => void;

export function getEmptyMessage(filtered?: boolean): string {
  if (filtered) {
    return '検索条件に一致するデータがありません';
  }

  return 'データがありません';
}
