import React, {RefObject, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {ApiContext} from '../../api';
import {Dialog} from '../Dialog';
import {Spinner} from '../Spinner';
import {ImportForm} from '../../components/ImportForm';

type Props = {
  ctx: ApiContext;
  shown: boolean;
  appId?: string;
  fileTypes?: string[];
  onClose: () => void;
  onComplete: () => void;
};

export function ImportDialog(props: Props): JSX.Element | null {
  const ref = useRef<Dialog>(null);

  useEffect(() => {
    if (ref && ref.current && props.shown) {
      ref.current.showDialog();
    }
  }, [ref, props.shown]);

  return (
    <Dialog ref={ref} modal={true} title={'インポート'} maxWidth="100%">
      <ImportDialogContents {...props} componentRef={ref} />
    </Dialog>
  );
}

type ContentProps = {
  ctx: ApiContext;
  componentRef: RefObject<Dialog>;
  appId?: string;
  fileTypes?: string[];
  onClose: () => void;
  onComplete: () => void;
};

function ImportDialogContents(props: ContentProps): JSX.Element | null {
  if (!props.appId) {
    return <Spinner />;
  }

  return (
    <DialogContents>
      <ImportForm
        ctx={props.ctx}
        appId={props.appId}
        fileTypes={props.fileTypes}
        cancelLabel={'閉じる'}
        onCancel={() => {
          props.componentRef.current!.closeDialog();
          props.onClose();
        }}
        onComplete={props.onComplete}
      />
    </DialogContents>
  );
}

const DialogContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
`;
