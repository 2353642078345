import React, {RefObject} from 'react';
import styled from 'styled-components';
import {borderColorLight} from '../styles';
import {GridArea} from './GridArea';
import {clearSelection, HandleProps, Resize, Size} from './GridLayout';
import {GridSizeDetector} from './GridSizeDetector';

type Props = HandleProps & {
  handleRadius: number;
  rows: Size[];
  setRows: Resize;
  rowIndex: number;
  sizeDetector: RefObject<GridSizeDetector>;
};

type State = {
  startY: number;
  top: number;
  min: number;
  max: number;
  innerOffset: number;
  topHeight: number;
  bottomHeight: number;
};

export class HorizontalHandle extends React.Component<Props, State> {
  private readonly element: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.element = React.createRef();

    this.state = {
      startY: 0,
      top: 0,
      innerOffset: 0,
      min: 0,
      max: 0,
      topHeight: 0,
      bottomHeight: 0,
    };
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.dragEnd);
  }

  mouseY(e: {clientY: number}) {
    let y = e.clientY - this.state.innerOffset;

    if (y > this.state.max) {
      y = this.state.max;
    }

    if (y < this.state.min) {
      y = this.state.min;
    }

    return y - this.state.top;
  }

  setY(e: {clientY: number}) {
    const newY = this.mouseY(e);
    const diff = newY - this.state.startY;
    const rows = [...this.props.rows];
    rows[this.props.rowIndex - 1].size = this.state.topHeight + diff + 'px';
    rows[this.props.rowIndex + 1].size = this.state.bottomHeight - diff + 'px';
    this.props.setRows(rows);
  }

  detectRange() {
    const top = this.props.sizeDetector.current!.getRowTopBottom(
      this.props.rowIndex - 1,
    );
    const bottom = this.props.sizeDetector.current!.getRowTopBottom(
      this.props.rowIndex + 1,
    );

    const topSize = this.props.rows[this.props.rowIndex - 1];
    const bottomSize = this.props.rows[this.props.rowIndex + 1];

    const minCandidates = [];

    if (topSize.min !== undefined) {
      minCandidates.push(top.top + topSize.min);
    }

    if (bottomSize.max !== undefined) {
      minCandidates.push(
        bottom.bottom - bottomSize.max - this.props.handleRadius,
      );
    }

    minCandidates.sort((a, b) => b - a);

    const maxCandidates = [];

    if (topSize.max !== undefined) {
      maxCandidates.push(top.top + topSize.max);
    }

    if (bottomSize.min !== undefined) {
      maxCandidates.push(
        bottom.bottom - bottomSize.min - this.props.handleRadius,
      );
    }

    maxCandidates.sort((a, b) => a - b);

    return {
      top: top.top,
      min: minCandidates[0],
      max: maxCandidates[0],
    };
  }

  getHeight(columnIndex: number): number {
    return this.props.sizeDetector.current!.getRowTopBottom(columnIndex).height;
  }

  getTop(columnIndex: number): number {
    return this.props.sizeDetector.current!.getRowTopBottom(columnIndex).top;
  }

  dragStart = (e: React.MouseEvent) => {
    if (!this.props.sizeDetector.current) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    this.toActualSize();
    document.addEventListener('mousemove', this.drag);
    document.addEventListener('mouseup', this.dragEnd);
    document.body.style.cursor = 'row-resize';
    const handleBox = e.currentTarget.getBoundingClientRect();
    const innerOffset = e.clientY - handleBox.top;
    const topHeight = this.getHeight(this.props.rowIndex - 1);
    const bottomHeight = this.getHeight(this.props.rowIndex + 1);
    const {top, min, max} = this.detectRange();
    const startY = this.getTop(this.props.rowIndex) - top;
    this.setState({
      startY,
      top,
      min,
      max,
      innerOffset,
      topHeight,
      bottomHeight,
    });
    clearSelection();
  };

  drag = (e: MouseEvent) => {
    clearSelection();
    this.setY(e);
  };

  dragEnd = (e: MouseEvent) => {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.dragEnd);
    document.body.style.cursor = 'auto';
    clearSelection();
    this.setY(e);
  };

  toActualSize() {
    const sizes = this.props.sizeDetector.current!.rowSizes();

    this.props.rows.forEach((x, i) => {
      x.size = sizes[i] + 'px';
    });

    this.props.setRows(this.props.rows);
  }

  render() {
    return (
      <HorizontalHandleArea
        ref={this.element}
        onMouseDown={this.dragStart}
        area={this.props.area}>
        <Handle radius={this.props.handleRadius} />
      </HorizontalHandleArea>
    );
  }
}

const HorizontalHandleArea = styled(GridArea)`
  position: relative;
  background-color: ${borderColorLight};
  z-index: 100;
  &:hover {
    cursor: row-resize;
  }
`;

const Handle = styled.div<{radius: number}>`
  margin-top: -${(props) => props.radius}px;
  height: ${(props) => props.radius * 2 + 1}px;
  width: 100%;
`;
