import * as React from 'react';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Field, Option} from '../types/Field';
import {CheckboxWidget, OnChangeFn} from '../widgets/CheckboxWidget';
import {onChangeValue as onChangeSearchValue} from './CheckboxField';

type Props = {
  value: any;
  field: Field;
  onChange?: (v: any) => void;
  readOnly?: boolean;
};

export function BoolField(props: Props) {
  const fn = useCallback<OnChangeFn>(
    (v, checked) => {
      onChangeValue(props.field.id, props.value, v, checked, props.onChange);
    },
    [props.field.id, props.value, props.onChange],
  );

  const options: Option[] = [
    {
      value: 'true',
      label: '',
    },
  ];

  return (
    <Container>
      <CheckboxWidget
        {...props}
        value={String(props.value)}
        options={options}
        onChange={fn}
      />
    </Container>
  );
}

export function BoolSearchField(props: Props): JSX.Element | null {
  const fn = useCallback<OnChangeFn>(
    (v, checked) => {
      onChangeSearchValue(
        props.field.id,
        0,
        props.value,
        v,
        checked,
        props.onChange,
      );
    },
    [props.field.id, props.value, props.onChange],
  );

  const intl = useIntl();

  const options: Option[] = [
    {
      value: 'true',
      label: intl.formatMessage({
        id: 'Widget.Bool.True',
        defaultMessage: 'Checked',
      }),
    },
    {
      value: 'false',
      label: intl.formatMessage({
        id: 'Widget.Bool.False',
        defaultMessage: 'Unchecked',
      }),
    },
  ];

  return (
    <Container>
      <CheckboxWidget {...props} options={options} onChange={fn} />
    </Container>
  );
}

function onChangeValue(
  fieldId: string,
  value: any,
  newValue: string,
  checked?: boolean,
  onChange?: OnChangeFieldValue,
) {
  if (onChange) {
    onChange({[fieldId]: checked});
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
