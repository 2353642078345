import React from 'react';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {ButtonWidget} from '../widgets/ButtonWidget';
import {widgetProps} from '../widgets/common';
import {useActionAndArgs} from './hooks/useActionAndArgs';

type Props = {
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
  disabled?: boolean;
};

export function ButtonField(props: Props): JSX.Element | null {
  const iconName = widgetProps<string>(props.field, 'icon_name', '');
  const label = widgetProps<string>(props.field, 'label', '');
  const size = widgetProps<number | undefined>(props.field, 'size', undefined);
  const width = widgetProps<number | undefined>(
    props.field,
    'width',
    undefined,
  );

  const {action, args} = useActionAndArgs(
    props.field,
    props.schema,
    props.item,
    props.actions,
    'on_press',
    '',
  );

  const onClick = () => {
    action(args);
  };

  const disabled = useDisabled(props.field, props.item, props.disabled);

  return (
    <ButtonWidget
      iconName={iconName}
      label={label}
      onClick={onClick}
      size={size}
      width={width}
      disabled={disabled}
    />
  );
}

function useDisabled(
  field: Field,
  item: ResourceDetails,
  disabled?: boolean,
): boolean | undefined {
  const fid = widgetProps<string>(field, 'disabled_field_id', '');

  if (fid === '') {
    return disabled;
  }

  return item[fid] ?? disabled;
}
