import {addMonths} from '@fluentui/date-time-utilities';
import React, {useState} from 'react';
import styled from 'styled-components';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {classNameIconMiddle, textColor} from '../../styles';

type Props = {
  month: Date;
  onSelect: (date: Date) => void;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Part = styled.div`
  display: inline-block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: ${textColor};
`;

const Arrow = styled.div`
  display: inline-block;
  height: 20px;
  line-height: 20px;
  min-width: 30px;
  text-align: center;
  cursor: pointer;
  color: ${textColor};
  user-select: none;
`;

export function MonthSelector(props: Props): JSX.Element | null {
  const [month, setMonth] = useState(props.month);

  return (
    <Container>
      <Arrow
        onClick={() => {
          const m = addMonths(month, -1);
          setMonth(m);
          props.onSelect(m);
        }}>
        <MaterialIcon
          iconName={'keyboard_arrow_left'}
          className={classNameIconMiddle}
        />
      </Arrow>
      <Part>{month.getFullYear() + '年' + (month.getMonth() + 1) + '月'}</Part>
      <Arrow
        onClick={() => {
          const m = addMonths(month, 1);
          setMonth(m);
          props.onSelect(m);
        }}>
        <MaterialIcon
          iconName={'keyboard_arrow_right'}
          className={classNameIconMiddle}
        />
      </Arrow>
    </Container>
  );
}
