import {
  ContextualMenu,
  DefaultButton,
  Dialog as OriginalDialog,
  DialogType,
  PrimaryButton,
} from '@fluentui/react';
import * as React from 'react';
import {CSSProperties} from 'react';
import styled from 'styled-components';
import {borderColorTheme} from '../styles';
import {ErrorReason} from '../types/Errors';
import './Dialog.css';
import {ErrorMsg} from './ErrorMsg';

type Props<T> = {
  hidden?: boolean;
  onExecute?: (data?: T) => Promise<boolean>;
  onCancel?: (data?: T) => void;
  onOpen?: (data?: T) => void;
  onClose?: (data?: T) => void;
  renderHeader?: (data?: T) => JSX.Element | null;
  renderBody?: (data?: T) => JSX.Element | null;
  renderFooter?: (data?: T, errors?: ErrorReason[]) => JSX.Element | null;
  body?: JSX.Element;
  executeLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  title?: string;
  subText?: string;
  contentClassName?: string;
  maxWidth?: string;
  errors?: ErrorReason[];
  modal?: boolean;
  styles?: {
    buttons?: CSSProperties;
  };
  children?: React.ReactNode;
};

type State<T> = {
  hidden: boolean;
  data?: T;
  status: 'init' | 'executing' | 'executed';
};

export class Dialog<T = void> extends React.Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);

    this.state = {
      hidden: true,
      status: 'init',
    };

    this.showDialog = this.showDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onExecute = this.onExecute.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onExecute = async () => {
    if (this.props.onExecute) {
      this.setState({status: 'executing'});

      let close = false;

      try {
        close = await this.props.onExecute(this.state.data);
      } finally {
        this.setState({status: 'executed'});
      }

      if (!close) {
        return;
      }
    }

    this.closeDialog();
  };

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel(this.state.data);
    }

    this.closeDialog();
  }

  showDialog(data: T) {
    this.setState({hidden: false, data});

    if (this.props.onOpen) {
      this.props.onOpen(data);
    }
  }

  closeDialog() {
    if (this.props.onClose) {
      this.props.onClose(this.state.data);
    }

    this.setState({hidden: true, data: undefined});
  }

  isButtonDisabled() {
    return this.props.disabled || this.state.status === 'executing';
  }

  renderHeader() {
    if (this.props.renderHeader) {
      return this.props.renderHeader(this.state.data);
    }

    return null;
  }

  renderBody() {
    if (this.props.renderBody) {
      return this.props.renderBody(this.state.data);
    }

    if (this.props.body) {
      return this.props.body;
    }

    return this.props.children;
  }

  renderFooter() {
    if (this.props.renderFooter) {
      return this.props.renderFooter(this.state.data, this.props.errors);
    }

    if (!this.props.onExecute && !this.props.onCancel) {
      return;
    }

    return (
      <DialogFooter>
        <ErrorMsg messages={this.props.errors} />
        <DialogButtons style={this.props.styles?.buttons}>
          {this.props.onExecute ? (
            <PrimaryButton
              onClick={this.onExecute}
              text={this.props.executeLabel || '実行'}
              disabled={this.isButtonDisabled()}
            />
          ) : null}
          {this.props.onCancel ? (
            <DefaultButton
              onClick={this.onCancel}
              text={this.props.cancelLabel || 'キャンセル'}
              disabled={this.isButtonDisabled()}
            />
          ) : null}
        </DialogButtons>
      </DialogFooter>
    );
  }

  isHidden() {
    if (this.props.hidden !== undefined) {
      return this.props.hidden;
    }

    return this.state.hidden;
  }

  render() {
    const hidden = this.isHidden();

    if (hidden) {
      return null;
    }

    const styles = {
      main: {
        maxHeight: 'calc(100% - 100px)',
        minHeight: 150,
        width: 'auto',
      },
    };

    if (this.props.modal) {
      return (
        <OriginalDialog
          maxWidth={this.props.maxWidth || '90vw'}
          hidden={hidden}
          onDismiss={this.closeDialog}
          styles={styles}
          dialogContentProps={{
            type: DialogType.normal,
            showCloseButton: false,
            title: this.props.title,
            subText: this.props.subText,
            className: this.props.contentClassName,
            styles: {
              header: {
                borderTop: `3px solid ${borderColorTheme}`,
              },
              inner: {
                padding: 0,
              },
            },
          }}
          modalProps={{
            isBlocking: true,
            layerProps: {eventBubblingEnabled: true},
          }}>
          <Container>
            {this.renderHeader()}
            {this.renderBody()}
            {this.renderFooter()}
          </Container>
        </OriginalDialog>
      );
    }

    return (
      <OriginalDialog
        maxWidth={this.props.maxWidth}
        hidden={hidden}
        onDismiss={this.closeDialog}
        styles={styles}
        dialogContentProps={{
          type: DialogType.close,
          showCloseButton: false,
          title: this.props.title,
          subText: this.props.subText,
          className: this.props.contentClassName,
          styles: {
            header: {
              borderTop: `3px solid ${borderColorTheme}`,
            },
            inner: {
              padding: 0,
            },
          },
        }}
        modalProps={{
          isBlocking: false,
          layerProps: {eventBubblingEnabled: true},
          dragOptions: {
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
          },
        }}>
        <Container>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </Container>
      </OriginalDialog>
    );
  }
}

const Container = styled.div``;

export const DialogFooter = styled.div`
  padding: 1rem;
  box-sizing: border-box;
`;

export const DialogButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;

    & button {
      flex-grow: 1;
      height: 44px;
    }
  }
`;
