import {loadDefaultJapaneseParser} from 'budoux';
import styled from 'styled-components';

type Props = {
  text: string;
};

const parser = loadDefaultJapaneseParser();
const splitter = /(?=[\s(（]+)/;

export function WrapText({text}: Props): JSX.Element | null {
  if (!text) {
    return null;
  }

  return (
    <>
      {split(text).map((s, i) => (
        <Part key={`s-${i}`}>{s}</Part>
      ))}
    </>
  );
}

const Part = styled.span`
  display: inline-block;
`;

function split(text: string): string[] {
  const ss = parser.parse(text);
  return ss
    .flatMap((s) => s.split(splitter).map((s) => s.trim()))
    .filter((s) => !!s);
}
