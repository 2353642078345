import React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {
  ColorWidget,
  DEFAULT_COLUMN_COUNT,
  DEFAULT_PICKER_TYPE,
  PickerType,
} from '../widgets/ColorWidget';
import {widgetProps} from '../widgets/common';

export function ColorField(props: FieldValueProps): JSX.Element | null {
  const columnCount = widgetProps<number>(
    props.field,
    'column_count',
    DEFAULT_COLUMN_COUNT,
  );

  const pickerType = widgetProps<PickerType>(
    props.field,
    'picker_type',
    DEFAULT_PICKER_TYPE,
  );

  const inline = widgetProps<boolean>(props.field, 'inline', true);
  const defaultColor = widgetProps<string>(props.field, 'default_color', '');

  return (
    <ColorWidget
      pickerType={pickerType}
      value={props.value}
      onChange={(v) => {
        props.onChange({[props.field.id]: v});
      }}
      options={props.field.options}
      readOnly={props.readOnly}
      columnCount={columnCount}
      inline={inline}
      defaultColor={defaultColor}
    />
  );
}
