import React from 'react';
import {Styles} from '../types/Styles';
import {MarkdownWidget} from '../widgets/MarkdownWidget';

type Props = {
  text?: string;
  styles?: Styles;
};

export function MarkdownMessage(props: Props): JSX.Element | null {
  if (!props.text) {
    return null;
  }

  return <MarkdownWidget value={props.text} styles={props.styles} />;
}
