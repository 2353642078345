import {
  ContextualMenu,
  Dialog,
  DialogType,
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react';
import React, {CSSProperties, MouseEvent, useState} from 'react';
import {Img} from 'react-image';
import styled, {css} from 'styled-components';
import {MaterialIcon} from '../icons/MaterialIcon';
import {bodyColor, borderColorLightest, textColorLightest} from '../styles';
import {array} from '../util';

type Props = {
  value: any;
  alt: string;
  size?: number;
  clickable?: boolean;
  styles?: Styles;
};

type Styles = {
  root?: CSSProperties;
  img?: CSSProperties;
  notFound?: CSSProperties;
};

const defaultSize = 100;

export function ImageOutputWidget(props: Props) {
  const list = array(props.value);
  //TODO edit mode?
  return (
    <Container>
      {list.map((src, i) => (
        <ImageComponent
          key={`img-${i}`}
          src={src}
          size={validSize(props.size)}
          clickable={props.clickable ?? true}
          alt={props.alt}
          styles={props.styles}
        />
      ))}
    </Container>
  );
}

function validSize(size?: number): number {
  if (!size || size < 0) {
    return defaultSize;
  }

  return size;
}

type ImageProps = {
  src: string;
  alt: string;
  size: number;
  clickable: boolean;
  styles?: Styles;
};

function ImageComponent({src, alt, size, clickable, styles}: ImageProps) {
  const ss = styles || {};

  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <StyledImg
        style={ss.img}
        size={size}
        src={src}
        alt={alt}
        container={(children) => (
          <ImageContainer
            clickable={clickable}
            onClick={
              clickable
                ? (e) => {
                    cancelEvent(e);
                    setShow(true);
                  }
                : cancelEvent
            }
            style={ss.root}
            href={src}
            target="_blank"
            rel="noopener noreferrer">
            {children}
          </ImageContainer>
        )}
        loader={
          <Shimmer
            width={size + 'px'}
            shimmerElements={[
              {
                type: ShimmerElementType.line,
                height: size,
                width: size,
              },
            ]}
          />
        }
        unloaderContainer={(children) => (
          <NotFoundContainer style={ss.root}>{children}</NotFoundContainer>
        )}
        unloader={
          <NotFoundIconContainer size={size} style={ss.notFound}>
            <NotFoundIcon iconName={'help_outline'} />
          </NotFoundIconContainer>
        }
      />
      <Dialog
        hidden={!show}
        onDismiss={() => setShow(false)}
        maxWidth={'90vw'}
        dialogContentProps={{
          type: DialogType.close,
        }}
        modalProps={{
          isBlocking: false,
          dragOptions: {
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
          },
        }}>
        <ActualImageContainer>
          <ActualImage src={src} alt={alt}></ActualImage>
        </ActualImageContainer>
      </Dialog>
    </>
  );
}

function cancelEvent(e: MouseEvent) {
  e.stopPropagation();
  e.preventDefault();
}

type Content = {
  size: number;
  style?: CSSProperties;
};

function apply({size, style}: Content) {
  return {
    style: {
      ...{
        width: size + 'px',
        height: size + 'px',
        lineHeight: size + 'px',
      },
      ...style,
    },
  };
}

type Anchor = {
  clickable: boolean;
  style?: CSSProperties;
};

function anchor({clickable, style}: Anchor) {
  const clickStyle = clickable ? {} : {cursor: 'default'};

  return {
    style: {
      ...clickStyle,
      ...style,
    },
  };
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const centering = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.a.attrs<Anchor>(anchor)<Anchor>`
  ${centering};
  display: block;
  margin: 0.5rem;
  text-decoration: none;
`;

const NotFoundContainer = styled.div`
  ${centering};
  margin: 0.5rem;
`;

const NotFoundIconContainer = styled.div.attrs<Content>(apply)<Content>`
  ${centering};
  box-sizing: border-box;
  border: 5px solid ${borderColorLightest};
  width: 100px;
  height: 100px;
  background-color: ${bodyColor};
  user-select: none;
`;

const NotFoundIcon = styled(MaterialIcon)`
  font-size: 30px;
  color: ${textColorLightest};
`;

const StyledImg = styled(Img).attrs<Content>(apply)<Content>`
  box-sizing: border-box;
  border: 5px solid ${borderColorLightest};
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

// Without `display: flex;`, there will be 5px space below the image.
const ActualImageContainer = styled.div`
  max-width: 80vw;
  max-height: min(80vh, 600px);
  display: flex;
`;

const ActualImage = styled.img`
  max-width: 80vw;
  max-height: min(80vh, 600px);
  object-fit: cover;
`;
