import React, {KeyboardEventHandler} from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {WidgetStyles} from '../types/WidgetStyles';
import {AutoComplete, widgetProps} from '../widgets/common';
import {
  TextListInputWidget,
  TextListOutputWidget,
} from '../widgets/TextListWidget';

type Props = FieldValueProps & {
  multiline: boolean;
  autoComplete?: AutoComplete;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function TextListField(props: Props): JSX.Element | null {
  const num = widgetProps<number>(props.field, 'num', 1);
  const prefixes = widgetProps<string[]>(props.field, 'prefixes', []);
  const suffixes = widgetProps<string[]>(props.field, 'suffixes', []);
  const joiners = widgetProps<string[]>(props.field, 'joiners', []);
  const vertical = widgetProps<boolean>(props.field, 'vertical', true);
  const displayBox = widgetProps<boolean>(props.field, 'display_box', false);
  const styles = {
    ...widgetProps<WidgetStyles>(props.field, 'styles', {}),
    ...props.styles,
  };

  if (props.readOnly) {
    return (
      <TextListOutputWidget
        {...props}
        num={num}
        prefixes={prefixes}
        suffixes={suffixes}
        joiners={joiners}
        vertical={vertical}
        styles={styles}
        displayBox={displayBox}
      />
    );
  }

  const onChange = (v: string, i: number, values: string[]) => {
    if (props.onChange) {
      const vs: string[] = [...values];
      vs[i] = v || '';
      props.onChange({[props.field.id]: vs});
    }
  };

  return (
    <TextListInputWidget
      {...props}
      onChange={onChange}
      num={num}
      prefixes={prefixes}
      suffixes={suffixes}
      joiners={joiners}
      vertical={vertical}
      styles={styles}
    />
  );
}
