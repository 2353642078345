import React, {useState} from 'react';
import styled from 'styled-components';
import {MaterialIcon} from '../icons/MaterialIcon';
import {textColorTheme} from '../styles';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export function FlagWidget(props: Props): JSX.Element {
  const [value, setValue] = useState<boolean>(props.value);
  const iconName = selectIcon(value);

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const newValue = !value;
        props.onChange(newValue);
        setValue(newValue);
      }}>
      <MaterialIcon iconName={iconName} />
    </Container>
  );
}

function selectIcon(value: boolean): string {
  return value ? 'flag' : 'outlined_flag';
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColorTheme};

  & .material-icons {
    font-size: 20px;
  }
`;
