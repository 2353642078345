import {ActionPropsHolder} from './actionProps';

export type onReload = () => void;

export function reload(holder?: ActionPropsHolder, onReload?: onReload) {
  if (onReload) {
    onReload();
  } else {
    window.location.reload();
  }
}
