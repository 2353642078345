export type ActionPropsHolder = {
  actionProps?: {[key: string]: any};
};

export function actionProps<T = any>(
  holder: ActionPropsHolder,
  key: string,
  def: T,
): T {
  if (!holder.actionProps) {
    return def;
  }

  const v = holder.actionProps[key];

  if (v === undefined) {
    return def;
  }

  return v as T;
}
