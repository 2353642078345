import React, {CSSProperties, useCallback} from 'react';
import {api, useApiContext} from '../api';
import {FieldValueProps, OnChangeFieldValue} from '../field/FieldValue';
import {widgetProps} from '../widgets/common';
import {
  TimeInputWidget,
  TimeOutputWidget,
  TimeRangeInputWidget,
} from '../widgets/TimeWidget';

type Props = FieldValueProps & {style?: CSSProperties};

export function TimeField(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <TimeOutputWidget {...props} />;
  }

  if (needTimeRange(props)) {
    return <TimeRangeField {...props} />;
  }

  return <TimeInputField {...props} />;
}

const needTimeRange = (props: Props): boolean => {
  if (!widgetProps<boolean>(props.field, 'time_range', false)) {
    return false;
  }

  const startId = widgetProps<string>(props.field, 'start_field_id', '');
  const startField = props.schema.fields.find((f) => f.id === startId);

  const endId = widgetProps<string>(props.field, 'end_field_id', '');
  const endField = props.schema.fields.find((f) => f.id === endId);

  return !!(startField && endField);
};

function TimeInputField(props: Props): JSX.Element | null {
  const onChange = useOnChange(props.onChange, props.field.id);
  return <TimeInputWidget {...props} onChange={onChange} />;
}

function TimeRangeField(props: Props): JSX.Element | null {
  const startId = widgetProps<string>(props.field, 'start_field_id', '');
  const startValue = props.values[startId];

  const endId = widgetProps<string>(props.field, 'end_field_id', '');
  const endValue = props.values[endId];

  const onChangeStart = useOnChange(props.onChange, startId);
  const onChangeEnd = useOnChange(props.onChange, endId);
  const onChangeRange = useOnChangeRange(props.onChange, startId, endId);

  const calendar = widgetProps<boolean>(props.field, 'calendar', false);

  const ctx = useApiContext();
  const paramsFieldId = widgetProps<string>(
    props.field,
    'events_params_field_id',
    'events_params',
  );
  const params = props.values[paramsFieldId];
  const schemaId = props.schema.id;
  const onListEvents = useCallback(async () => {
    const list = await api.list(ctx, schemaId, params);
    return list.list;
  }, [ctx, schemaId, params]);

  return (
    <TimeRangeInputWidget
      {...props}
      startId={startId}
      startValue={startValue}
      onChangeStart={onChangeStart}
      endId={endId}
      endValue={endValue}
      onChangeEnd={onChangeEnd}
      onListEvents={onListEvents}
      onChangeRange={onChangeRange}
      calendar={calendar}
    />
  );
}

const useOnChange = (onChange: OnChangeFieldValue, fieldId: string) => {
  return useCallback(
    (d: string) => onChange({[fieldId]: d}),
    [onChange, fieldId],
  );
};

const useOnChangeRange = (
  onChange: OnChangeFieldValue,
  startId: string,
  endId: string,
) => {
  return useCallback(
    (start: string, end: string) => onChange({[startId]: start, [endId]: end}),
    [onChange, startId, endId],
  );
};
