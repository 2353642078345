import React from 'react';
import {ResourceDetails} from '../../types/ResourceDetails';
import {isChecked} from './Item';
import {Items} from './Items';

type Props = {
  items: ResourceDetails[];
  checkedIds: string[];
  onChecked: (ids: string[]) => void;
  size: number;
  nameFieldId?: string;
};

export function MultipleItemSelector(props: Props): JSX.Element | null {
  const isChecked: isChecked = (id: string): boolean => {
    return props.checkedIds.includes(id);
  };

  return (
    <Items
      {...props}
      isChecked={isChecked}
      onChecked={(id: string, checked: boolean) => {
        if (checked) {
          props.onChecked(plus(props.checkedIds, id));
        } else {
          props.onChecked(minus(props.checkedIds, id));
        }
      }}
    />
  );
}

function plus(ids: string[], id: string): string[] {
  const s = new Set<string>([...ids]);
  s.add(id);
  return [...s];
}

function minus(ids: string[], id: string): string[] {
  const s = new Set<string>([...ids]);
  s.delete(id);
  return [...s];
}
