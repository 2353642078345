import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import {Styles} from '../types/Styles';

type Props = {
  value: string;
  styles?: Styles;
};

export function MarkdownWidget(props: Props): JSX.Element | null {
  if (!props.value) {
    return null;
  }

  return (
    <Container style={props.styles?.container}>
      <ReactMarkdown
        remarkPlugins={[[remarkBreaks], [remarkGfm, {singleTilde: false}]]}
        linkTarget={'_blank'}>
        {props.value}
      </ReactMarkdown>
    </Container>
  );
}

const Container = styled.div`
  & *:first-child {
    margin-block-start: 0;
  }

  & *:last-child {
    margin-block-end: 0;
  }
`;
