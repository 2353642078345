import React from 'react';
import styled from 'styled-components';
import {Logo} from '../../common/Logo';

export function LogoButton(): JSX.Element {
  return (
    <LogoContainer
      onClick={() => {
        window.location.reload();
      }}>
      <Logo style={{width: '100px'}} />
    </LogoContainer>
  );
}

const LogoContainer = styled.div``;
