import styled from 'styled-components';
import {textColorError} from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px 50px 50px;
  gap: 2rem;
  font-family: sans-serif;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const Desc = styled.div`
  margin-bottom: 1rem;
`;

export const ErrorMessage = styled.div`
  max-width: 400px;
  color: ${textColorError};
`;
