import * as React from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {DateWidget} from '../widgets/DateWidget';

type Props = FieldValueProps & {
  placeholder?: string;
};

export function DateField(props: Props) {
  return (
    <DateWidget
      value={props.value}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      onChange={(v) => props.onChange({[props.field.id]: v})}
      style={(props.styles || {}).value}
    />
  );
}
