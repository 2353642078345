import {TextField} from '@fluentui/react';
import React, {CSSProperties, KeyboardEventHandler} from 'react';
import styled from 'styled-components';
import {
  bodyColor,
  borderColorLight,
  textColor,
  textColorLight,
} from '../styles';
import {WidgetStyles} from '../types/WidgetStyles';
import {array} from '../util';
import {AutoComplete, selectStringValue, textDecorator} from './common';
import {TextOutputWidget} from './TextWidget';

type OutputProps = {
  value: string | string[];
  num: number;
  prefixes: string[];
  suffixes: string[];
  displayBox?: boolean;
  joiners: string[];
  vertical: boolean;
  styles?: WidgetStyles;
  digitSeparator?: boolean;
};

export function TextListOutputWidget(props: OutputProps): JSX.Element | null {
  const Container = props.vertical ? VerticalContainer : HorizontalContainer;
  const decorator = textDecorator(props.digitSeparator);
  const values = array(props.value);
  const len = Math.max(props.num, values.length);
  const elements: JSX.Element[] = [];
  const styles = makeReadOnlyStyles((props.styles || {}).value);

  if (isEmpty(len, values)) {
    return null;
  }

  if (props.displayBox) {
    for (let i = 0; i < len; i++) {
      const v = values[i] || '';

      elements.push(
        <TextField
          key={`text-${i}`}
          value={selectStringValue(v, decorator)}
          styles={styles}
          readOnly={true}
          disabled={true}
          prefix={props.prefixes[i]}
          suffix={props.suffixes[i]}
        />,
      );

      addJoiner(props, elements, i);
    }
  } else {
    for (let i = 0; i < len; i++) {
      const v = values[i] || '';

      elements.push(
        <TextOutputWidget
          {...props}
          key={`text-${i}`}
          value={v}
          copy={false}
          prefix={props.prefixes[i]}
          suffix={props.suffixes[i]}
        />,
      );

      addJoiner(props, elements, i);
    }
  }

  return <Container>{elements}</Container>;
}

function addJoiner(props: OutputProps, elements: JSX.Element[], i: number) {
  const joiner = props.joiners[i];

  if (joiner) {
    elements.push(<Joiner key={`text-list-joiner-${i}`}>{joiner}</Joiner>);
  }
}

function isEmpty(len: number, vs: string[]): boolean {
  if (len === 0) {
    return true;
  }

  for (let i = 0; i < len; i++) {
    if (vs[i] && vs[i] !== '') {
      return false;
    }
  }

  return true;
}

function makeReadOnlyStyles(style?: CSSProperties) {
  if (!style) {
    return readOnlyStyles;
  }

  return {
    ...readOnlyStyles,
    field: {
      ...readOnlyStyles.field,
      ...style,
    },
  };
}

const readOnlyStyles = {
  fieldGroup: {
    borderColor: borderColorLight,
  },
  field: {
    color: textColor,
    backgroundColor: bodyColor,
  },
  prefix: {
    color: textColorLight,
  },
};

type InputProps = {
  value: string | string[];
  onChange: (v: string, i: number, values: string[]) => void;
  num: number;
  prefixes: string[];
  suffixes: string[];
  joiners: string[];
  vertical: boolean;
  readOnly?: boolean;
  multiline: boolean;
  styles: WidgetStyles;
  autoComplete?: AutoComplete;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  textProps?: {[key: string]: any};
};

export function TextListInputWidget(props: InputProps): JSX.Element | null {
  const inputs: JSX.Element[] = [];
  const values = array(props.value);
  const len = Math.max(props.num, values.length, 1);

  for (let i = 0; i < len; i++) {
    const onChange = (_: unknown, v?: string) => {
      if (props.onChange) {
        props.onChange(v || '', i, values);
      }
    };

    inputs.push(
      <InputContainer
        key={`text-list-input-${i}`}
        style={props.styles.container}>
        <TextField
          disabled={props.readOnly}
          onChange={onChange}
          value={values[i] || ''}
          autoComplete={props.autoComplete}
          multiline={props.multiline}
          onKeyDown={props.onKeyDown}
          prefix={props.prefixes[i]}
          suffix={props.suffixes[i]}
          {...props.textProps}
        />
      </InputContainer>,
    );

    addJoiner(props, inputs, i);
  }

  const Container = props.vertical ? VerticalContainer : HorizontalContainer;
  return <Container>{inputs}</Container>;
}

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const InputContainer = styled.div``;

const Joiner = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
`;
