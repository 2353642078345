import React, {useEffect, useState} from 'react';
import {api, useApiContext} from '../api';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {FormValues} from '../types/Form';
import {RelatedProxies, RelatedSchemas} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {array} from '../util';
import {ItemWidget} from '../widgets/ItemWidget';
import {useItemProps} from './hooks/useItemProps';
import {useOnChange} from './hooks/useOnChange';
import {useOnClear} from './hooks/useOnClear';
import {relatedItems} from './ItemField';

type Props = {
  value: any;
  values: FormValues;
  onChange: OnChangeFieldValue;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
  relatedProxies: RelatedProxies;
  relatedSchemas: RelatedSchemas;
  capacity?: number;
};

export function ProxyItemField(props: Props): JSX.Element | null {
  const ids = array(props.value);
  const itemProps = useItemProps(
    props.field,
    props.item,
    props.relatedSchemas,
    props.capacity,
  );
  const proxyItems = useProxyItems(props.field.itemType, props.relatedProxies);
  const onChange = useOnChange(props.onChange, props.field.id);
  const onClear = useOnClear(props.onChange, props.field.id);

  if (!itemProps || !proxyItems) {
    return null;
  }

  return (
    <ItemWidget
      {...props}
      {...itemProps}
      {...proxyItems}
      value={ids}
      onChange={onChange}
      onClear={onClear}
    />
  );
}

type Items = {
  useIdsToItems: (ids: string[]) => ResourceDetails[];
  useItemsToIds: (items: ResourceDetails[]) => string[];
};

function useProxyItems(
  schemaId?: string,
  relatedProxies?: RelatedProxies,
): Items | null {
  const [relates, setRelates] = useState<RelatedProxies>({
    ...relatedProxies,
  });
  const ctx = useApiContext();

  if (!schemaId) {
    return null;
  }

  const useItemsToIds = (v: ResourceDetails[]): string[] => {
    const values = array(v).map((x) => x.proxy_key);
    const newRelates = {...relates};

    if (!relates[schemaId]) {
      newRelates[schemaId] = {};
    }

    for (let x of array(v)) {
      newRelates[schemaId][x.proxy_key] = x;
    }

    setRelates(newRelates);
    return values;
  };

  const useIdsToItems = (ids: string[]): ResourceDetails[] => {
    useEffect(() => {
      (async () => {
        const newRelates = {...relates};

        if (!relates[schemaId]) {
          newRelates[schemaId] = {};
        }

        let changed = false;

        for (let id of ids) {
          if (newRelates[schemaId][id]) {
            continue;
          }

          try {
            const v = await api.show(ctx, schemaId, 'proxy/' + id);

            if (v.details && v.details.proxy_key) {
              newRelates[schemaId][v.details.proxy_key] = v.details;
              changed = true;
            }
          } catch (ignore) {}
        }

        if (changed) {
          setRelates(newRelates);
        }
      })();
    }, [ids]);

    return proxyItems(schemaId, ids, relates);
  };

  return {useIdsToItems, useItemsToIds};
}

function proxyItems(
  schemaId: string,
  ids: string[],
  relates: RelatedProxies,
): ResourceDetails[] {
  let result: ResourceDetails[] = [];

  if (relates) {
    result = result.concat(relatedItems(ids, relates[schemaId]));
  }

  return [...new Set(result)];
}
