import React from 'react';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {Container, IconContainer, LabelOrNull} from './AddButton';

type Props = {
  onPress: () => void;
  size: number;
  label?: string;
};

export function RemoveButton(props: Props): JSX.Element | null {
  return (
    <Container onClick={props.onPress}>
      <IconContainer
        style={{height: props.size + 'px', width: props.size + 'px'}}>
        <MaterialIcon iconName={'clear'} />
      </IconContainer>
      <LabelOrNull label={props.label} size={props.size} />
    </Container>
  );
}
