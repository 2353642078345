import {MFASetting} from '../../types/mfa';
import styled from 'styled-components';
import React from 'react';
import {PrimaryButton} from '@fluentui/react';
import {useIntl} from 'react-intl';

type Props = {
  setting: MFASetting;
  onAdd: () => void;
  onDelete: () => void;
};

export function Setting(props: Props): JSX.Element {
  return (
    <Container>
      <Name>{props.setting.name}</Name>
      {props.setting.enabled ? (
        <DeleteButton onClick={props.onDelete} />
      ) : (
        <AddButton onClick={props.onAdd} />
      )}
    </Container>
  );
}

type DeleteButtonProps = {
  onClick: () => void;
};

function DeleteButton(props: DeleteButtonProps): JSX.Element {
  const intl = useIntl();

  return (
    <PrimaryButton onClick={props.onClick}>
      {intl.formatMessage({
        id: 'MFA.Action.Delete',
        defaultMessage: 'Delete Setting',
      })}
    </PrimaryButton>
  );
}

type AddButtonProps = {
  onClick: () => void;
};

function AddButton(props: AddButtonProps): JSX.Element {
  const intl = useIntl();

  return (
    <PrimaryButton onClick={props.onClick}>
      {intl.formatMessage({
        id: 'MFA.Action.Add',
        defaultMessage: 'Add Setting',
      })}
    </PrimaryButton>
  );
}

const Container = styled.div`
  display: flex;
  width: min(400px, 100vw);
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div``;
