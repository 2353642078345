import {useEffect} from 'react';
import {api} from '../api';
import {Params} from '../types/Params';

export function useWebSocket(
  path: string | undefined,
  params: Params,
  onMessage: (e: MessageEvent) => void,
) {
  useEffect(() => {
    if (!path) {
      return;
    }

    const ws = api.newWebSocket(path, params);
    ws.addEventListener('message', onMessage);

    return () => {
      ws.removeEventListener('message', onMessage);
      ws.close();
    };
  }, [path, params, onMessage]);
}
