import React, {useCallback, useMemo} from 'react';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {widgetProps} from '../widgets/common';
import {WebSocketWidget} from '../widgets/WebSocketWidget';
import {useActionAndArgs} from './hooks/useActionAndArgs';

type Props = {
  value: unknown;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
};

export function WebSocketField(props: Props): JSX.Element {
  const path = widgetProps<string>(
    props.field,
    'path',
    useMemo(() => String(props.value), [props.value]),
  );

  const {action, args} = useActionAndArgs(
    props.field,
    props.schema,
    props.item,
    props.actions,
    'on_message',
    'patch',
  );

  const onMessage = useCallback((e: MessageEvent) => {
    const json = JSON.parse(e.data);

    action({
      ...args,
      item: {
        ...args.item,
        ...json.details,
      },
      relatedResources: json.relatedResources,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WebSocketWidget path={path} params={args.params} onMessage={onMessage} />
  );
}
