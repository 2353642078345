import {Dropdown, IDropdownOption} from '@fluentui/react';
import * as React from 'react';
import styled from 'styled-components';
import {FieldValueProps} from '../field/FieldValue';
import {Option} from '../types/Field';
import {add, array, del, unique} from '../util';
import {ClearButton} from './common/ClearButton';
import {Text} from './TextWidget';

type Props = {
  value: any;
  onChange?: (v: string | number | string[]) => void;
  options?: Option[];
  readOnly?: boolean;
  multiple?: boolean;
  width?: string | number;
  horizontal?: boolean;
  hideClearButton?: boolean;
};

type SelectWidgetProps = FieldValueProps & {
  multiple?: boolean;
  width?: number;
  horizontal?: boolean;
};

export function SelectWidget(props: SelectWidgetProps) {
  return (
    <SelectInputWidget
      value={props.value}
      onChange={(v) => props.onChange({[props.field.id]: v})}
      options={props.field.options}
      readOnly={props.readOnly}
      multiple={props.multiple}
      width={props.width}
      horizontal={props.horizontal}
    />
  );
}

export function SelectInputWidget(props: Props) {
  if (props.readOnly) {
    if (!props.options) {
      return <Text>{props.value}</Text>;
    }

    const selected = props.options.filter((opt) => opt.value === props.value);
    const text = selected.map((o) => o.label).join(', ');
    return <Text>{text}</Text>;
  }

  if (props.multiple) {
    return <MultiSelectWidget {...props} />;
  }

  return <SingleSelectWidget {...props} />;
}

function MultiSelectWidget(props: Props) {
  const selected = array(props.value);

  const onChange = (opt?: IDropdownOption) => {
    if (!opt || !props.onChange) {
      return;
    }

    const op = opt.selected ? add : del;
    const values = unique(op(selected, opt.key));
    props.onChange(values);
  };

  const onClear = () => {
    if (props.onChange) {
      props.onChange([]);
    }
  };

  const options = buildOptions(props.options || [], props.value);
  const Container = props.horizontal ? HorizontalContainer : VerticalContainer;
  const style = props.horizontal
    ? {marginLeft: '0.5rem'}
    : {marginTop: '0.5rem'};

  return (
    <Container>
      <Dropdown
        styles={{
          root: {},
          dropdown: {
            width: props.width,
          },
        }}
        selectedKeys={selected}
        multiSelect={true}
        options={options}
        onChange={(e, opt) => {
          onChange(opt);
        }}
      />
      {props.hideClearButton ? null : (
        <ClearButton onClick={onClear} styles={{root: style}} />
      )}
    </Container>
  );
}

function SingleSelectWidget(props: Props) {
  const selected = array(props.value)[0] ?? '';

  const onChange = (opt?: IDropdownOption) => {
    if (!opt || !props.onChange) {
      return;
    }

    props.onChange(opt.key);
  };

  const onClear = () => {
    if (props.onChange) {
      props.onChange('');
    }
  };

  const options = buildOptions(props.options || [], props.value);
  const Container = props.horizontal ? HorizontalContainer : VerticalContainer;
  const style = props.horizontal
    ? {marginLeft: '0.5rem'}
    : {marginTop: '0.5rem'};

  return (
    <Container>
      <Dropdown
        styles={{
          dropdown: {
            width: props.width,
          },
        }}
        calloutProps={{
          calloutMaxHeight: 300,
        }}
        selectedKey={selected}
        options={options}
        onChange={(e, opt) => {
          onChange(opt);
        }}
      />
      {props.hideClearButton ? null : (
        <ClearButton onClick={onClear} styles={{root: style}} />
      )}
    </Container>
  );
}

function buildOptions(options: Option[], value: any) {
  return options.map((opt) => {
    return {
      key: opt.value,
      text: opt.label,
      selected: opt.value === value,
    };
  });
}

const HorizontalContainer = styled.div`
  display: flex;
`;

const VerticalContainer = styled.div``;
