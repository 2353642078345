import {Sorter} from '../../types/Sorter';

export function selectDefaultSorter(sorters: Sorter[]): Sorter | null {
  if (!sorters || sorters.length === 0) {
    return null;
  }

  const def = sorters.filter((s) => s.default);

  if (def.length > 0) {
    return def[0];
  }

  return sorters[0];
}
