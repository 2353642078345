import {getTheme} from '@fluentui/react';

const theme = getTheme();

export const bodyColor = theme.palette.white;
export const bodyColorPrimary = theme.palette.themePrimary;
export const bodyColorAlt = theme.palette.neutralLighterAlt;
export const bodyColorHovered = theme.palette.neutralLighter;
export const bodyColorAccent = theme.palette.themeLighterAlt;
export const bodyColorAccentHovered = theme.palette.themeLighter;
export const bodyColorDisabled = theme.palette.neutralTertiaryAlt;
export const bodyColorDark = theme.palette.neutralLight;
export const bodyColorError = theme.semanticColors.errorBackground;
export const bodyColorTheme = theme.palette.themePrimary;

export const borderColor = theme.palette.neutralSecondary;
export const borderColorTheme = theme.palette.themePrimary;
export const borderColorMiddle = theme.palette.neutralTertiaryAlt;
export const borderColorLight = theme.palette.neutralTertiary;
export const borderColorLightest = theme.palette.neutralLight;

export const textColor = theme.palette.neutralPrimary;
export const textColorTheme = theme.palette.themePrimary;
export const textColorThemeAlt = theme.palette.themeDarker;
export const textColorThemeLight = theme.palette.themeSecondary;
export const textColorLight = theme.palette.neutralSecondary;
export const textColorLightest = theme.palette.neutralQuaternaryAlt;
export const textColorError = theme.palette.redDark;
export const textColorDisabled = theme.palette.neutralTertiary;
export const textColorInverse = theme.palette.white;

export const labelColor = theme.palette.neutralPrimaryAlt;

export const colors = {
  bodyColor,
  bodyColorPrimary,
  bodyColorAlt,
  bodyColorHovered,
  bodyColorAccent,
  bodyColorAccentHovered,
  bodyColorDisabled,
  bodyColorDark,
  bodyColorError,
  bodyColorTheme,

  borderColor,
  borderColorTheme,
  borderColorMiddle,
  borderColorLight,
  borderColorLightest,

  textColor,
  textColorTheme,
  textColorThemeAlt,
  textColorThemeLight,
  textColorLight,
  textColorLightest,
  textColorError,
  textColorDisabled,
  textColorInverse,

  labelColor,
};

export const classNameIconMiddle = 'eapp-icon-mid';
