import {Field} from '../../types/Field';
import {widgetProps} from '../../widgets/common';

export type TextProps = {
  copy: boolean;
  prefix?: string;
  suffix?: string;
  digitSeparator?: boolean;
  maxLength?: number;
  pattern?: string;
  multiline: boolean;
};

export function useTextProps(
  field: Field,
  defaultMultiline: boolean,
): TextProps {
  const copy = widgetProps<boolean>(field, 'clipboard_copy', false);
  const prefix = widgetProps<string | undefined>(field, 'prefix', undefined);
  const suffix = widgetProps<string | undefined>(field, 'suffix', undefined);
  const digitSeparator = widgetProps<boolean>(field, 'digit_separator', false);
  const maxLength =
    widgetProps<number | undefined>(field, 'max_length', undefined) ||
    field.size;

  const pattern = widgetProps<string | undefined>(field, 'pattern', undefined);
  const multiline = widgetProps<boolean>(field, 'multiline', defaultMultiline);

  return {
    copy,
    prefix,
    suffix,
    digitSeparator,
    maxLength,
    pattern,
    multiline,
  };
}
