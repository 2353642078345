import React from 'react';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {FormValues} from '../types/Form';
import {RelatedResources, RelatedSchemas} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {array} from '../util';
import {widgetProps} from '../widgets/common';
import {ItemCheckboxWidget} from '../widgets/ItemCheckboxWidget';
import {useItemProps} from './hooks/useItemProps';

type Props = {
  value: any;
  values: FormValues;
  onChange: OnChangeFieldValue;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
  relatedResources: RelatedResources;
  relatedSchemas: RelatedSchemas;
};

export function ItemCheckboxField(props: Props): JSX.Element | null {
  const itemProps = useItemProps(
    props.field,
    props.item,
    props.relatedSchemas,
    0,
  );

  if (!itemProps) {
    return null;
  }

  const checkFieldId = widgetProps<string>(
    props.field,
    'checkbox_field_id',
    '',
  );
  const checkboxDescription = widgetProps<string>(
    props.field,
    'checkbox_description',
    '',
  );
  const checkboxDescriptionView = widgetProps<string>(
    props.field,
    'checkbox_description_view',
    '',
  );

  const relatedResources = props.relatedResources;

  const onChange = (ids: string[]) => {
    props.onChange({[props.field.id]: ids});
  };

  const onCheck = (ids: string[]) => {
    props.onChange({[checkFieldId]: ids});
  };

  const onClear = () => {
    props.onChange({[props.field.id]: [], [checkFieldId]: []});
  };

  const checked: string[] = array(props.values[checkFieldId]) || [];

  const p = {
    ...itemProps,
    relatedResources,
    checkboxDescription,
    checkboxDescriptionView,
    onChange,
    checked,
    onCheck,
    onClear,
  };

  return <ItemCheckboxWidget {...props} {...p} />;
}
