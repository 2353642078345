import {Dropdown, IDropdownOption} from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
import {Field} from '../../types/Field';
import {TableColumn} from '../../types/TableColumn';

const Container = styled.div`
  width: 12em;
`;

type Props = {
  allColumns: TableColumn[];
  currentColumns: TableColumn[];
  fields: Field[];
  onChange: (columns: TableColumn[]) => void;
};

export function ColumnSelector(props: Props) {
  const options = buildOptions(props);

  return (
    <Container>
      <Dropdown
        options={options}
        onRenderTitle={() => <Label />}
        onRenderPlaceholder={() => <Label />}
        multiSelect={true}
        onChange={(e, option) => {
          if (option) {
            onChange(option, props);
          }
        }}
        styles={{
          caretDownWrapper: {
            height: 30,
          },
          caretDown: {
            display: 'flex',
            alignItems: 'center',
            height: 30,
            fontSize: 24,
          },
        }}
      />
    </Container>
  );
}

function Label() {
  return <div>表示列選択</div>;
}

function buildOptions(props: Props) {
  return props.allColumns
    .map((a) => {
      const field = props.fields.find((f) => f.id === a.fieldId);

      if (!field) {
        return null;
      }

      const col = props.currentColumns.find((col) => col.fieldId === a.fieldId);

      return {
        key: field.id,
        text: a.label || field.label,
        selected: !!col,
      };
    })
    .filter((o) => o !== null) as IDropdownOption[];
}

function onChange(option: IDropdownOption, props: Props): void {
  const fieldId = option.key as string;

  if (option.selected) {
    addColumn(fieldId, props);
    return;
  }

  removeColumn(fieldId, props);
}

function addColumn(fieldId: string, props: Props) {
  const ids = new Set([...props.currentColumns.map((c) => c.fieldId), fieldId]);
  const cols = props.allColumns.filter((c) => ids.has(c.fieldId));
  props.onChange(cols);
}

function removeColumn(fieldId: string, props: Props) {
  const ids = new Set(
    props.currentColumns
      .filter((c) => c.fieldId !== fieldId)
      .map((c) => c.fieldId),
  );
  const cols = props.allColumns.filter((c) => ids.has(c.fieldId));
  props.onChange(cols);
}
