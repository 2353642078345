import React from 'react';
import {MarkdownMessage} from '../components/MarkdownMessage';
import {Styles} from '../types/Styles';

export type BannerProps = {
  text: string;
  styles: Styles;
};

export function Banner(props: BannerProps): JSX.Element | null {
  return <MarkdownMessage text={props.text} styles={props.styles} />;
}
