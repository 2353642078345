import {mergeStyles} from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
import {MaterialIcon} from '../icons/MaterialIcon';
import {textColor, textColorLight, textColorTheme} from '../styles';

type Props = {
  list: BreadcrumbProps[];
};

export type BreadcrumbProps = {
  title: string;
  iconName: string;
  url?: string;
};

export function BreadcrumbList(props: Props): JSX.Element | null {
  if (!props.list || props.list.length === 0) {
    return null;
  }

  const list: JSX.Element[] = [];

  props.list.forEach((b, i) => {
    if (i !== 0) {
      list.push(<Joiner key={`joiner-${i}`}>{'>'}</Joiner>);
    }

    list.push(<Breadcrumb key={`breadcrumb-${i}`} {...b} />);
  });

  return <Container>{list}</Container>;
}

function Breadcrumb(props: BreadcrumbProps): JSX.Element | null {
  if (!props.url) {
    return (
      <NoLink>
        {renderIcon(props.iconName)}
        <Label>{props.title}</Label>
      </NoLink>
    );
  }

  return (
    <Link href={props.url} title={props.title}>
      {renderIcon(props.iconName)}
      <Label>{props.title}</Label>
    </Link>
  );
}

function renderIcon(iconName: string) {
  if (!iconName) {
    return null;
  }

  return <MaterialIcon iconName={iconName} className={iconClass} />;
}

export function lastBreadcrumbItem(list: BreadcrumbProps[]): BreadcrumbProps {
  if (!list || list.length === 0) {
    return {
      title: '',
      iconName: '',
    };
  }

  return list[list.length - 1];
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span``;

const Link = styled.a`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-decoration: none;
  color: ${textColor};

  & ${Label} {
    text-decoration: underline;
  }
`;

const Joiner = styled.span`
  color: ${textColorLight};
`;

const NoLink = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`;

const iconClass = mergeStyles({
  fontSize: 16,
  height: 16,
  width: 16,
  userSelect: 'none',
  marginRight: 10,
  color: textColorTheme,
});
