import {DetailsList, IColumn, SelectionMode} from '@fluentui/react';
import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {textColor} from '../styles';
import {Acl} from '../types/Acl';
import {RelatedResources, RelatedSchemas} from '../types/Resource';
import {CheckboxWidget} from './CheckboxWidget';

type Props = {
  acls: Acl[];
  relatedSchemas: RelatedSchemas;
  relatedResources: RelatedResources;
};

export function AclWidget(props: Props): JSX.Element | null {
  const intl = useIntl();

  const columns: IColumn[] = [
    {
      key: 'subject_type',
      name: intl.formatMessage({
        id: 'Widget.Acl.SubjectType',
        defaultMessage: 'Type',
      }),
      onRender: (acl) => <SubjectType {...props} acl={acl} />,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: 'subject',
      name: intl.formatMessage({
        id: 'Widget.Acl.Subject',
        defaultMessage: 'Subject',
      }),
      onRender: (acl) => <Subject {...props} acl={acl} />,
      minWidth: 100,
    },
    {
      key: 'readable',
      name: intl.formatMessage({
        id: 'Widget.Acl.Readable',
        defaultMessage: 'Readable',
      }),
      onRender: (acl) => <Right type={'readable'} bool={acl.readable} />,
      minWidth: 40,
      maxWidth: 40,
    },
    {
      key: 'writable',
      name: intl.formatMessage({
        id: 'Widget.Acl.Writable',
        defaultMessage: 'Writable',
      }),
      onRender: (acl) => <Right type={'writable'} bool={acl.writable} />,
      minWidth: 40,
      maxWidth: 40,
    },
    {
      key: 'deletable',
      name: intl.formatMessage({
        id: 'Widget.Acl.Deletable',
        defaultMessage: 'Deletable',
      }),
      onRender: (acl) => <Right type={'deletable'} bool={acl.deletable} />,
      minWidth: 40,
      maxWidth: 40,
    },
  ];

  return (
    <Container>
      <DetailsList
        columns={columns}
        items={props.acls}
        selectionMode={SelectionMode.none}
      />
    </Container>
  );
}

type SubjectProps = {
  acl: Acl;
  relatedResources: RelatedResources;
};

function Subject(props: SubjectProps): JSX.Element | null {
  const subject = props.relatedResources[props.acl.subjectId];
  const name = subject?.name || props.acl.subjectId;
  return <Text>{name}</Text>;
}

type SubjectTypeProps = {
  acl: Acl;
  relatedSchemas: RelatedSchemas;
  relatedResources: RelatedResources;
};

function SubjectType(props: SubjectTypeProps): JSX.Element | null {
  const subject = props.relatedResources[props.acl.subjectId];
  const schema = props.relatedSchemas[subject?.schema_id];
  const subType = schema?.name || '';
  return <Text>{subType}</Text>;
}

type RightProps = {
  type: 'readable' | 'writable' | 'deletable';
  bool: boolean;
};

function Right(props: RightProps): JSX.Element | null {
  return (
    <CheckboxContainer>
      <CheckboxWidget
        options={[{value: 'checked', label: ''}]}
        value={props.bool ? 'checked' : ''}
        readOnly={true}
      />
    </CheckboxContainer>
  );
}

const Container = styled.div`
  width: 600px;
`;

const Text = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: ${textColor};
`;

const CheckboxContainer = styled.div`
  padding-left: 0.2rem;
`;
