import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import React from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import {borderColor} from '../styles';

type OutputProps = {
  value: EditorState;
};

export function RichTextOutputWidget(props: OutputProps): JSX.Element | null {
  return (
    <Container>
      <Editor
        defaultEditorState={props.value}
        editorClassName={'RichTextWidget-editor-readOnly'}
        toolbarHidden={true}
        readOnly={true}
        toolbar={editorToolbar} // to hide pop over on link
      />
    </Container>
  );
}

type InputProps = {
  value: EditorState;
  onChange: (v: EditorState) => void;
};

export function RichTextInputWidget(props: InputProps): JSX.Element | null {
  return (
    <Container>
      <Editor
        editorState={props.value}
        onEditorStateChange={props.onChange}
        toolbarClassName={'RichTextWidget-toolbar'}
        editorClassName={'RichTextWidget-editor'}
        toolbar={editorToolbar}
        locale={'ja'}
        localization={{
          locale: 'ja',
          translations: {
            'components.controls.link.linkTarget': 'URL',
          },
        }}
      />
    </Container>
  );
}

export function convertToRichTextState(value: string): EditorState {
  return value
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
    : EditorState.createEmpty();
}

export function convertFromRichTextState(value: EditorState): string {
  return JSON.stringify(convertToRaw(value.getCurrentContent()));
}

const editorToolbar = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'colorPicker',
    'link',
    'history',
  ],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
  },
  blockType: {
    className: 'RichTextWidget-toolbar-blockType',
  },
  fontSize: {
    className: 'RichTextWidget-toolbar-fontSize',
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  link: {
    showOpenOptionOnHover: false,
  },
};

const Container = styled.div`
  & .RichTextWidget-editor {
    border: 1px solid ${borderColor};
    border-radius: 2px;
    padding: 0.5rem;
    min-height: 200px;
    max-height: 400px;
    box-sizing: border-box;
    line-height: 1.5;

    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }

  & .RichTextWidget-toolbar {
    border-color: ${borderColor};
  }

  & .RichTextWidget-toolbar-blockType {
    min-width: 10em;
  }

  & .RichTextWidget-toolbar-fontSize {
    min-width: 3em;
  }

  & .rdw-link-modal-btn {
    width: 7em;
  }

  & .RichTextWidget-editor-readOnly {
    line-height: 1.5;

    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }
`;
