import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';

export type ImportResult = {
  totalCount: number;
  successCount: number;
  errorCount: number;
  errorRows: number[];
};

type Props = {
  result?: ImportResult;
};

export function ImportResultComponent(props: Props) {
  if (!props.result) {
    return null;
  }

  return (
    <Container>
      <Number
        labelId={'Import.Result.TotalCount'}
        defaultLabel={'Total'}
        num={props.result.totalCount}
      />
      <Number
        labelId={'Import.Result.SuccessCount'}
        defaultLabel={'Succeeded'}
        num={props.result.successCount}
      />
      <Number
        labelId={'Import.Result.ErrorCount'}
        defaultLabel={'Failed'}
        num={props.result.errorCount}
      />
      {props.result.errorCount === 0 ? null : (
        <Numbers
          labelId={'Import.Result.ErrorRows'}
          defaultLabel={'Failed line numbers'}
          nums={props.result.errorRows}
        />
      )}
    </Container>
  );
}

type NumberProps = {
  labelId: string;
  defaultLabel: string;
  num: number;
};

function Number(props: NumberProps) {
  const label = useMessage(props.labelId, props.defaultLabel);

  return (
    <NumberContainer>
      <Label>{label}</Label>
      <Num>{props.num}</Num>
    </NumberContainer>
  );
}

type NumbersProps = {
  labelId: string;
  defaultLabel: string;
  nums: number[];
};

function Numbers(props: NumbersProps) {
  const label = useMessage(props.labelId, props.defaultLabel);
  let nums = takeFirstN(props.nums, 10).join(', ');

  if (props.nums.length > 10) {
    nums += ', ...';
  }

  return (
    <NumberContainer>
      <Label>{label}</Label>
      <Num>{nums}</Num>
    </NumberContainer>
  );
}

function useMessage(labelId: string, defaultLabel: string) {
  const intl = useIntl();

  return intl.formatMessage({
    id: labelId,
    defaultMessage: defaultLabel,
  });
}

function takeFirstN(nums: number[], n: number): number[] {
  return nums.slice(0, n);
}

const Container = styled.div``;

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const Label = styled.div`
  margin-right: 1rem;
  min-width: 100px;
`;

const Num = styled.div`
  font-family: monospace;
`;
