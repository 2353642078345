import {AppForm} from './forms/AppForm';

type Props = {
  mfaType?: string;
  onCancel: () => void;
  onDone: () => void;
};

const mfaTypeApp = '0';

export function Form(props: Props): JSX.Element | null {
  switch (props.mfaType) {
    case mfaTypeApp:
      return <AppForm {...props} mfaType={mfaTypeApp} />;
  }

  return null;
}
