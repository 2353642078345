import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {textColorLight} from '../../styles';
import {Option} from '../../types/Field';
import {array} from '../../util';
import {RadioWidget} from '../RadioWidget';
import {AntennaScannedData} from './AntennaScannedData';

type Props = {
  selected: string;
  onSelect: (code: string) => void;
  data: AntennaScannedData;
};

export function ScannedValues(props: Props): JSX.Element {
  const intl = useIntl();

  if (props.data.unknownIds.length === 0) {
    const msg = intl.formatMessage({
      id: 'Widget.AntennaScannedValueSelector.None',
      defaultMessage: 'None',
    });
    return (
      <Message>
        <p>{msg}</p>
      </Message>
    );
  }

  const options = buildOptions(props.data.unknownIds);

  return (
    <RadioWidget
      value={props.selected}
      options={options}
      onChange={(v) => {
        props.onSelect(String(v));
      }}
      vertical={true}
      noClearButton={true}
    />
  );
}

function buildOptions(values: string[]): Option[] {
  return array(values).map((code) => ({
    label: code,
    value: code,
  }));
}

const Message = styled.div`
  font-size: 1rem;
  color: ${textColorLight};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
