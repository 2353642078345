import React, {CSSProperties, useCallback} from 'react';
import styled from 'styled-components';
import {ClearButton} from './common/ClearButton';
import {formatDateUTC, formatMonth, toDate} from './common/dateutil';
import {MonthSelector} from './common/MonthSelector';

type Props = {
  readOnly: boolean;
} & InputProps &
  OutputProps;

export function MonthWidget(props: Props): JSX.Element {
  if (props.readOnly) {
    return <MonthOutputWidget {...props} />;
  }

  return <MonthInputWidget {...props} />;
}

type OutputProps = {
  value: any;
  style?: CSSProperties;
};

export function MonthOutputWidget(props: OutputProps): JSX.Element {
  const ym = formatMonth(toDate(props.value));
  return <OutputContainer style={props.style}>{ym}</OutputContainer>;
}

type InputProps = {
  value: any;
  onChange: (v: string) => void;
  placeholder?: string;
  style?: CSSProperties;
};

export function MonthInputWidget(props: InputProps): JSX.Element {
  const current = toDate(props.value);
  const onChange = props.onChange;

  const onSelectMonth = (date?: Date | null) => {
    const value = formatDateUTC(date);

    if (onChange) {
      onChange(value);
    }
  };

  const onClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <InputContainer>
      <MonthSelector
        value={current}
        onSelectDate={onSelectMonth}
        placeholder={props.placeholder}
      />
      <ClearButton
        onClick={() => {
          onClear();
        }}
        styles={{root: {marginLeft: '0.5rem'}}}
      />
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  max-width: 300px;
`;

const OutputContainer = styled.div``;
