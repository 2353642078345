import React, {RefObject, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dialog} from '../../common/Dialog';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ItemSelector, ItemSelectorProps} from './ItemSelector';

type Props = ItemSelectorProps & {
  componentRef: RefObject<Dialog>;
  title: string;
  value: ResourceDetails[];
  onSelect: (items: ResourceDetails[]) => void;
};

export function ItemSelectorDialog(props: Props): JSX.Element {
  const intl = useIntl();
  const [selected, setSelected] = useState<ResourceDetails[]>(props.value);

  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);

  return (
    <Dialog
      ref={props.componentRef}
      title={props.title}
      executeLabel={intl.formatMessage({
        id: 'Action.Select',
        defaultMessage: 'Select',
      })}
      maxWidth="100%"
      onExecute={async () => {
        props.onSelect(selected);
        return true;
      }}
      onCancel={() => {
        setSelected(props.value);
      }}
      styles={{buttons: {justifyContent: 'flex-end'}}}>
      <ItemSelector
        {...props}
        selected={selected}
        onSelect={(items) => {
          setSelected(items);
        }}
        style={{paddingLeft: '1rem', paddingRight: '1rem'}}
      />
    </Dialog>
  );
}
