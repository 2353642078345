import {DefaultButton} from '@fluentui/react';
import React, {useRef, useState} from 'react';
import {useApiContext} from '../api';
import {Dialog} from '../common/Dialog';
import {ItemDetailsDialog} from '../common/dialogs/ItemDetailsDialog';
import {TargetItemProps} from '../fields/hooks/useItemProps';
import {useMessage} from '../hooks/useMessage';
import {Actions} from '../types/Action';
import {RelatedResources} from '../types/Resource';
import {WidgetStyles} from '../types/WidgetStyles';
import {ClearButton} from './common/ClearButton';
import {
  buildOptions,
  LabelStyles,
  OptionButtonContainer,
  OptionButtonDescription,
  useItems,
  useRenderClickableLabel,
  useRenderStaticLabel,
} from './item/ItemOptionButton';
import {ItemSelectorDialog} from './item/ItemSelectorDialog';
import {RadioWidget} from './RadioWidget';

type Props = {
  value: string[];
  onChange: (ids: string[]) => void;
  selected: string;
  onSelect: (id: string) => void;
  onClear: () => void;
  title: string;
  disabledIds: string[];
  actions: Actions;
  capacity?: number;
  styles?: WidgetStyles;
  relatedResources?: RelatedResources;
  radioDescription?: string;
  radioDescriptionView?: string;
  targets: TargetItemProps[];
  readOnly?: boolean;
};

export function ItemRadioWidget(props: Props): JSX.Element {
  if (props.readOnly) {
    return <ItemRadioOutputWidget {...props} />;
  }

  return <ItemRadioInputWidget {...props} />;
}

export function ItemRadioOutputWidget(props: Props): JSX.Element {
  const {items, itemsMap} = useItems(props);
  const options = buildOptions(items);
  const [itemId, setItemId] = useState<string>();
  const [schemaId, setSchemaId] = useState<string>();
  const [shown, setShown] = useState<boolean>(false);
  const ctx = useApiContext();
  const onRenderLabel = useRenderClickableLabel(
    itemsMap,
    props.targets,
    setItemId,
    setSchemaId,
    setShown,
    styles,
  );

  return (
    <div>
      {items.length > 0 ? (
        <>
          <OptionButtonDescription
            text={props.radioDescriptionView || props.radioDescription}
          />
          <RadioWidget
            value={props.selected}
            options={options}
            readOnly={true}
            onRenderLabel={onRenderLabel}
          />
          <ItemDetailsDialog
            ctx={ctx}
            shown={shown}
            schemaId={schemaId}
            resId={itemId}
            onClose={() => {
              setShown(false);
            }}
            actions={props.actions}
            minWidth={'400px'}
          />
        </>
      ) : null}
    </div>
  );
}

export function ItemRadioInputWidget(props: Props): JSX.Element {
  const dialog = useRef<Dialog>(null);
  const {onChange, items, itemsMap} = useItems(props);
  const options = buildOptions(items);
  const onRenderLabel = useRenderStaticLabel(itemsMap, props.targets, styles);

  return (
    <div>
      <DefaultButton
        text={useMessage('Widget.Item.Select', 'Select')}
        onClick={() => {
          if (dialog.current) {
            dialog.current.showDialog();
          }
        }}
        styles={{
          root: {
            marginRight: '0.5rem',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <ClearButton onClick={props.onClear} />
      <ItemSelectorDialog
        {...props}
        componentRef={dialog}
        value={items}
        onSelect={onChange}
      />
      {items.length > 0 ? (
        <OptionButtonContainer>
          <OptionButtonDescription text={props.radioDescription} />
          <RadioWidget
            value={props.selected}
            options={options}
            onChange={(id) => {
              props.onSelect(String(id));
            }}
            onRenderLabel={onRenderLabel}
          />
        </OptionButtonContainer>
      ) : null}
    </div>
  );
}

const styles: LabelStyles = {
  container: {
    marginLeft: '28px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
  },
};
