import * as React from 'react';
import {CSSProperties, MouseEventHandler} from 'react';
import styled from 'styled-components';
import {doNothing, useActionAndArgs} from '../fields/hooks/useActionAndArgs';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {widgetProps} from '../widgets/common';
import {rowPadding} from './ResourceDetailsTable';

export function ActionText(props: {
  field: Field;
  schema: Schema;
  item: ResourceDetails;
  actions: Actions;
  value: any;
  style: CSSProperties;
}): JSX.Element {
  const {action, args} = useActionAndArgs(
    props.field,
    props.schema,
    props.item,
    props.actions,
    'on_press',
    '',
  );

  const noAction = action === doNothing;

  const onClick: MouseEventHandler<HTMLElement> = (evt) => {
    if (noAction) {
      return;
    }

    evt.stopPropagation();
    evt.preventDefault();
    action(args);
  };

  const textAlign = widgetProps<'left' | 'right' | 'center'>(
    props.field,
    'text_align',
    'left',
  );

  const fill = widgetProps<boolean>(props.field, 'fill', false);
  const fillStyle = fill ? {margin: '-' + rowPadding, padding: rowPadding} : {};

  const Elem = noAction ? Text : ActionableText;

  return (
    <Elem
      title={props.value}
      style={{...props.style, textAlign, ...fillStyle}}
      onClick={onClick}>
      {props.value}
    </Elem>
  );
}

const Text = styled.div`
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const ActionableText = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;
