import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {formatDateUTC} from '../../widgets/common/dateutil';
import {DateWidget} from '../../widgets/DateWidget';
import {DateRange} from '../DateRange';

type Props = {
  dateRange: DateRange;
  onChangeDateRange: (range: DateRange) => void;
  maxLength?: number;
  style?: CSSProperties;
};

export function DateRangeSelector(props: Props): JSX.Element {
  const onChangeStart = (dateStr: string) => {
    const start = new Date(dateStr);
    props.onChangeDateRange(props.dateRange.withStart(start, props.maxLength));
  };

  const onChangeEnd = (dateStr: string) => {
    const end = new Date(dateStr);
    props.onChangeDateRange(props.dateRange.withEnd(end, props.maxLength));
  };

  return (
    <Container style={props.style}>
      <DateWidget
        value={formatDateUTC(props.dateRange.start)}
        onChange={onChangeStart}
        hideClearButton={true}
        style={style}
      />
      <Connector>〜</Connector>
      <DateWidget
        value={formatDateUTC(props.dateRange.end)}
        onChange={onChangeEnd}
        hideClearButton={true}
        style={style}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Connector = styled.div`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

const style = {
  width: 140,
};
